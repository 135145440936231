import { log } from 'utils/helpers';
import {
  CREATE_COUNTER_PARTY,
  CYBRID_VERIFY_COUNTER_PARTY,
  GET_ALL_COUNTER_PARTIES,
  GET_ALL_COUNTRY_BANKS,
  GET_ALL_RECENT_TRANSACTIONS,
  GET_COUNTRY_BANKS,
  GET_SUBDIVISIONS,
  INTL_TRANSFER_ENDPOINT,
  VERIFY_ACCOUNT_NUMBER,
  VERIFY_COUNTER_PARTY,
} from './index';
import { IntlTransferData, TransferData, VerifyAccountData } from 'types/data';
import { default as axios } from 'config/axios';

export const executeGetCountryBanks = async (
  countryCode: string,
  token: string,
): Promise<ResponseDataType> => {
  try {
    const options: RequestInit = {
      headers: {
        'Content-Type': 'application/json',
        whspay: '&sw22mewjsd4t343643?',
        whcookie: `whspay=${token}`,
      },
    };
    const request = await fetch(GET_ALL_COUNTRY_BANKS(countryCode), options);
    const response = (await request.json()) satisfies ResponseDataType;
    return response.data;
  } catch (error: any) {
    log('LOGIN [ERROR]:', error.message);
    return { message: error.message, isSuccess: false } as ResponseDataType;
  }
};

export const executeVerifyAccountNumber = async (
  data: VerifyAccountData,
  token: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).post(VERIFY_ACCOUNT_NUMBER, data);
    return response.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: error };
  }
};

export const executeVerifyCounterParty = async (
  counterPartyId: string,
  token: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).get(
      CYBRID_VERIFY_COUNTER_PARTY(counterPartyId),
    );
    return response.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: error };
  }
};

export const executeCounterParty = async (
  data: any,
  token: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).post(VERIFY_COUNTER_PARTY, data);
    return response.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: error };
  }
};

export const executeCreateCounterParty = async (
  data: any,
  userId: string,
  token: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).post(
      CREATE_COUNTER_PARTY(userId),
      data,
    );
    return response.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: error };
  }
};

export const executeGetSubdivisions = async (
  countryCode: string,
  token: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).get(GET_SUBDIVISIONS(countryCode));
    return response.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: error };
  }
};

export const executeGetAllCounterParties = async (
  token: string,
  userId: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).get(GET_ALL_COUNTER_PARTIES(userId));
    return response.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: error };
  }
};

export const executeLocalTransfer = async (
  data: TransferData,
  token: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).post(INTL_TRANSFER_ENDPOINT, data);
    return response.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: error };
  }
};

export const executeInternationalTransfer = async (
  data: IntlTransferData,
  token: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).post(INTL_TRANSFER_ENDPOINT, data);
    return response.data;
  } catch (error: any) {
    console.log({ error });
    return { message: error.message, isSuccess: false, status: error };
  }
};

export const executeGetBanksByCountryCode = async (
  token: string,
  code: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).get(GET_COUNTRY_BANKS(code));
    return response.data;
  } catch (error: any) {
    log('LOGIN [ERROR]:', error.message);
    return { message: error.message, isSuccess: false } as ResponseDataType;
  }
};

export const executeGetRecentTransactions = async (
  token: string,
  userId: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).get(
      GET_ALL_RECENT_TRANSACTIONS(userId),
    );
    return response.data;
  } catch (error: any) {
    log('LOGIN [ERROR]:', error.message);
    return { message: error.message, isSuccess: false } as ResponseDataType;
  }
};
