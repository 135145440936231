import { ADD_ADDRESS_ENDPOINT, PROFILE_SETTINGS_ENDPOINT } from '../index';
import { default as axios } from 'config/axios';

export const executeAddAddress = async (
  data: AddressData,
  token: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).post(ADD_ADDRESS_ENDPOINT, data);
    return response.data;
  } catch (error: any) {
    return {
      message: error.response.data.errors.address,
      isSuccess: false,
      status: 'error',
    };
  }
};

export const executeSetPinCode = async (
  data: UserProfile,
  token: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).patch(PROFILE_SETTINGS_ENDPOINT, data);
    return response.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: 'error' };
  }
};
