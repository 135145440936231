import React from 'react';

type CautionIconProps = React.SVGProps<SVGSVGElement>;
const CautionIcon: React.FC<CautionIconProps> = (props) => {
  return (
    <svg
      viewBox="0 0 41 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className="w-10 h-10"
    >
      <path
        d="M7.78647 9.74668L9.49314 8.04001L7.78647 6.33334"
        stroke="#221860"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.66666 8.04001H9.44666"
        stroke="#221860"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 2.66666C10.9467 2.66666 13.3333 4.66666 13.3333 7.99999C13.3333 11.3333 10.9467 13.3333 8 13.3333"
        stroke="#221860"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const FileIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        stroke="#E8E8E8"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M7.5 14.167v-5l-1.667 1.667M7.5 9.167l1.667 1.667"
      />
      <path
        stroke="#CCC"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M18.333 8.334V12.5c0 4.167-1.666 5.834-5.833 5.834h-5c-4.167 0-5.833-1.667-5.833-5.834v-5c0-4.166 1.666-5.833 5.833-5.833h4.167"
      />
      <path
        stroke="#CCC"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M18.333 8.334H15c-2.5 0-3.333-.834-3.333-3.334V1.667l6.666 6.667Z"
      />
    </svg>
  );
};

const DocumentCopyIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
      <path
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M17 13.4v3c0 4-1.6 5.6-5.6 5.6H7.6c-4 0-5.6-1.6-5.6-5.6v-3.8C2 8.6 3.6 7 7.6 7h3"
      />
      <path
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M17 13.4h-3.2c-2.4 0-3.2-.8-3.2-3.2V7l6.4 6.4ZM11.6 2h4M7 5c0-1.66 1.34-3 3-3h2.62M22 8v6.19c0 1.55-1.26 2.81-2.81 2.81M22 8h-3c-2.25 0-3-.75-3-3V2l6 6Z"
      />
    </svg>
  );
};

const KYCIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      {...props}
      className="w-6 h-6"
    >
      <path
        stroke="#221860"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="m9.31 14.7 1.5 1.5 4-4"
      />
      <path
        stroke="#221860"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M10 6h4c2 0 2-1 2-2 0-2-1-2-2-2h-4C9 2 8 2 8 4s1 2 2 2Z"
      />
      <path
        stroke="#221860"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M16 4.02c3.33.18 5 1.41 5 5.98v6c0 4-1 6-6 6H9c-5 0-6-2-6-6v-6c0-4.56 1.67-5.8 5-5.98"
      />
    </svg>
  );
};

const LogoutIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      {...props}
      className="w-6 h-6"
    >
      <path
        fill="#292D32"
        stroke="#221860"
        d="M8.63 10.82c-.686 0-1.25.564-1.25 1.25 0 .677.547 1.253 1.255 1.24H13.6v7.83c0 .2-.162.36-.36.36-2.825 0-5.194-.984-6.855-2.645C4.724 17.195 3.74 14.825 3.74 12c0-2.826.984-5.194 2.645-6.855C8.046 3.484 10.415 2.5 13.24 2.5c.2 0 .364.154.37.366v7.954H8.63ZM20.185 11.893h.001c.097.098.088.241.003.321l-.007.007-.007.006-2.839 2.85a.242.242 0 0 1-.176.073.242.242 0 0 1-.177-.074.254.254 0 0 1 0-.352l1.56-1.56.854-.854H14.6v-.5h4.807l-.854-.854-1.56-1.56a.254.254 0 0 1 0-.352.255.255 0 0 1 .353 0l2.84 2.849Z"
      />
    </svg>
  );
};

const UserIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="18"
      fill="none"
      {...props}
      className="w-4 h-4"
    >
      <path
        fill="#F5F5F5"
        stroke="#221860"
        d="M1.7 15.719H1.7a.538.538 0 0 1-.449-.531V14A2.75 2.75 0 0 1 4 11.25h.341v.5c.026 0 .052.004.077.012L1.7 15.719Zm0 0c4.173.68 8.428.68 12.6 0a.537.537 0 0 0 .45-.532m-13.05.532 13.05-.532m0 0V14A2.75 2.75 0 0 0 12 11.25h-.338m3.088 3.937-4.19-3.617.865-.283.004-.001a.753.753 0 0 1 .232-.036m0 0h-.001v.5l.004-.5h-.002Zm-6.066-.156a7.75 7.75 0 0 0 4.81 0l.866-.283c.125-.04.256-.061.388-.061H12A3.25 3.25 0 0 1 15.25 14v1.188c0 .509-.368.942-.87 1.024a39.59 39.59 0 0 1-12.76 0 1.037 1.037 0 0 1-.87-1.024V14A3.25 3.25 0 0 1 4 10.75h.34c.13 0 .263.021.39.062h.002l.863.282ZM4.25 4.5a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0Zm3.75 3.25a3.25 3.25 0 1 0 0-6.5 3.25 3.25 0 0 0 0 6.5Z"
      />
    </svg>
  );
};

const CategoryIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      {...props}
    >
      <path
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M5 10h2c2 0 3-1 3-3V5c0-2-1-3-3-3H5C3 2 2 3 2 5v2c0 2 1 3 3 3ZM17 10h2c2 0 3-1 3-3V5c0-2-1-3-3-3h-2c-2 0-3 1-3 3v2c0 2 1 3 3 3ZM17 22h2c2 0 3-1 3-3v-2c0-2-1-3-3-3h-2c-2 0-3 1-3 3v2c0 2 1 3 3 3ZM5 22h2c2 0 3-1 3-3v-2c0-2-1-3-3-3H5c-2 0-3 1-3 3v2c0 2 1 3 3 3Z"
      />
    </svg>
  );
};
const WalletMinus: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      {...props}
    >
      <path
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M14.262 15.998h-5"
      />
      <path
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="m12.66 2.518-.03.07-2.9 6.73H6.88c-.68 0-1.33.14-1.92.39l1.75-4.18.04-.1.07-.16c.02-.06.04-.12.07-.17 1.31-3.03 2.79-3.72 5.77-2.58Z"
      />
      <path
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M18.05 9.518c-.45-.14-.93-.2-1.41-.2H9.73l2.9-6.73.03-.07c.15.05.29.12.44.18l2.21.93c1.23.51 2.09 1.04 2.61 1.68.1.12.18.23.25.36.09.14.16.28.2.43.04.09.07.18.09.26.27.84.11 1.87-.41 3.16Z"
      />
      <path
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M21.522 14.198v1.95c0 .2-.01.4-.02.6-.19 3.49-2.14 5.25-5.84 5.25h-7.8c-.24 0-.48-.02-.71-.05-3.18-.21-4.88-1.91-5.09-5.09-.03-.23-.05-.47-.05-.71v-1.95c0-2.01 1.22-3.74 2.96-4.49.6-.25 1.24-.39 1.92-.39h9.76c.49 0 .97.07 1.41.2 1.99.61 3.46 2.47 3.46 4.68Z"
      />
      <path
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="m6.71 5.528-1.75 4.18A4.894 4.894 0 0 0 2 14.198v-2.93c0-2.84 2.02-5.21 4.71-5.74ZM21.519 11.268v2.93c0-2.2-1.46-4.07-3.46-4.67.52-1.3.67-2.32.42-3.17-.02-.09-.05-.18-.09-.26 1.86.96 3.13 2.93 3.13 5.17Z"
      />
    </svg>
  );
};

const DebitIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      {...props}
    >
      <path
        stroke="#FF4C51"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M9.5 13.75c0 .97.75 1.75 1.67 1.75h1.88c.8 0 1.45-.68 1.45-1.53 0-.91-.4-1.24-.99-1.45l-3.01-1.05c-.59-.21-.99-.53-.99-1.45 0-.84.65-1.53 1.45-1.53h1.88c.92 0 1.67.78 1.67 1.75M12 7.5v9"
      />
      <path
        stroke="#FF4C51"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M22 12c0 5.52-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2M22 6V2h-4M17 7l5-5"
      />
    </svg>
  );
};

const CreditIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      className="w-6 h-6"
      {...props}
    >
      <path
        stroke="#17CB49"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M12 14.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
      />
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeOpacity=".2"
        strokeWidth="1.5"
        d="M12 14.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
      />
      <path
        stroke="#17CB49"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M18.5 9.5v5"
      />
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeOpacity=".2"
        strokeWidth="1.5"
        d="M18.5 9.5v5"
      />
      <path
        stroke="#17CB49"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M9 18c0 .75-.21 1.46-.58 2.06A3.97 3.97 0 0 1 5 22a3.97 3.97 0 0 1-3.42-1.94A3.92 3.92 0 0 1 1 18c0-2.21 1.79-4 4-4s4 1.79 4 4Z"
      />
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeOpacity=".2"
        strokeWidth="1.5"
        d="M9 18c0 .75-.21 1.46-.58 2.06A3.97 3.97 0 0 1 5 22a3.97 3.97 0 0 1-3.42-1.94A3.92 3.92 0 0 1 1 18c0-2.21 1.79-4 4-4s4 1.79 4 4Z"
      />
      <path
        stroke="#17CB49"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M6.492 17.98h-2.98"
      />
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeOpacity=".2"
        strokeWidth="1.5"
        d="M6.492 17.98h-2.98"
      />
      <path
        stroke="#17CB49"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M5 16.52v2.99"
      />
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeOpacity=".2"
        strokeWidth="1.5"
        d="M5 16.52v2.99"
      />
      <path
        stroke="#17CB49"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M2 15.3V9c0-3.5 2-5 5-5h10c3 0 5 1.5 5 5v6c0 3.5-2 5-5 5H8.5"
      />
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeOpacity=".2"
        strokeWidth="1.5"
        d="M2 15.3V9c0-3.5 2-5 5-5h10c3 0 5 1.5 5 5v6c0 3.5-2 5-5 5H8.5"
      />
    </svg>
  );
};

interface ReceivedIconProps extends React.SVGProps<SVGSVGElement> {
  primaryColor?: string;
  secondaryColor?: string;
}

const ReceivedIcon: React.FC<ReceivedIconProps> = ({
  primaryColor = '#17CB49',
  secondaryColor = '#000',
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      fill="none"
      {...props}
      className="w-6 h-6"
    >
      <path
        stroke={primaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M9.5 13.75c0 .97.75 1.75 1.67 1.75h1.88c.8 0 1.45-.68 1.45-1.53 0-.91-.4-1.24-.99-1.45l-3.01-1.05c-.59-.21-.99-.53-.99-1.45 0-.84.65-1.53 1.45-1.53h1.88c.92 0 1.67.78 1.67 1.75"
      />
      <path
        stroke={secondaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity=".2"
        strokeWidth="1.5"
        d="M9.5 13.75c0 .97.75 1.75 1.67 1.75h1.88c.8 0 1.45-.68 1.45-1.53 0-.91-.4-1.24-.99-1.45l-3.01-1.05c-.59-.21-.99-.53-.99-1.45 0-.84.65-1.53 1.45-1.53h1.88c.92 0 1.67.78 1.67 1.75"
      />
      <path
        stroke={primaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12 7.5v9"
      />
      <path
        stroke={secondaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity=".2"
        strokeWidth="1.5"
        d="M12 7.5v9"
      />
      <path
        stroke={primaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M22 12c0 5.52-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2"
      />
      <path
        stroke={secondaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity=".2"
        strokeWidth="1.5"
        d="M22 12c0 5.52-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2"
      />
      <path
        stroke={primaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M17 3v4h4"
      />
      <path
        stroke={secondaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity=".2"
        strokeWidth="1.5"
        d="M17 3v4h4"
      />
      <path
        stroke={primaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="m22 2-5 5"
      />
      <path
        stroke={secondaryColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity=".2"
        strokeWidth="1.5"
        d="m22 2-5 5"
      />
    </svg>
  );
};

interface ConversionIconProps extends React.SVGProps<SVGSVGElement> {
  primaryColor?: string;
  secondaryColor?: string;
}

const ConversionIcon: React.FC<ConversionIconProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
      <path
        fill="#292D32"
        d="M12.031 2.031c-1.95 0-3.77.56-5.3 1.52l6.86 6.87.01-2.74a.749.749 0 1 1 1.5 0l-.01 4.55c0 .31-.19.58-.47.69-.09.04-.19.06-.28.06a.75.75 0 0 1-.53-.22l-8.19-8.2a1.06 1.06 0 0 1-.1-.12 9.984 9.984 0 0 0-3.49 7.59c0 5.52 4.48 10 10 10 1.95 0 3.77-.56 5.3-1.52l-6.86-6.87-.01 2.74a.749.749 0 1 1-1.5 0l.01-4.55c0-.31.19-.58.47-.69.27-.12.6-.06.81.16l8.19 8.2c.04.04.07.08.1.12a9.984 9.984 0 0 0 3.49-7.59c0-5.52-4.48-10-10-10Z"
      />
    </svg>
  );
};

interface ArrowSwapIconProps extends React.SVGProps<SVGSVGElement> {
  primaryColor?: string;
  secondaryColor?: string;
}

const ArrowSwap: React.FC<ArrowSwapIconProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
      <path
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="m20.5 14.99-5.01 5.02M3.5 14.99h17M3.5 9.01l5.01-5.02M20.5 9.01h-17"
      />
    </svg>
  );
};

const KeyIcon: React.FC<ArrowSwapIconProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
      <path
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M19.79 14.93a7.575 7.575 0 0 1-7.6 1.87l-4.71 4.7c-.34.35-1.01.56-1.49.49l-2.18-.3c-.72-.1-1.39-.78-1.5-1.5l-.3-2.18c-.07-.48.16-1.15.49-1.49l4.7-4.7c-.8-2.6-.18-5.55 1.88-7.6 2.95-2.95 7.74-2.95 10.7 0 2.96 2.95 2.96 7.76.01 10.71ZM6.89 17.49l2.3 2.3"
      />
      <path
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M14.5 11a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
      />
    </svg>
  );
};

const ArrowRightIcon: React.FC<ArrowSwapIconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="gray"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-chevron-right"
    >
      <polyline points="9 18 15 12 9 6" />
    </svg>
  );
};

export {
  CautionIcon,
  FileIcon,
  KYCIcon,
  LogoutIcon,
  UserIcon,
  CreditIcon,
  ReceivedIcon,
  DocumentCopyIcon,
  ConversionIcon,
  ArrowSwap,
  DebitIcon,
  CategoryIcon,
  WalletMinus,
  KeyIcon,
  ArrowRightIcon,
};
