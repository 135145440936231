import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, Input } from 'components/common/Form';
import { executeUpdateSecurityQuestion } from 'api/settings/security';
import { useToast } from 'components/Notifications/Toast';
import { useAppSelector } from 'store/hooks';

const ChangeSecurityQuestion = () => {
  const { showToast } = useToast();
  const [loading, setLoading] = React.useState(false);
  const token = useAppSelector((state) => state.accountStore.tokenStore);
  const { register, getValues, trigger } = useForm<SecuritySettingsData>();

  const handleSetSecurityQuestion = async () => {
    setLoading(true);
    try {
      if (!(await trigger())) return false;
      const payload: SecuritySettingsData = {
        old_question: getValues('old_question'),
        new_question: getValues('new_question'),
        answer: getValues('answer'),
      };
      const result = await executeUpdateSecurityQuestion(payload, token);
      if (!result.isSuccess) throw new Error(result.message);
      showToast(result.message, 'success');
    } catch (error: any) {
      showToast(error.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      data-aos="flip-right"
      data-aos-duration="2000"
      data-aos-delay="10"
      data-aos-offset="200"
      className="w-[375px] px-[24px] bg-white py-[8px] h-[685px]"
    >
      <h1 className="text-2xl font-semibold mb-4">Security Question</h1>
      <p className="text-gray-500 text-sm mb-14">
        Create your security question here
      </p>
      <div className="space-y-6 mt-14">
        <Input
          register={register}
          name={'old_question'}
          placeholder="Choose a Security question"
          label={'Old Security Question'}
          type={'text'}
          color={true}
          rules={{
            required: 'Please enter a question',
            minLength: {
              value: 8,
              message: 'Please enter a question',
            },
          }}
        />
        <Input
          register={register}
          name={'new_question'}
          placeholder="Choose a Security question"
          label={'New Security Question'}
          type={'text'}
          color={true}
          rules={{
            required: 'Please enter a question',
            minLength: {
              value: 8,
              message: 'Please enter a question',
            },
          }}
        />
        <Input
          register={register}
          name={'answer'}
          placeholder="Placeholder text"
          label={'Answer'}
          type={'textarea'}
          color={true}
          rules={{
            required: 'Please enter an answer',
          }}
        />
      </div>
      <Button
        onClick={handleSetSecurityQuestion}
        label={loading ? 'Saving...' : 'Save'}
        extraClasses="mt-[100px]"
      />
    </div>
  );
};

export default ChangeSecurityQuestion;
