import React from 'react';
import AuthLayout from 'layouts/Auth';
import authBg from 'assets/secure-guide-bg.png';
import icon from 'assets/icons/secure.svg';
import check from 'assets/icons/check.svg';
import { SecureTransactionStep } from 'utils/data';
import { Button } from 'components/common/Form';
import { useAppDispatch } from 'store/hooks';
import { STEPS, updateLevel } from 'store/registration/registerSlice';

interface SecureTransactionsGuideProps {
  setActiveStep: (no: any) => void;
}

const SecureTransactionsGuide: React.FC<SecureTransactionsGuideProps> = ({
  setActiveStep,
}) => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(
      updateLevel({
        step: STEPS.SECURE_ACCOUNT,
      }),
    );
    setActiveStep((prev: any) => prev + 1);
  };

  return (
    <AuthLayout background={true} backgroundImage={authBg}>
      <div className="flex flex-col justify-between items-center mb-14 px-5 space-y-16">
        <h2 className="text-2xl font-semibold text-center">
          Secure your account with a transaction pin
        </h2>
        <img src={icon} className="w-24 h-24 mx-auto" alt="Secure Icon" />
        <p className="text-sm text-center text-gray-500">
          Create a 4 digit PiN to protect your account against unauthorized
          transactions
        </p>
      </div>
      <div className="space-y-4 px-5">
        {SecureTransactionStep().map((input) => (
          <div className="flex items-center" key={input.id}>
            <img src={check} className="mr-2" alt="Check Icon" />
            <p className="text-sm text-gray-500">{input.value}</p>
          </div>
        ))}
        <Button onClick={handleClick} label={'Create PIN'} />
      </div>
    </AuthLayout>
  );
};

export default SecureTransactionsGuide;
