import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Input, Select, Button } from 'components/common/Form';
import { BiChevronDown } from 'react-icons/bi';
import useDisclosure from 'hooks/useDisclosure';
import { useToast } from 'components/Notifications/Toast';
import { executeCounterParty } from 'api/bank';
import { useAppSelector } from 'store/hooks';
import { transferInputs } from 'utils/data';
import { WalletData } from 'types/data';

interface InputField {
  name: string;
  placeholder?: string;
  label?: string;
  type?: string;
  required?: boolean;
  enum?: string[];
  const?: string;
}

interface Props {
  paymentMethod: string | null;
  data: any;
  setPaymentData: Dispatch<SetStateAction<any>>;
  handleNextStep: () => void;
  selectedWallet: WalletData;
  selectedCountry: {
    value: {
      code: string;
      paymentMethods: string[];
      currencies: string[];
      name: string;
    };
  } | null;
}

const Recipient: React.FC<Props> = ({
  paymentMethod,
  data,
  selectedCountry,
  handleNextStep,
  setPaymentData,
  selectedWallet,
}) => {
  const {
    register,
    formState: { errors, isValid, isDirty },
    setValue,
    getValues,
    control,
    trigger,
  } = useForm({
    mode: 'onChange', // Changed to onChange for real-time validation
  });

  const getInputs = (): any => {
    if (!selectedCountry) return [];
    const transferInputs = paymentMethod
      ? Object.values(data?.destination?.[paymentMethod])
      : Object.values(data?.destination);

    return transferInputs[0];
  };

  const { inputs, constFields } = useMemo(() => {
    const allInputs = getInputs();
    const constFields: Record<string, string> = {};
    const filteredInputs = allInputs.filter((input: InputField) => {
      if (input.const !== undefined) {
        constFields[input.name] = input.const;
        return false;
      }
      return true;
    });
    return { inputs: filteredInputs, constFields };
  }, [selectedCountry, paymentMethod, data]);

  const token = useAppSelector((state) => state.accountStore.tokenStore);
  const customerEmail = useAppSelector(
    (state) => state.accountStore.account?.email,
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { showToast } = useToast();

  useEffect(() => {
    // Set const fields as default values and trigger validation
    Object.entries(constFields).forEach(([key, value]) => {
      setValue(key, value);
    });
    trigger(); // Trigger validation after setting const fields
  }, [constFields, setValue, trigger]);

  const onSubmit = async () => {
    // Validate all fields before proceeding
    const isFormValid = await trigger();
    if (!isFormValid) {
      showToast('Please fill all required fields', 'error');
      return;
    }

    onOpen();
    try {
      const { amount, narration, ...formData } = getValues();
      void amount;
      void narration;

      const networkField = inputs.find((input: any) => input.enum);
      if (networkField) {
        formData[networkField.name] = formData[networkField.name]?.id || null;
      }

      const destination = { ...formData, ...constFields };

      let payload;
      if (selectedWallet.currency === 'NGN') {
        payload = {
          currency: selectedCountry?.value?.currencies[0],
          country: selectedCountry?.value?.code,
          customerEmail,
          destination,
        };
      } else {
        payload = {
          currency: selectedCountry?.value?.currencies[0],
          country: selectedCountry?.value?.code,
          customerEmail,
          destination,
        };
      }

      const res = await executeCounterParty(payload, token);

      if (res.isSuccess) {
        showToast(res.message, 'success');
        setPaymentData({
          ...getValues(),
          counterId: res.data.counter_party_id,
        });
        handleNextStep();
      } else {
        showToast('Failed to process your request', 'error');
      }
    } catch (error: any) {
      showToast(error.message, 'error');
    } finally {
      onClose();
    }
  };

  return (
    <div
      className="bg-[#F5FAFE] p-6 mb-[24px] mt-4 rounded-lg space-y-6"
      style={{ maxHeight: '80%', overflowY: 'auto' }}
    >
      {inputs.map((input: InputField, idx: number) =>
        input.enum ? (
          <Controller
            key={idx}
            name={input.name}
            control={control}
            rules={{ required: input.required !== false }}
            render={({ field }) => (
              <Select
                selected={field.value as { id: number; name: string }}
                setSelected={(value) => {
                  field.onChange(value);
                  trigger(input.name); // Trigger validation on change
                }}
                datas={input.enum?.map((item) => ({
                  name: item,
                  id: item,
                }))}
                label={input.label || input.name}
              >
                <div className="w-full flex-btn text-textGray text-sm p-4 border border-border font-light rounded-lg focus:border focus:border-subMain bg-white">
                  {(field.value as { id: number; name: string })?.name || ''}
                  <BiChevronDown className="text-xl" />
                </div>
              </Select>
            )}
          />
        ) : (
          <div key={idx}>
            <Input
              name={input.name}
              placeholder={input.placeholder || input.name}
              label={input.label || input.name}
              type={input.type || 'text'}
              color={true}
              register={register}
              required={input.required !== false}
            />
            {errors[input.name] && (
              <p className="text-red-500 text-sm">
                {errors[input.name]?.message?.toString()}
              </p>
            )}
          </div>
        ),
      )}
      {transferInputs().map((input, idx) => (
        <div key={idx}>
          <Input
            name={input.name}
            placeholder={input.placeholder || input.name}
            label={input.label || input.name}
            type={input.type || 'text'}
            color={true}
            register={register}
            required
          />
          {errors[input.name] && (
            <p className="text-red-500 text-sm">
              {errors[input.name]?.message?.toString()}
            </p>
          )}
        </div>
      ))}
      <Button
        loading={isOpen}
        label="Next"
        onClick={onSubmit}
        disabled={!isValid || !isDirty} // Disable button if form is invalid or unchanged
        extraClasses={`mb-[2px] ${!isValid || !isDirty ? 'opacity-50 cursor-not-allowed' : ''}`}
      />
    </div>
  );
};

export default Recipient;
