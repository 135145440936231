import React from 'react';
import flag from 'assets/flags.svg';

interface SummaryProps {
  formData?: any;
}
const Summary: React.FC<SummaryProps> = ({ formData }) => {
  return (
    <div className="space-y-6 bg-[#F5FAFE] p-6 mb-12 mt-8 rounded-lg">
      <div className="w-full">
        <div className="flex justify-between">
          <p className="text-base text-[#1c1c1c]">22/12/2023</p>
          <p className="text-base text-[#1c1c1c]">Personal</p>
        </div>
        <div className="mt-6 px-2 py-4 space-y-6">
          <h2 className="text-2xl font-semibold text-center">
            N{formData?.amount}
          </h2>
          <p className="text-center text-base text-[#1c1c1c] font-medium">
            University of Urich
          </p>
          <div className="w-full flex justify-between">
            <p className="text-sm text-base text-[#1c1c1c] leading-[21px]">
              {formData?.accountNumber} <br /> {formData?.narration}
            </p>
            <p className="text-sm text-base text-[#1c1c1c]">
              {formData?.bankName?.name}
            </p>
          </div>
        </div>
        <div className="px-2 py-4">
          <div className="w-full flex justify-between items-center mb-[41px]">
            <p className="text-xs leading-5 text-sm font-medium text-[#1c1c1c]">
              Transfer Fee
            </p>
            <p className="font-semibold text-[20px] text-[#1c1c1c]">N0.00</p>
          </div>
          <div className="w-full space-y-2">
            <p className="text-sm text-base text-[#1c1c1c]">
              {formData?.recipient}
            </p>
            <p className="text-sm text-base text-[#1c1c1c]">
              12, Saint meridan road, Magodo, Lagos
            </p>
            <div className="flex items-center gap-2">
              <img src={flag} alt="Flag" className="inline" />
              <p className="text-sm">Access Bank/Victoria island</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
