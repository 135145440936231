import React, { ReactNode } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';

interface IndexProps {
  children: ReactNode;
  title: string;
  hasItsOwnHeader?: boolean;
  bgColor?: string;
}

const Index: React.FC<IndexProps> = ({
  children,
  title,
  hasItsOwnHeader,
  bgColor,
}) => {
  return (
    <div className="bg-white 2xl:h-screen flex-colo">
      <div className="grid xl:grid-cols-12 w-full 2xl:max-w-[2000px]">
        <div className="col-span-2 xl:block hidden">
          <Sidebar />
        </div>

        <div
          className={`bg-[${bgColor}] col-span-10 xl:h-screen overflow-y-scroll relative`}
        >
          {!hasItsOwnHeader && <Header title={title} />}
          <div className={!hasItsOwnHeader ? 'xs:px-8 px-2 pt-24' : ''}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
