import React from 'react';
import AuthLayout from 'layouts/Auth';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { executeSignUp } from 'api/auth';
import { STEPS, updateLevel } from 'store/registration/registerSlice';
import First from './forms/First';
import Third from './forms/Third';
import { useToast } from 'components/Notifications/Toast';
import { Button, FormFooter } from 'components/common/Form';
import { BiLogInCircle } from 'react-icons/bi';
import { populateAccount, populateToken } from 'store/account/accountSlice';

interface RegistrationProps {
  setActiveStep: (no: any) => void;
}

const Registration: React.FC<RegistrationProps> = ({ setActiveStep }) => {
  const {
    trigger,
    getValues,
    register,
    watch,
    formState: { errors },
  } = useForm<RegistrationData>({
    mode: 'onSubmit',
  });

  const steps: {
    title: string;
    validation: (keyof RegistrationData)[];
    component: any;
  }[] = [
    {
      title: 'First',
      component: <First register={register} errors={errors} />,
      validation: ['first_name', 'last_name', 'middle_name', 'phone_number'],
    },
    {
      title: 'Third',
      component: <Third register={register} errors={errors} watch={watch} />,
      validation: ['password', 'confirm_password'],
    },
  ];

  const { selectedCountry } = useAppSelector((state) => state.countryStore);
  console.log({ selectedCountry });
  const { data } = useAppSelector((state) => state.registerStore);
  const [step, setStep] = React.useState(0);

  const dispatch = useAppDispatch();
  const { showToast } = useToast();
  const [isLoading, setIsLoading] = React.useState(false);

  const handleSignUp = async () => {
    if (!(await trigger(steps[step].validation))) return false;
    if (!selectedCountry) {
      showToast('Please select a country.', 'error');
      return;
    }
    if (step === 1) {
      try {
        setIsLoading(true);
        const payload: RegistrationData = {
          first_name: getValues('first_name')?.trim(),
          last_name: getValues('last_name')?.trim(),
          middle_name: getValues('middle_name')?.trim(),
          phone_number: getValues('phone_number'),
          country_id: selectedCountry.id,
          email: data.EMAIL,
          membership_type: 'User',
          dob: getValues('dob'),
          bvn: getValues('bvn'),
          password: getValues('password'),
          confirm_password: getValues('confirm_password'),
          country_code: selectedCountry?.code,
        };
        const result = await executeSignUp(payload);
        console.log({ result }, 'from method handler');
        if (!result.isSuccess) throw new Error(result.message);
        dispatch(
          updateLevel({
            step: STEPS.RESIDENTIAL_ADDRESS,
            data: {
              ['FIRST_NAME']: getValues('first_name'),
              ['LAST_NAME']: getValues('last_name'),
              ['MIDDLE_NAME']: getValues('middle_name'),
              ['EMAIL']: data?.EMAIL,
              ['ACCOUNT_ID']: result?.data?.account?.id,
            },
          }),
        );
        dispatch(populateToken(result.data.token));
        dispatch(
          populateAccount({
            email: result?.data?.account?.email,
            id: result?.data?.account?.id,
          }),
        );
        setActiveStep((prev: any) => prev + 1);
      } catch (error: any) {
        showToast(error.message, 'error');
      } finally {
        setIsLoading(false);
      }
    } else {
      setStep((prev) => prev + 1);
    }
  };

  return (
    <AuthLayout backgroundOverlay="bg-neutral-gray">
      <div className="space-y-6">
        {steps[step].component}
        <Button
          label="Continue"
          Icon={BiLogInCircle}
          onClick={handleSignUp}
          extraClasses="mt-[196px] mb-[24px]"
          loading={isLoading}
        />
        <FormFooter />
      </div>
    </AuthLayout>
  );
};

export default Registration;
