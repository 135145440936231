import { CountryData } from 'types/data';

const SHOW_LOGS = true;

export const log = (...args: any[]) => SHOW_LOGS && console.log(...args);

export const getSlug = (name: string) => {
  const words = name.split(' ');
  if (words.length === 1) return name.toLowerCase();
  return words
    .filter((name) => name)
    .map((_name) => _name.toLowerCase())
    .join('-');
};

export const labelValueMap = <T>(
  arr: T[],
): { id: number; name: string; value: string }[] =>
  arr.map((item) => ({
    id: (item as any).id,
    name: (item as any).name,
    value: (item as any).id,
  }));

export const combineOtp = (otp: string[]): number => {
  return parseInt(otp.join(''), 10);
};

export const isOtpFilled = (otp: string[]): boolean => {
  return otp.every((digit) => digit !== '');
};

export const getIdentityLabel = (country: CountryData): string => {
  return country.code === 'US' ? 'SSN' : 'NIN';
};

export const convertToIdNameArray = (names: string[]) => {
  return names.map((name, index) => ({
    id: index + 1,
    name,
  }));
};

export const formatDateForInput = (dateString: string) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // months are 0-indexed
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const getRandomColor = () => {
  const colors = [
    '#FFE7CC',
    '#FFD120',
    '#CCFFDA',
    '#CFE6FC',
    '#4F3D8C',
    '#CCFFDA',
    '#00CC39',
  ];
  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
};

export const currencyToCountryCode: { [key: string]: string } = {
  NGN: 'ng',
  USD: 'us',
  EUR: 'eu',
  GBP: 'gb',
  JPY: 'jp',
};

export function generateRandomString(length: number): string {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomString = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters[randomIndex];
  }

  return randomString;
}
