import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { executeGetTransferCountries } from 'api/countries';
import useDisclosure from 'hooks/useDisclosure';
import { useAppSelector } from 'store/hooks';
import { Button } from 'components/common/Form';

interface Props {
  setState: (prev: any) => void;
  setSelectedCountry: (prev: any) => void;
  selectedCountry: any;
}

const Countries: React.FC<Props> = ({
  setState,
  setSelectedCountry,
  selectedCountry,
}) => {
  const token = useAppSelector((state) => state.accountStore.tokenStore);
  const [countries, setCountries] = useState<any[]>([]);
  const {
    isOpen: isLoading,
    onOpen: openLoading,
    onClose: closeLoading,
  } = useDisclosure();

  const fetchCountries = async () => {
    try {
      openLoading();
      const result = await executeGetTransferCountries(token);
      if (!result.isSuccess) throw new Error(result.message);
      setCountries(result.data);
    } catch (error: any) {
      console.log('error', error);
    } finally {
      closeLoading();
    }
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  // Mapping countries to react-select options
  const countryOptions = countries.map((country) => ({
    value: country,
    label: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ marginRight: 10 }}>{country.flag}</span>
        {country.name}
      </div>
    ),
  }));

  const handleCountrySelect = (selectedOption: any) => {
    setSelectedCountry(selectedOption);
  };

  return (
    <div className="flex flex-col items-center space-y-4">
      <div className="w-full">
        <Select
          options={countryOptions}
          isSearchable
          value={selectedCountry?.code}
          onChange={handleCountrySelect}
          placeholder="Select your country"
          className="w-full"
        />
      </div>
      <div className="flex-grow"></div>

      <Button
        onClick={() => setState(2)}
        label="Next"
        extraClasses={'mt-auto'}
        loading={isLoading}
        disabled={!selectedCountry}
      />
    </div>
  );
};

export default Countries;
