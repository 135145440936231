import moneyCard from 'assets/moneycard.png';
import { Button } from 'components/common/Form';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import nigeria from 'assets/flags.svg';
import canada from 'assets/canada.svg';
import usa from 'assets/usa.svg';
import mastercard from 'assets/mastercard.svg';
import number from 'assets/letter.svg';
import letter from 'assets/number.svg';
import { populateSelectedCard } from 'store/account/accountSlice';

interface Props {
  setActiveStep: (no: any) => void;
  setSelectedCard: React.Dispatch<any>;
  selectedCard: any;
}

const Initial: React.FC<Props> = ({
  setActiveStep,
  setSelectedCard,
  selectedCard,
}) => {
  const cardsObject = useAppSelector((state) => state.accountStore.cards);
  const dispatch = useAppDispatch();

  const handleSelection = (card: string) => {
    setSelectedCard((card as any).id);
    dispatch(populateSelectedCard(card));
  };

  const cards = Object.values(cardsObject);

  const getFlagIcon = (title: string) => {
    switch (title) {
      case 'NGN':
        return nigeria;
      case 'USD':
        return usa;
      case 'CAD':
        return canada;
      default:
        return undefined;
    }
  };
  return (
    <div className="flex flex-col items-center text-center">
      <div className="w-[374px] pt-[24px] pb-[73px] px-[24px] bg-[white] shadow-lg">
        <h2 className="text-lg font-semibold">Virtual Cards</h2>
        <div className="my-6">
          <img src={moneyCard} alt="3d money card" className="mx-auto" />
        </div>
        <div className="flex flex-col gap-4">
          {cards.map((card: any) => (
            <label key={card.id} className="flex items-center cursor-pointer">
              <input
                type="radio"
                name="wallet"
                value={card.id}
                className="hidden"
                checked={selectedCard === card.id}
                onChange={() => handleSelection(card)}
              />
              <div
                className={`flex items-center justify-between border-2 rounded-md p-3 w-full transition-colors duration-300 ${
                  selectedCard === card.id
                    ? 'border-primary'
                    : 'border-[#E8E8E8]'
                } hover:border-gray-400`}
              >
                <div
                  className={`flex items-center justify-center w-6 h-6 rounded-full border-2 transition duration-300 ${
                    selectedCard === card.id
                      ? 'border-primary bg-primary'
                      : 'border-[#E8E8E8] bg-white'
                  }`}
                >
                  {selectedCard === card.id && (
                    <div className="w-3 h-3 rounded-full bg-white" />
                  )}
                </div>
                <img
                  src={getFlagIcon(card.currency)}
                  className={`text-xl text-darkBlue ml-2`}
                />{' '}
                <span className="font-mono ml-2">{card.cardDigits}</span>
                <img
                  src={letter}
                  className={`text-xl text-darkBlue ml-2`}
                />{' '}
                <img src={number} className={`text-xl text-darkBlue ml-2`} />{' '}
                <img
                  src={mastercard}
                  className={`text-xl text-darkBlue ml-2`}
                />{' '}
              </div>
            </label>
          ))}
        </div>
        <Button
          label="Send Request"
          onClick={() => setActiveStep((prev: number) => prev + 1)}
        />
      </div>
    </div>
  );
};

export default Initial;
