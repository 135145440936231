import { createSlice } from '@reduxjs/toolkit';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import { BankData } from 'types/data';
import { convertToIdNameArray } from 'utils/helpers';

type InitialStateType = {
  banks: BankData[];
};
const initialState: InitialStateType = {
  banks: [],
};

const bankSlice = createSlice({
  name: 'bank',
  initialState,
  reducers: {
    populateCountryBanks: (state, action) => {
      const normalizedBanks = convertToIdNameArray(action.payload);
      state.banks = normalizedBanks;
    },
  },
});

const bankPersistConfig = {
  key: 'bank',
  storage: storage,
};

export const { populateCountryBanks } = bankSlice.actions;

const bankReducer = persistReducer(bankPersistConfig, bankSlice.reducer);

export default bankReducer;
