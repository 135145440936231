import React, { SetStateAction } from 'react';
import AuthLayout from 'layouts/Auth';
import authBg from 'assets/form-bg-two.png';
import OtpVerificationBox from 'components/otp/VerificationBox';
import TimerComponent from 'components/common/TimerComponent';
import { BiLogInCircle } from 'react-icons/bi';
import { Button } from 'components/common/Form';
import { combineOtp, isOtpFilled } from 'utils/helpers';
import { useToast } from 'components/Notifications/Toast';

interface VerifyEmailProps {
  setActiveStep: (no: any) => void;
  email: string;
  setVerificationOtp: React.Dispatch<SetStateAction<number | null>>;
}

const VerifyEmail: React.FC<VerifyEmailProps> = ({
  setActiveStep,
  setVerificationOtp,
}) => {
  const [otp, setOtp] = React.useState(Array.from({ length: 6 }, () => ''));

  const [isLoading, setIsLoading] = React.useState(false);
  const [isResending, setIsResending] = React.useState(false);

  console.log({ setIsResending, setIsLoading });

  const { showToast } = useToast();

  const handleVerifyEmail = async () => {
    if (!isOtpFilled(otp)) {
      showToast('Please fill in all OTP digits.', 'error');
      return;
    }
    setVerificationOtp(combineOtp(otp));
    setActiveStep((prev: any) => prev + 1);
  };

  return (
    <AuthLayout background={true} backgroundImage={authBg}>
      <div className="flex flex-col items-center justify-between text-center">
        <h2 className="text-[24px] font-semibold mb-6">OTP Verification</h2>
        <p className="text-[#949596] text-sm">Enter the OTP sent to</p>
        <button className="text-[#1C1C1C] mb-6">
          {'ayomoses111@gmail.com'}
        </button>
        <div className="mb-4 mt-10">
          <OtpVerificationBox otp={otp} setOtp={setOtp} />
        </div>
        <TimerComponent
          time={5}
          isMinute
          isLoading={isResending}
          onClick={() => console.log('test')}
        />
        <p className="text-[#949596] mt-[81px] mb-[24px] text-sm">
          Didn’t receive a code?
        </p>
        <button className="text-[#1C1C1C] mb-6">Request again</button>
        <Button
          label="Continue"
          Icon={BiLogInCircle}
          onClick={handleVerifyEmail}
          extraClasses="mt-[95px] mb-[24px]"
          loading={isLoading}
        />
      </div>
    </AuthLayout>
  );
};

export default VerifyEmail;
