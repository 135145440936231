import React, { useState, useEffect } from 'react';
// import { MdOutlineCloudDownload } from 'react-icons/md';
import Layout from 'layouts/Dashboard';
// import { Button } from 'components/common/Form';
import { TransactionsTable } from 'components/Tables/index';
import { useNavigate, useParams } from 'react-router-dom';
import { Tab, TabGroup, TabList } from '@headlessui/react';
import { transactionsTab } from 'utils/data';
import { executeGetWalletTransactions } from 'api/wallet';
import { useAppSelector } from 'store/hooks';
import { useToast } from 'components/Notifications/Toast';
import Loader from 'components/Notifications/Loader'; // Import the loader component
import { WalletData } from 'types/data';

const Doctors: React.FC = () => {
  const [transactions, setTransactions] = useState<TransactionData[]>([]); // Initialize as an empty array
  const [selectedTab, setSelectedTab] = useState<number>(1); // Default to "All" tab
  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const [error, setError] = useState<string | null>(null); // State for error messages
  const token = useAppSelector((state) => state.accountStore.tokenStore);
  const wallets = useAppSelector((state) => state.accountStore.wallets);
  const { id } = useParams();
  const wallet = Object.values(wallets).find(
    (wallet: any): wallet is WalletData => wallet.id === id,
  );

  const navigate = useNavigate();
  const { showToast } = useToast();

  const preview = (id: number | string) => {
    navigate(`/transactions/preview/${id}`);
  };

  const getAllTransactions = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await executeGetWalletTransactions(id!, token);
      setTransactions(response.data || []); // Ensure transactions is an array
      showToast(response.message, 'success');
    } catch (error: any) {
      setError(
        error.message || 'An error occurred while fetching transactions.',
      );
      showToast(error.message, 'error');
    } finally {
      setLoading(false); // Set loading to false after fetching data
    }
  };

  useEffect(() => {
    getAllTransactions();
  }, [id]);

  // Function to filter transactions based on the selected tab
  const filteredTransactions = () => {
    switch (selectedTab) {
      case 1: // All
        return transactions;
      case 2: // Credit
        return transactions.filter(
          (transaction) => transaction.type === 'credit',
        );
      case 3: // Debit
        return transactions.filter(
          (transaction) => transaction.type === 'debit',
        );
      default:
        return transactions;
    }
  };

  return (
    <Layout title="Transactions">
      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="100"
        data-aos-offset="200"
        className="my-8 p-5"
      >
        {/* Tabs for filtering transactions */}
        <TabGroup>
          <div className="grid md:grid-cols-6 sm:grid-cols-2 grid-cols-1 gap-2">
            <div className="md:col-span-5 grid lg:grid-cols-4 items-center gap-6">
              <TabList className="flex gap-4">
                {transactionsTab.map(({ title, id }) => (
                  <Tab
                    key={id}
                    onClick={() => setSelectedTab(id)} // Set the selected tab when clicked
                    className={`py-1 px-3 text-sm font-normal text-[#585757] focus:outline-none ${
                      selectedTab === id
                        ? 'border-b-[3px] border-primary data-[hover]:underline-offset-4 data-[focus]:outline-1 data-[focus]:outline-white'
                        : 'data-[hover]:underline-offset-4 data-[focus]:outline-1 data-[focus]:outline-white'
                    }`}
                  >
                    {title}
                  </Tab>
                ))}
              </TabList>
            </div>
            {/* Export Button */}
            <h2>{wallet?.currency}</h2>
            {/* <Button label="Download Statement" Icon={MdOutlineCloudDownload} /> */}
          </div>
        </TabGroup>
        <div className="mt-8 w-full overflow-x-scroll">
          {loading ? ( // Show loader while loading
            <Loader />
          ) : error ? ( // Check if there was an error
            <div className="text-center text-red-500">{error}</div>
          ) : filteredTransactions().length === 0 ? ( // Check if there are no transactions
            <div className="text-center text-gray-500">
              No transactions available for this wallet.
            </div>
          ) : (
            <TransactionsTable
              action={true}
              data={filteredTransactions()} // Use filtered transactions
              functions={{
                preview: preview,
              }}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Doctors;
