import React, { createContext, useState, useContext, ReactNode } from 'react';

interface DrawerContextProps {
  isOpen: boolean;
  openDrawer: (content: ReactNode) => void;
  closeDrawer: () => void;
  content: ReactNode | null;
}

const DrawerContext = createContext<DrawerContextProps | undefined>(undefined);

export const DrawerProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState<ReactNode | null>(null);

  const openDrawer = (content: ReactNode) => {
    setContent(content);
    setIsOpen(true);
  };

  const closeDrawer = () => {
    setContent(null);
    setIsOpen(false);
  };

  return (
    <DrawerContext.Provider
      value={{ isOpen, openDrawer, closeDrawer, content }}
    >
      {children}
    </DrawerContext.Provider>
  );
};

export const useDrawer = () => {
  const context = useContext(DrawerContext);
  if (!context) {
    throw new Error('useDrawer must be used within a DrawerProvider');
  }
  return context;
};
