import React, { useState } from 'react';
import Modal from 'components/Modal'; // Adjust import path as needed
import { Button } from 'components/common/Form'; // Assuming you have a Button component
import { useToast } from 'components/Notifications/Toast';
import { executeVerifyCounterParty } from 'api/bank';
import { useAppSelector } from 'store/hooks';

interface CounterPartyVerificationModalProps {
  isOpen: boolean;
  onClose: () => void;
  counterId: string;
  onVerificationSuccess: () => void;
}

const CounterPartyVerificationModal: React.FC<
  CounterPartyVerificationModalProps
> = ({ isOpen, onClose, counterId, onVerificationSuccess }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { showToast } = useToast();
  const token = useAppSelector((state) => state.accountStore.tokenStore);

  const handleVerification = async () => {
    setIsLoading(true);
    try {
      const res = await executeVerifyCounterParty(counterId, token);

      if (res.isSuccess) {
        showToast('Counter party verified successfully', 'success');
        onVerificationSuccess();
        onClose();
      } else {
        showToast(res.message || 'Verification failed', 'error');
      }
    } catch (error: any) {
      showToast(
        error.message || 'An error occurred during verification',
        'error',
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      closeModal={onClose}
      title="Verify Counter Party"
      width="max-w-md"
    >
      <div className="space-y-4">
        <p className="text-gray-600">
          Please verify the counter party details to complete the process.
        </p>
        <div className="flex justify-end space-x-3">
          <Button label="Cancel" onClick={onClose} disabled={isLoading} />
          <Button
            label="Verify Counter Party"
            onClick={handleVerification}
            loading={isLoading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CounterPartyVerificationModal;
