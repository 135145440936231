import React from 'react';
import { currencyToCountryCode } from 'utils/helpers';

const thclass = 'text-start text-sm font-medium py-3 px-2 whitespace-nowrap';
const tdclass = 'text-start text-sm py-4 px-2 whitespace-nowrap';

interface TransactionTableProps {
  data: TransactionData[];
  action: boolean;
  functions: {
    preview: (id: number | string) => void;
  };
}

const getCountryCode = (currencyCode: string): string | null => {
  return currencyToCountryCode[currencyCode.toUpperCase()] || null;
};

export const TransactionsTable: React.FC<TransactionTableProps> = ({
  data,
}) => {
  return (
    <table className="table-auto w-full">
      <thead className="bg-greyed border-b-2 border-[#E8E8E8] rounded-md overflow-hidden">
        <tr>
          <th className={thclass}>Account No</th>
          <th className={thclass}>Date</th>
          <th className={thclass}>Recipient</th>
          <th className={thclass}>Narration</th>
          <th className={thclass}>Reference</th>
          <th className={thclass}>Amount</th>
          <th className={thclass}>Balance</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr
            key={item.id}
            className="border-b border-border hover:bg-greyed transitions"
          >
            <td className={tdclass}>
              <span
                className={`fi fi-${getCountryCode(item.currency)} mr-2`}
              ></span>
              {item.destination_account_number}
            </td>
            <td className={tdclass}>
              {new Intl.DateTimeFormat('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              }).format(new Date(item.created_at))}
            </td>
            <td className={tdclass}>
              {item.destination_account_name ?? 'Test User'}
            </td>
            <td className={tdclass}>{item.narration}</td>
            <td className={tdclass}>{item.reference}</td>
            <td className={tdclass}>
              {item.currency}
              {new Intl.NumberFormat().format(item.amount / 100)}
            </td>
            <td className={tdclass}>
              {item.currency}
              {new Intl.NumberFormat().format(item.new_balance / 100)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
