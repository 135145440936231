import React from 'react';
import Summary from './Summary';
import InputPin from './InputPin';
import Successful from './Successful';
import Receipt from './Receipt';
import Countries from './Countries';
import { ArrowBackIcon, CloseIcon } from '@chakra-ui/icons';
import { Button, FormNavigator } from 'components/common/Form';
import useDisclosure from 'hooks/useDisclosure';
import {
  executeGetBanksByCountryCode,
  executeInternationalTransfer,
} from 'api/bank';
import { useAppSelector } from 'store/hooks';
import Recipient from './Recipient';
import { useToast } from 'components/Notifications/Toast';
import { IntlTransferData, Transaction, WalletData } from 'types/data';
import { useDrawer } from 'contexts/Drawer';

const International = () => {
  const [state, setState] = React.useState(0);

  const [selectedCountry, setSelectedCountry] = React.useState<any | null>(
    null,
  );
  const [data, setData] = React.useState<any | null>(null);
  const [paymentMethod, setPaymentMethod] = React.useState<string | null>(null);
  const [showRecipientInfo, setShowRecipientInfo] = React.useState(false);
  const token = useAppSelector((state) => state.accountStore.tokenStore);
  const [paymentData, setPaymentData] = React.useState<Transaction | null>(
    null,
  );
  const [selectedWallet, setSelectedWallet] = React.useState<any | null>(null);
  const { showToast } = useToast();

  const [pin, setPin] = React.useState('');
  const wallets = useAppSelector((state) => state.accountStore.wallets);
  const { closeDrawer } = useDrawer();

  const {
    isOpen: isLoading,
    onOpen: openLoading,
    onClose: closeLoading,
  } = useDisclosure();

  const {
    isOpen: isSubmitting,
    onOpen: openSubmit,
    onClose: closeSubmit,
  } = useDisclosure();

  const fetchCountryBanks = async () => {
    try {
      if (!selectedCountry) return;
      openLoading();
      const result = await executeGetBanksByCountryCode(
        token,
        selectedCountry?.value?.code,
      );
      if (!result.isSuccess) throw new Error(result.message);
      setData(result.data);
    } catch (error: any) {
      console.log('error', error);
    } finally {
      closeLoading();
    }
  };

  React.useEffect(() => {
    fetchCountryBanks();
  }, [selectedCountry]);

  const handlePaymentMethodChange = (method: string) => {
    setPaymentMethod(method);
    setShowRecipientInfo(true);
  };

  const handleNextStep = () => {
    if (selectedCountry?.value?.paymentMethods.length > 1 && !paymentMethod) {
      alert('Please select a payment method.');
      return;
    }
    if (showRecipientInfo) {
      setState((prev) => prev + 1);
    } else {
      setShowRecipientInfo(true); // Display recipient info before moving to the next state
    }
  };

  const handleTransfer = async () => {
    if (!paymentData || !selectedWallet) return;
    try {
      openSubmit();
      const payload: IntlTransferData = {
        amount: paymentData.amount,
        accountId: paymentData.accountNumber,
        reason: paymentData.narration,
        walletId: selectedWallet.id,
        counter_party_id: paymentData.counterId,
        currency: selectedWallet.currency,
        dest_currency: selectedCountry?.value?.code,
        pin,
      };
      const result = await executeInternationalTransfer(payload, token);
      if (!result.isSuccess) throw new Error(result.message);
      showToast(result.message, 'success');
      setState((prev: number) => prev + 1);
      return result;
    } catch (err: any) {
      const isNetwork = err.message.toLowerCase().includes('network');
      showToast(
        isNetwork
          ? "Can't connect to internet, check your network settings"
          : err.message,
        isNetwork ? 'warning' : 'error',
      );
    } finally {
      closeSubmit();
    }
  };

  const handleWalletSelect = (wallet: WalletData) => {
    setSelectedWallet({ id: wallet.id, currency: wallet.currency });
  };

  return (
    <>
      {state === 0 && (
        <>
          <div className="flex justify-between w-full mb-12">
            <ArrowBackIcon className="cursor-pointer" onClick={closeDrawer} />
            <CloseIcon className="cursor-pointer" onClick={closeDrawer} />
          </div>
          <h2 className="text-sm text-center text-gray-500">Choose country</h2>
          <Countries
            setState={setState}
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
          />
        </>
      )}

      {state === 1 && (
        <>
          <FormNavigator
            setState={setState}
            state={1}
            closeDrawer={closeDrawer}
          />
          <h2 className="text-sm text-center text-gray-500 mb-8">
            Select Wallet
          </h2>
          {wallets && Object.values(wallets).length > 0 ? (
            <>
              <div className="space-y-4">
                {Object.values(wallets).map((wallet: any) => (
                  <label
                    key={wallet.id}
                    className={`flex items-center space-x-2 p-2 rounded-lg cursor-pointer ${
                      selectedWallet?.id === wallet.id
                        ? 'bg-primary border-primary'
                        : 'bg-white'
                    } border-2 border-gray-200 transition-all duration-200`}
                  >
                    <input
                      type="radio"
                      name="selectedWallet"
                      value={wallet.id}
                      onChange={() => handleWalletSelect(wallet)}
                      checked={selectedWallet?.id === wallet.id}
                      className="form-radio text-blue-600 focus:ring-blue-500"
                    />
                    <span
                      className={
                        selectedWallet?.id === wallet.id
                          ? 'text-white'
                          : 'text-black'
                      }
                    >
                      {wallet.currency} ({wallet.available_balance})
                    </span>
                  </label>
                ))}
              </div>
              <Button
                label="Next"
                onClick={() => {
                  if (selectedWallet) {
                    setState(2);
                  } else {
                    alert('Please select a wallet.');
                  }
                }}
                extraClasses="mb-[2px]"
              />
            </>
          ) : (
            <p>No wallets available</p>
          )}
        </>
      )}

      {state === 2 && (
        <>
          {selectedCountry.value.paymentMethods.length > 1 &&
          !showRecipientInfo ? (
            <>
              <div className="space-y-4">
                <h3 className="text-lg">Select Payment Method</h3>
                {selectedCountry.value.paymentMethods.map((method: string) => (
                  <label key={method} className="flex items-center space-x-2">
                    <input
                      type="radio"
                      name="paymentMethod"
                      value={method}
                      onChange={() => handlePaymentMethodChange(method)}
                      checked={paymentMethod === method}
                    />
                    <span>{method}</span>
                  </label>
                ))}
              </div>
              <Button
                label="Next"
                onClick={handleNextStep}
                extraClasses="mb-[2px]"
              />
            </>
          ) : (
            <>
              <FormNavigator
                setState={setState}
                state={0}
                closeDrawer={closeDrawer}
              />
              <h2 className="text-center text-[1.5rem] font-semibold text-[#585757]">
                Recipient information
              </h2>
              {isLoading ? (
                <p>Loading...</p>
              ) : data ? (
                <>
                  <Recipient
                    paymentMethod={paymentMethod}
                    data={data}
                    selectedCountry={selectedCountry}
                    selectedWallet={selectedWallet}
                    handleNextStep={handleNextStep}
                    setPaymentData={setPaymentData}
                  />
                </>
              ) : (
                // </>
                <p>No data available</p>
              )}
            </>
          )}
        </>
      )}

      {state === 3 && (
        <>
          <FormNavigator
            setState={setState}
            state={2}
            closeDrawer={closeDrawer}
          />
          <h2 className="text-sm text-center text-gray-500">Summary</h2>
          <Summary formData={paymentData} />
          <Button
            label="Confirm"
            onClick={() => setState((prev) => prev + 1)}
            extraClasses="mb-[2px]"
          />
        </>
      )}

      {state === 4 && (
        <>
          <FormNavigator
            setState={setState}
            state={2}
            closeDrawer={closeDrawer}
          />
          <h2 className="text-sm text-center text-gray-500 mb-8">Summary</h2>
          <InputPin
            setState={setState}
            isLoading={isSubmitting}
            setPin={setPin}
            handleTransfer={handleTransfer}
          />
        </>
      )}

      {state === 5 && (
        <>
          <FormNavigator
            setState={setState}
            state={3}
            closeDrawer={closeDrawer}
          />
          <Successful setState={setState} />
        </>
      )}

      {state === 6 && (
        <>
          <FormNavigator
            setState={setState}
            state={4}
            closeDrawer={closeDrawer}
          />
          <Receipt data={paymentData} />
          <div className="flex w-full mt-6">
            <Button label="Download" onClick={() => setState(6)} />
            {/* <button className="border border-red-500 text-red-500 bg-white py-2 px-4 rounded">
              Report
            </button> */}
          </div>
        </>
      )}
    </>
  );
};

export default International;
