import { Button, Input } from 'components/common/Form';
import authBg from 'assets/q1.png';
import { useForm } from 'react-hook-form';
import { paymentFormInputs } from 'utils/data';
import KycLayout from 'layouts/Kyc';
import useDisclosure from 'hooks/useDisclosure';
import { useToast } from 'components/Notifications/Toast';
import { useAppSelector } from 'store/hooks';
import { useNavigate } from 'react-router-dom';
import {
  formatDateForInput,
  generateRandomString,
  getSlug,
} from 'utils/helpers';
import { executeCreateVirtualCard } from 'api/kyc';

import { AccountData } from 'types/data';

const CreateVirtualCard = () => {
  const { trigger, register, handleSubmit } =
    useForm<UserCardRegistrationPayload>({
      mode: 'onSubmit',
    });

  const token = useAppSelector((state) => state.accountStore.tokenStore);
  const user = useAppSelector((state) => state.accountStore.account);

  const navigate = useNavigate();
  const {
    isOpen: isLoading,
    onOpen: openLoading,
    onClose: closeLoading,
  } = useDisclosure();

  const { showToast } = useToast();

  const handleCreateVirtualCard = async (data: any) => {
    if (!(await trigger())) return false;
    if (!user?.id) return;

    try {
      openLoading();
      const payload: any = {
        customerEmail: user.email,
        firstName: user.first_name,
        lastName: user.last_name,
        amount: Number(data.amount),
        reference: generateRandomString(10),
        currency: 'USD',
        cardBrand: data.cardbrand,
        cardType: data.cardtype,
      };

      const result = await executeCreateVirtualCard(payload, token);
      if (!result.isSuccess) throw new Error(result.message);
      showToast(result.message, 'success');
      navigate('/dashboard');
    } catch (error: any) {
      showToast(error.message, 'error');
    } finally {
      closeLoading();
    }
  };

  return (
    <KycLayout background={true} backgroundImage={authBg}>
      <h2 className="text-2xl font-semibold mb-[35px]">Create Virtual Card</h2>
      <form
        className="space-y-6"
        onSubmit={handleSubmit(handleCreateVirtualCard)}
      >
        {paymentFormInputs().map((input, idx) => (
          <Input
            key={idx}
            name={getSlug(input.name)}
            placeholder={input.placeholder}
            color={true}
            label={input.label}
            type={input.type}
            register={register}
            defaultValue={
              input.name === 'dob' && user?.[input.name as keyof AccountData]
                ? formatDateForInput(
                    String(user[input.name as keyof AccountData]),
                  )
                : String(user?.[input.name as keyof AccountData] ?? '')
            }
          />
        ))}

        <Button
          label="Continue"
          loading={isLoading}
          extraClasses="mt-[196px] mb-[24px]"
        />
      </form>
    </KycLayout>
  );
};

export default CreateVirtualCard;
