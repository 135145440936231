import React from 'react';
import useRequestOTP from 'hooks/useRequestOTP';
import OTPVerification from './components/OTPVerification';
// import useNotification from "components/common/useNotification";
import DialPad from './components/DialPad';
import { executeResetPin } from 'api/settings/security';
import { useAppSelector } from 'store/hooks';
import { useToast } from 'components/Notifications/Toast';
import useDisclosure from 'hooks/useDisclosure';
// import { executeResetPin } from "apis/settings/security";
// import { useAppSelector } from "store/hooks";

const ForgotPin = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [newPin, setNewPin] = React.useState('');
  const [confirmPin, setConfirmPin] = React.useState('');
  const { handleRequest, loadingData } = useRequestOTP();
  const { showToast } = useToast();
  const { onOpen: openLoading, onClose: closeLoading } = useDisclosure();

  const token = useAppSelector((state) => state.accountStore.tokenStore);

  React.useEffect(() => {
    handleRequest();
  }, []);

  const handleResetPin = async () => {
    try {
      openLoading();
      const payload: SecuritySettingsData = {
        new_pin: newPin,
      };
      const result = await executeResetPin(payload, token);
      if (!result.isSuccess) throw new Error(result.message);
      showToast(result.message, 'success');
    } catch (error: any) {
      showToast(error.message, 'error');
    } finally {
      closeLoading();
    }
  };

  const steps = [
    {
      name: 'OTP Verification',
      component: (
        <OTPVerification
          cta={setActiveStep}
          isLoading={loadingData}
          resendOtp={handleRequest}
        />
      ),
    },
    {
      name: 'New Pin',
      component: (
        <DialPad
          cta={setActiveStep}
          title={'Input your New PIN'}
          pin={newPin}
          setPin={setNewPin}
        />
      ),
    },
    {
      name: 'Confirm Pin',
      component: (
        <DialPad
          cta={handleResetPin}
          title={'Confirm your new PIN'}
          pin={confirmPin}
          setPin={setConfirmPin}
        />
      ),
    },
  ];

  return <>{steps[activeStep].component}</>;
};

export default ForgotPin;
