import React from 'react';
import { useForm } from 'react-hook-form';
import { profileInputs } from 'utils/data';
// import camera from 'assets/camera.svg';
import { Button, Input } from 'components/common/Form';
import { useAppSelector } from 'store/hooks';
import { AccountData } from 'types/data';

interface ProfileFormProps {
  setActiveStep?: (no: any) => void;
}

const ProfileForm: React.FC<ProfileFormProps> = () => {
  const { register } = useForm();
  const user = useAppSelector((state) => state.accountStore.account);
  return (
    <div className="flex flex-row justify-between px-24 w-11/12">
      <div className="max-w-sm mx-auto flex flex-col space-y-6">
        <p className="text-center text-xs text-black">
          Manage your personal information, and control which information other
          people see and apps may access.
        </p>
        <div className="flex flex-col items-center space-y-4">
          <h2 className="text-xl text-blue-500">
            {user?.first_name?.charAt(0)} {user?.last_name?.charAt(0)}
          </h2>
          {/* <img src={camera} alt="Camera" /> */}
          <p className="text-lg font-bold text-black">
            {user?.first_name} {user?.last_name}
          </p>
          <p className="text-sm text-gray-500">USERID: {user?.id}</p>
          {/* <button
            className="border border-blue-500 text-blue-500 text-sm rounded px-4 py-2"
            onClick={() => setActiveStep((prev: number) => prev + 1)}
          >
            QR Code
          </button> */}
        </div>
        <div className="space-y-6 w-12/12">
          {profileInputs().map((input, idx) => (
            <Input
              key={idx}
              type={input.type}
              color={true}
              name={input.name}
              placeholder={input.placeholder}
              label={input.label}
              register={register}
              required
              defaultValue={String(
                user?.[input.name as keyof AccountData] ?? '',
              )}
            />
          ))}
        </div>
      </div>
      <div className="w-3/12">
        <Button label=" Update Profile" />
      </div>
    </div>
  );
};

export default ProfileForm;
