import './App.css';
import Aos from 'aos';
import { useRef, useState, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import useDisclosure from 'hooks/useDisclosure';
import Modal from 'components/Modal';
import { Button } from 'components/common/Form';
import { clearAccount } from 'store/account/accountSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { persistor } from 'store';
import { Route, Routes } from 'react-router-dom';
import routes from 'utils/routes';
import { Navigate } from 'react-router-dom';

function App() {
  Aos.init();

  const idleTimerRef = useRef(null);
  const [countdown, setCountdown] = useState(1800); // 180 seconds = 3 minutes
  const dispatch = useAppDispatch();
  const { isOpen: isModalOpen, onClose: closeModal } = useDisclosure();

  // Get isAuthenticated from the Redux store
  const { isAuthenticated } = useAppSelector((state) => state.accountStore);

  useIdleTimer({
    crossTab: true,
    ref: idleTimerRef,
    timeout: 500 * 1000, // Optional: you can keep idle timeout logic for user inactivity
  });

  // Start countdown on authentication and log out after 3 minutes (JWT expiry)
  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (isAuthenticated) {
      // Start countdown when user is authenticated
      timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            handleLogout(); // Call logout function when countdown reaches 0
            return 0; // Reset countdown
          }
          return prev - 1;
        });
      }, 1000); // Countdown every second
    }

    return () => clearInterval(timer); // Clear timer when component unmounts or user logs out
  }, [isAuthenticated]); // Trigger this effect only when user is authenticated

  // Handle logout function
  const handleLogout = () => {
    dispatch(clearAccount());
    localStorage.clear();
    persistor.pause();
    persistor.flush().then(() => persistor.purge());
    window.location.href = '/login';
  };

  return (
    <>
      <Routes>
        {routes.map((route, idx) => {
          if (route.children) {
            return (
              <Route key={idx} element={route.element} path={route.path}>
                {route.children.map((item: any, index: any) => (
                  <Route
                    key={index}
                    element={
                      item.protected ? (
                        <ProtectedRoute>{item.element}</ProtectedRoute>
                      ) : (
                        item.element
                      )
                    }
                    path={item.path}
                  />
                ))}
              </Route>
            );
          }
          return (
            <Route
              key={idx}
              element={
                route.protected ? (
                  <ProtectedRoute>{route.element}</ProtectedRoute>
                ) : (
                  route.element
                )
              }
              path={route.path}
            />
          );
        })}
      </Routes>

      {isAuthenticated && (
        <Modal
          closeModal={closeModal}
          isOpen={isModalOpen}
          title={'Logout User'}
          width={'max-w-lg'}
        >
          <div className="text-center">
            <div className="idle-warning">
              <p>Your session will expire in {countdown} seconds.</p>
              <p>Please interact with the application to stay logged in.</p>
            </div>
            <div className="flex justify-center gap-4">
              <Button
                extraClasses="bg-red-600 text-white px-4 py-2 rounded-md"
                label="Logout"
                onClick={handleLogout}
              />
              <Button
                outline={true}
                extraClasses="text-gray-600 border-gray-300 px-4 py-2 rounded-md"
                label="Stay logged in"
                onClick={closeModal}
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default App;

const ProtectedRoute = ({ children }: any) => {
  const { isAuthenticated } = useAppSelector((state) => state.accountStore);

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return children;
};
