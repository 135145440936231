import React from 'react';
import coin from 'assets/coin.png';
import { Button } from 'components/common/Form';

interface Props {
  setState: (prev: any) => void;
}

const Successful: React.FC<Props> = ({ setState }) => {
  return (
    <div className="bg-white px-8 pb-16 space-y-20 rounded-lg">
      <img src={coin} alt="Coin" className="mx-auto" />
      <div className="text-center space-y-2">
        <h2 className="text-[20px] text-[#000000] font-semibold">
          Transaction Successful
        </h2>
        <p className="text-sm text-[#585757]">Your transaction is successful</p>
      </div>
      <div className="flex justify-center space-x-4">
        <Button label="Download" onClick={() => setState(6)} />
      </div>
    </div>
  );
};

export default Successful;
