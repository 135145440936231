import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Textarea, Input, Button } from 'components/common/Form';
import { executeSetSecurityQuestion } from 'api/settings/security';
import { useAppSelector } from 'store/hooks';
import { useToast } from 'components/Notifications/Toast';

const SecurityQuestion = () => {
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const token = useAppSelector((state) => state.accountStore.tokenStore);
  const {
    getValues,
    trigger,
    register,
    formState: { errors },
  } = useForm<SecuritySettingsData>();

  const handleSetSecurityQuestion = async () => {
    try {
      if (!(await trigger())) return false;
      setLoading(true);
      const payload: SecuritySettingsData = {
        question: getValues('question'),
        answer: getValues('answer'),
      };
      const result = await executeSetSecurityQuestion(payload, token);
      if (!result.isSuccess) throw new Error(result.message);
      showToast(result.message, 'success');
    } catch (error: any) {
      showToast(error.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      data-aos="flip-right"
      data-aos-duration="1000"
      data-aos-delay="10"
      data-aos-offset="200"
      className=" w-[375px] px-6 py-4 bg-white h-[685px]"
    >
      <h2 className="text-2xl font-semibold mb-6">Security Question</h2>
      <p className="text-gray-600 text-sm mb-8">
        Create your security question here
      </p>
      <div className="space-y-6 mt-14">
        <div className="flex flex-col">
          <label htmlFor="question" className="text-left mb-2">
            Security Question
          </label>

          <Input
            color={true}
            register={register}
            name={'question'}
            placeholder={'Choose a Security question'}
            label={''}
            type={'text'}
          />
          {errors['question'] && (
            <p className="text-red-500 text-xs mt-1">
              Security {errors['question']?.message?.toString()}
            </p>
          )}
        </div>
        <div className="flex flex-col mb-[40px]">
          <label htmlFor="answer" className="text-left mb-2">
            Answer
          </label>
          <Textarea
            name={'answer'}
            color={true}
            label=""
            rows={3}
            placeholder="Placeholder text"
            register={register}
          />
          {errors['answer'] && (
            <p className="text-red-500 text-xs mt-1">
              {errors['answer']?.message?.toString()}
            </p>
          )}
        </div>
      </div>
      <Button
        onClick={handleSetSecurityQuestion}
        label={loading ? 'Saving...' : 'Save'}
        extraClasses="mt-[100px]"
      />
    </div>
  );
};

export default SecurityQuestion;
