import React from 'react';
import AuthLayout from 'layouts/Auth';
import { useNavigate } from 'react-router-dom';
import authBg from 'assets/secure-successful.png';
import lock from 'assets/padlock.png';
import { Button } from 'components/common/Form';
import useDisclosure from 'hooks/useDisclosure';
import { useAppSelector } from 'store/hooks';
import { useToast } from 'components/Notifications/Toast';
import { executeCreateAccount } from 'api/kyc';

const GetWallet = () => {
  const navigate = useNavigate();
  const {
    onOpen: openLoading,
    onClose: closeLoading,
    isOpen: isLoading,
  } = useDisclosure();
  const token = useAppSelector((state) => state.accountStore.tokenStore);
  const userId = useAppSelector((state) => state.accountStore.account?.id);

  const { showToast } = useToast();

  const handleCreateAccount = async () => {
    if (!userId) return;
    try {
      openLoading();
      const result = await executeCreateAccount(userId, token);
      if (!result.isSuccess) throw new Error(result.message);
      showToast(result.message, 'success');
      navigate('/dashboard');
    } catch (error: any) {
      showToast(error.message, 'error');
    } finally {
      closeLoading();
    }
  };

  return (
    <AuthLayout background={true} backgroundImage={authBg}>
      <img src={lock} className="mx-auto" alt="Padlock" />
      <div className="space-y-4 px-6">
        <h2 className="text-2xl font-semibold text-center">
          Kyc Validated Successfully
        </h2>
        <p className="text-sm text-center text-gray-500">
          Your KYC has been successfully validated. Get your account wallet now
          to start sending funds.
        </p>
        <Button
          label="Create Account"
          onClick={handleCreateAccount}
          loading={isLoading}
        />

        <Button
          label="Do This Later"
          onClick={() => navigate('/')}
          loading={false}
          outline={true}
          extraClasses="border border-primary"
        />
      </div>
    </AuthLayout>
  );
};

export default GetWallet;
