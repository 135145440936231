// components/Carousel.tsx
import React, { useState } from 'react';
import VirtualCard from './VirtualCard';
import { Button } from 'components/common/Form';
import {
  CategoryIcon,
  DocumentCopyIcon,
  KeyIcon,
  WalletMinus,
} from 'components/icons';

const cards = [
  {
    cardNumber: '**** **** **** 1234',
    cardHolderName: 'John Doe',
    expiryDate: '12/25',
    country: 'USA',
  },
  {
    cardNumber: '**** **** **** 5678',
    cardHolderName: 'Jane Doe',
    expiryDate: '06/24',
    country: 'Nigeria',
  },
  {
    cardNumber: '**** **** **** 9012',
    cardHolderName: 'David Smith',
    expiryDate: '11/23',
    country: 'UK',
  },
];
const Carousel: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleDotClick = (index: number) => {
    setCurrentIndex(index);
  };

  const cardItems = [
    {
      name: 'card Digits',
      icon: WalletMinus,
    },
    {
      name: 'CVV',
      icon: KeyIcon,
    },
    {
      name: 'card Digits',
      icon: CategoryIcon,
    },
  ];

  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-col items-center w-[374px] pt-6 pb-11 px-6 shadow-lg bg-[white]">
        <div>
          <VirtualCard
            cardNumber={cards[currentIndex].cardNumber}
            cardHolderName={cards[currentIndex].cardHolderName}
            expiryDate={cards[currentIndex].expiryDate}
            country={cards[currentIndex].country} // Pass the country here
          />
        </div>
        <div className="flex space-x-2 mt-4 mb-8">
          {cards.map((_, index) => (
            <button
              key={index}
              className={`w-2 h-2 rounded-full ${
                currentIndex === index ? 'bg-primary' : 'bg-[#E8E8E8]'
              }`}
              onClick={() => handleDotClick(index)}
            />
          ))}
        </div>
        <h2 className="text-lg font-semibold mb-4">Take Note</h2>
        <p className="text-center text-[#585757] font-poppins font-normal text-[14px] leading-[21px]">
          You should not share your card details with anyone. Toggle the eye to
          see your digits.
        </p>
        <div className="w-full">
          {cardItems.map((item, idx) => (
            <div
              key={idx}
              className="flex p-4 justify-between border-b-2 border-[#E8E8E8]"
            >
              <div className="flex gap-4">
                <item.icon color="red" />
                {item.name}
              </div>
              <div className="cursor-pointer">
                <DocumentCopyIcon />
              </div>
            </div>
          ))}
        </div>
        <div className="flex flex-row w-full items-center justify-center gap-6">
          <Button extraClasses="bg-[#FF4C51]" label="Block Card" />
          <Button outline={true} label="Shuffle Digits" />
        </div>
      </div>
    </div>
  );
};

export default Carousel;
