import React, { ReactNode } from 'react';

interface AuthLayoutProps {
  children?: ReactNode;
  smaller?: boolean;
  background?: boolean;
  backgroundImage?: string;
  backgroundOverlay?: string;
}

const KycLayout: React.FC<AuthLayoutProps> = ({
  children,
  smaller,
  backgroundImage,
  backgroundOverlay,
}) => {
  return (
    <div className="flex h-screen w-full overflow-hidden">
      {/* Left Column */}
      <div
        className={`w-1/2 overflow-y-auto ${backgroundOverlay ?? 'bg-white'}`}
      >
        <div className="flex items-center justify-center min-h-full">
          <div
            className={`p-6 ${
              smaller ? 'max-w-sm' : 'max-w-md'
            } mx-auto w-full`}
          >
            {children}
          </div>
        </div>
      </div>

      {/* Right Column */}
      <div
        className={`hidden md:flex flex-col w-1/2 bg-primary bg-cover relative px-24 py-16 text-white`}
      >
        {backgroundImage && (
          <div className="absolute top-36 left-0">
            <img src={backgroundImage} alt="Background" />
          </div>
        )}
        <h1 className="text-2xl mb-4">
          Lets Get to know you, so we can continue this amazing journey.
        </h1>
        <p className="text-xs">Follow these steps</p>
      </div>
    </div>
  );
};

export default KycLayout;
