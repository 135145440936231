import {
  CREATE_CYBRID_ACCOUNT,
  CREATE_CYBRID_WALLET_ACCOUNT_ENDPOINT,
  CREATE_EXTERNAL_BANK_ACCOUNT,
  CREATE_PLAID_WORKFLOW_ENDPOINT,
  CREATE_VIRTUAL_CARD,
  CREATE_WALLET_ACCOUNT_ENDPOINT,
  GET_IDENTITY_VERIFICATION,
  GET_WORKFLOW_ENDPOINT,
  REGISTER_VIRTUAL_CARD_USER,
  UPDATE_KYC_ENDPOINT,
  UPLOAD_IMAGE_ENDPOINT,
  VERIFY_CYBRID_IDENTITY,
  VERIFY_KYC_ENDPOINT,
} from 'api';
import { default as axios } from 'config/axios';
import { log } from 'utils/helpers';

export const executeUpdateKyc = async (
  data: KycUploads,
  token: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).post(UPDATE_KYC_ENDPOINT, data);
    return response.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: 'error' };
  }
};

export const executeVerifyKyc = async (
  data: VerifyKycData,
  token: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).post(VERIFY_KYC_ENDPOINT, data);
    return response.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: 'error' };
  }
};

export const executeCreateBrailsWallet = async (
  token: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).get(VERIFY_KYC_ENDPOINT);
    return response.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: 'error' };
  }
};

export const executeUploadImage = async (
  data: FormData,
  token: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token, true).post(UPLOAD_IMAGE_ENDPOINT, data);
    return response.data.data.url;
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: 'error' };
  }
};

export const executeCreateAccount = async (
  userId: string,
  token: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).get(
      CREATE_WALLET_ACCOUNT_ENDPOINT(userId),
    );

    // const response = (await request.json()) satisfies ResponseDataType;
    return response.data;
  } catch (error: any) {
    log('LOGIN [ERROR]:', error.message);
    return { message: error.message, isSuccess: false } as ResponseDataType;
  }
};

export const executeCreateCybridWallet = async (
  walletId: string,
  token: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).get(
      CREATE_CYBRID_WALLET_ACCOUNT_ENDPOINT(walletId),
    );

    console.log({ response });

    // const response = (await request.json()) satisfies ResponseDataType;
    return response.data;
  } catch (error: any) {
    log('LOGIN [ERROR]:', error.message);
    return { message: error.message, isSuccess: false } as ResponseDataType;
  }
};

export const executeCreateCybridAccount = async (
  payload: CybridAccountPayload,
  userId: string,
  token: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).post(
      CREATE_CYBRID_ACCOUNT(userId),
      payload,
    );
    return response.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: 'error' };
  }
};

export const executeRegisterVirtualCard = async (
  payload: UserCardRegistrationPayload,
  userId: string,
  token: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).post(
      REGISTER_VIRTUAL_CARD_USER(),
      payload,
    );
    return response.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: 'error' };
  }
};

export const executeCreateVirtualCard = async (
  payload: UserCardRegistrationPayload,
  token: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).post(CREATE_VIRTUAL_CARD(), payload);
    return response.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: 'error' };
  }
};

export const executeCreateIdentityVerification = async (
  payload: Customer,
  token: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).post(VERIFY_CYBRID_IDENTITY, payload);
    return response.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: 'error' };
  }
};

export const executeCreateCybridIdentityVerification = async (
  payload: { customer_guid: string },
  token: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).post(VERIFY_CYBRID_IDENTITY, payload);
    return response.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: 'error' };
  }
};
export const executeGetIdentityVerification = async (
  currency: string,
  userId: string,
  token: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).get(
      GET_IDENTITY_VERIFICATION(currency, userId),
    );
    return response.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: 'error' };
  }
};

export const executeCreateIdentityVerificationTwo = async (
  data: any,
  token: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).post(VERIFY_CYBRID_IDENTITY, data);
    return response.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: 'error' };
  }
};

export const executeCreatePlaidWorkflow = async (
  data: any,
  token: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).post(
      CREATE_PLAID_WORKFLOW_ENDPOINT(),
      data,
    );
    return response.data;
  } catch (error: any) {
    log('LOGIN [ERROR]:', error.message);
    return { message: error.message, isSuccess: false } as ResponseDataType;
  }
};

export const executeGetWorkflow = async (
  workflowId: string,
  token: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).get(GET_WORKFLOW_ENDPOINT(workflowId));
    return response.data;
  } catch (error: any) {
    log('LOGIN [ERROR]:', error.message);
    return { message: error.message, isSuccess: false } as ResponseDataType;
  }
};

export const executeCreateExternalBankAccount = async (
  data: any,
  token: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).post(
      CREATE_EXTERNAL_BANK_ACCOUNT,
      data,
    );
    return response.data;
  } catch (error: any) {
    log('LOGIN [ERROR]:', error.message);
    return { message: error.message, isSuccess: false } as ResponseDataType;
  }
};
