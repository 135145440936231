import React, { useRef } from 'react';
import { executeUploadImage } from 'api/kyc';
import { useAppSelector } from 'store/hooks';
import { FiUpload } from 'react-icons/fi';

interface UploadInputProps {
  register: (...args: any[]) => any;
  error?: string;
  name: string;
  label: string;
  setImageUrls: React.Dispatch<React.SetStateAction<KycUploads | undefined>>;
  setValue: (name: string, value: File) => void;
  setError: (name: string, value: any) => void;
  value?: File;
  accept?: string;
  maxFileSize?: number; // in MB
}

const UploadInput: React.FC<UploadInputProps> = ({
  error,
  setError,
  accept = '.pdf, .docx', // Default file types
  maxFileSize = 2, // Default max file size in MB
  name,
  label,
  setValue,
  register,
  value,
  setImageUrls,
}) => {
  const fileRef = useRef<HTMLInputElement>(null);
  const token = useAppSelector((state) => state.accountStore.tokenStore);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files || files.length === 0) {
      return setError(name, {
        message: 'Please select a file',
        type: 'required',
      });
    }

    const file = files[0];

    // Check file size
    const maxSizeInBytes = maxFileSize * 1024 * 1024;
    if (file.size > maxSizeInBytes) {
      return setError(name, {
        message: `File size should not exceed ${maxFileSize}MB`,
        type: 'size',
      });
    }

    const formData = new FormData();
    formData.append('file', file);

    const imageUrl = await executeUploadImage(formData, token);

    setImageUrls((prev: KycUploads | undefined) => ({
      ...prev,
      [name]: imageUrl,
      id_type: prev?.id_type || '', // Ensures `id_type` is always present
    }));

    setValue(name, file);
  };

  return (
    <div className={`form-control ${error ? 'is-invalid' : ''}`}>
      <label
        className="block text-base font-normal text-[#585757] mb-1"
        htmlFor={name}
      >
        {label}
      </label>
      <input
        onChange={handleChange}
        type="file"
        hidden
        ref={fileRef}
        accept={accept}
      />
      <div className="relative">
        <input
          readOnly
          value={value?.name || ''}
          className="block w-full pl-3 pr-12 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-brand-500"
          placeholder="Select file"
          {...register(name, { required: 'Please upload a document' })}
        />
        <div
          className="absolute inset-y-0 right-0 flex items-center justify-center w-12 cursor-pointer"
          onClick={() => fileRef.current?.click()}
        >
          <FiUpload className="h-6 w-6 text-gray-400" />
        </div>
      </div>

      {/* Error message */}
      {error && <p className="mt-1 text-sm text-red-600">{error}</p>}

      {/* File size and type caption */}
      <p className="mt-1 text-sm text-gray-500">
        Max file size: {maxFileSize}MB. Accepted types: {accept}.
      </p>
    </div>
  );
};

export default UploadInput;
