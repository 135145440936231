import {
  CHANGE_PASSWORD_ENDPOINT,
  CHANGE_PIN_ENDPOINT,
  FORGOT_PIN_ENDPOINT,
  REQUEST_OTP_ENDPOINT,
  SET_SECURITY_QUESTION_ENDPOINT,
  SETUP_2FA,
  UPDATE_SECURITY_QUESTION_ENDPOINT,
  VERIFY_OTP_ENDPOINT,
} from 'api';
import { default as axios } from 'config/axios';

export const executeRequestOtp = async (
  data: VerificationType,
  token?: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).post(REQUEST_OTP_ENDPOINT, data);
    return response.data;
  } catch (error: any) {
    console.log({ res: error });
    return { message: error.message, isSuccess: false, status: 'error' };
  }
};

export const executeVerifyOtp = async (
  data: VerificationType,
  token?: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).post(VERIFY_OTP_ENDPOINT, data);
    return response.data;
  } catch (error: any) {
    console.log({ error });
    return {
      message: error.response.data.message,
      isSuccess: false,
      status: 'error',
    };
  }
};

export const executeChangePin = async (
  data: SecuritySettingsData,
  token: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).post(CHANGE_PIN_ENDPOINT, data);
    return response.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: 'error' };
  }
};

export const executeSet2Fa = async (
  id: string,
  token: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).post(SETUP_2FA, { id });
    return { ...response.data, isSuccess: true };
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: 'error' };
  }
};

export const executeResetPin = async (
  data: SecuritySettingsData,
  token: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).post(FORGOT_PIN_ENDPOINT, data);
    return response.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: 'error' };
  }
};

export const executeChangePassword = async (
  data: SecuritySettingsData,
  token: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).post(CHANGE_PASSWORD_ENDPOINT, data);
    return response.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: 'error' };
  }
};

export const executeSetSecurityQuestion = async (
  data: SecuritySettingsData,
  token: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).post(
      SET_SECURITY_QUESTION_ENDPOINT,
      data,
    );
    return response.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: 'error' };
  }
};

export const executeUpdateSecurityQuestion = async (
  data: SecuritySettingsData,
  token: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).post(
      UPDATE_SECURITY_QUESTION_ENDPOINT,
      data,
    );
    return response.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: 'error' };
  }
};
