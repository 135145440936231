import moneyCard from 'assets/moneycard.png';
import { Button } from 'components/common/Form';
import { useNavigate } from 'react-router-dom';

interface Props {
  setActiveStep: (no: any) => void;
}

const Initial: React.FC<Props> = ({ setActiveStep }) => {
  const navigate = useNavigate();
  const handleRegisterUser = () => {
    navigate(`/register-card-user?currency=USD`);
  };
  return (
    <div className="flex flex-col items-center text-center">
      <div className="w-[374px] pt-[24px] pb-[73px] px-[24px] bg-[white] shadow-lg">
        <div className="my-6">
          <img src={moneyCard} alt="3d money card" className="mx-auto" />
        </div>
        <h2 className="text-lg font-semibold mb-4">
          Your Virtual Card <br /> is on the way!
        </h2>
        <p className="text-sm text-[#585757] font-normal">
          Your account will be charged with $2 used in collecting the virtual
          card for the individual wallet.
        </p>
        <div>
          <Button
            label="Send Request"
            onClick={handleRegisterUser}
            // onClick={() => setActiveStep((prev: number) => prev + 1)}
          />
          <Button
            label="Cancel Request"
            outline={true}
            onClick={() => setActiveStep((prev: number) => prev - 1)}
          />
        </div>
      </div>
    </div>
  );
};

export default Initial;
