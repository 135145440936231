import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { DrawerProvider } from 'contexts/Drawer';
import Drawer from 'components/Drawer/index';
import 'aos';
import 'aos/dist/aos.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import '@fontsource/poppins/800.css';
import '@fontsource/poppins/900.css';
import { SnackbarProvider } from 'notistack';
import ReduxProvider from 'store/provider';
import AppContextProvider from 'contexts/App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <ReduxProvider>
    <BrowserRouter>
      <SnackbarProvider />
      <AppContextProvider>
        <DrawerProvider>
          <App />
          <Drawer side="right" />
        </DrawerProvider>
      </AppContextProvider>
    </BrowserRouter>
  </ReduxProvider>,
);
reportWebVitals();
