import React from 'react';
import DashboardLayout from 'layouts/Dashboard';
import { Outlet } from 'react-router-dom';
import Tabs from './Tabs';

const Index = () => {
  return (
    <DashboardLayout
      title={'settings'}
      hasItsOwnHeader={true}
      bgColor="#F5FAFE"
    >
      <div className="relative">
        <div className="border-b-2 border-[#E8E8E8] xl:w-5/6 w-full 2xl:max-w-[1640px] px-[24px] flex items-center bg-white bg-opacity-100 fixed top-0 h-[150px]">
          <Tabs />
        </div>
        <div className="pt-[100px] mt-[100px] px-[24px] h-screen">
          <Outlet />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Index;
