import React from 'react';
import qrcode from 'assets/qrcode.png';
import { useAppSelector } from 'store/hooks';

interface QRcodeProps {
  setActiveStep: (no: any) => void;
}

const qrSteps = [
  'Open your scanner on your phone.',
  'Place it in front of the QR code on the screen.',
  'This shows you the account you are scanning',
  'Copy the account Number you want to pay to.',
];

const QRcode: React.FC<QRcodeProps> = ({ setActiveStep }) => {
  const user = useAppSelector((state) => state.accountStore.account);
  return (
    <div className="grid grid-cols-2 gap-6">
      <div className="text-center">
        <div className="flex flex-col items-center">
          <p className="text-lg font-bold text-black">
            {user?.first_name} {user?.last_name}
          </p>
          <p className="text-sm text-gray-400">Scan to pay me</p>
        </div>
        <div className="flex flex-col items-center mt-16">
          <h2 className="text-center font-normal text-xl mb-4">How to scan</h2>
          <ol className="space-y-6 list-decimal list-inside">
            {qrSteps.map((item, idx) => (
              <li key={idx} className="font-normal text-base">
                {item}
              </li>
            ))}
          </ol>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center">
        <img src={qrcode} className="h-52 w-52" alt="QR code" />
        {/* <button className="border border-gray-400 text-gray-400 rounded px-4 py-1 mt-4 w-24">
          Share
        </button> */}
        <button
          className="mt-4 text-sm text-blue-600"
          onClick={() => setActiveStep((prev: number) => prev - 1)}
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default QRcode;
