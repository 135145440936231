import { Tab, TabGroup, TabList } from '@headlessui/react';
import { settingsTab } from 'utils/data';
import { useNavigate, useLocation } from 'react-router-dom';

const Tabs = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const initialSelectedIndex = settingsTab.findIndex(
    (tab) => tab.path === location.pathname,
  );

  return (
    <TabGroup defaultIndex={initialSelectedIndex}>
      <TabList className="flex gap-4">
        {settingsTab.map(({ title, id, path }) => (
          <Tab
            key={id}
            onClick={() => navigate(path)}
            className="py-1 px-3 text-sm font-normal text-[#585757] 
            focus:outline-none data-[selected]:border-b-[3px] border-primary 
            data-[hover]:underline-offset-4 data-[selected]:data-[hover]:bg-white/10 
            data-[focus]:outline-1 data-[focus]:outline-white"
          >
            {title}
          </Tab>
        ))}
      </TabList>
    </TabGroup>
  );
};

export default Tabs;
