import React from 'react';
import AuthLayout from 'layouts/Auth';
import { useForm } from 'react-hook-form';
import { addressInputs } from 'utils/data';
import authBg from 'assets/bvn-auth.png';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { executeAddAddress } from 'api/settings/users';
import { STEPS, updateLevel } from 'store/registration/registerSlice';
import { Button, Input } from 'components/common/Form';
import { useToast } from 'components/Notifications/Toast';

interface AddressProps {
  setActiveStep: (no: any) => void;
}

const Address: React.FC<AddressProps> = ({ setActiveStep }) => {
  const {
    trigger,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AddressData>({
    mode: 'onSubmit',
  });

  const token = useAppSelector((state) => state.accountStore.tokenStore);
  const {
    data: { ACCOUNT_ID },
  } = useAppSelector((state) => state.registerStore);
  const dispatch = useAppDispatch();

  const { showToast } = useToast();
  const [isLoading, setIsLoading] = React.useState(false);

  const onSubmit = async (data: AddressData) => {
    if (!(await trigger())) return false;
    try {
      setIsLoading(true);
      const payload: AddressData = {
        address: data.address,
        postal_code: data.postal_code,
        account_id: ACCOUNT_ID,
        state: data.state,
        country: data.country,
        type: 'default',
      };
      const result = await executeAddAddress(payload, token);
      if (!result.isSuccess) throw new Error(result.message);
      dispatch(
        updateLevel({
          step: STEPS.SECURE_TRANSACTIONS_GUIDE,
          data: {
            ['STATE']: data.state,
            ['COUNTRY']: data.country,
            ['ADDRESS']: data.address,
          },
        }),
      );
      setActiveStep((prev: number) => prev + 1);
    } catch (error: any) {
      showToast(error.message, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthLayout background={true} backgroundImage={authBg}>
      <div className="flex justify-between items-center mb-14">
        <h2 className="text-2xl font-semibold">Residential Address</h2>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        {addressInputs().map((input, idx) => (
          <Input
            key={idx}
            name={input.name}
            type={input.type}
            placeholder={input.placeholder}
            color={true}
            label={input.label}
            register={register}
            required={input.isRequired}
            errors={errors}
          />
        ))}
        <Button
          loading={isLoading}
          label={isLoading ? 'Loading...' : 'Continue'}
        />
      </form>
    </AuthLayout>
  );
};

export default Address;
