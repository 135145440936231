export const loginInputs = (): AuthInput[] => {
  return [
    {
      name: 'email',
      label: 'Email',
      rule: 'Email is required',
      placeholder: 'Example@gmail.com',
      isRequired: true,
      type: 'email',
    },
    {
      name: 'password',
      label: 'Password',
      rule: 'Password must be entered',
      isRequired: true,
      type: 'password',
      placeholder: '*********',
    },
  ];
};

export const extraTransferInputs = (): AuthInput[] => {
  return [
    {
      name: 'street2',
      label: 'Street 2',
      rule: 'Street is required',
      placeholder: '123 ERxample street',
      isRequired: true,
      type: 'text',
    },
    {
      name: 'street',
      label: 'Street',
      rule: 'Street is required',
      placeholder: '123 Example street',
      isRequired: true,
      type: 'text',
    },
    {
      name: 'city',
      label: 'City',
      rule: 'City is required',
      placeholder: 'City',
      isRequired: true,
      type: 'text',
    },
    {
      name: 'destinationAddress',
      label: 'Address',
      rule: 'Address is required',
      placeholder: 'City',
      isRequired: true,
      type: 'text',
    },
  ];
};

export const registrationInputs = (): AuthInput[] => {
  return [
    {
      name: 'first_name',
      label: 'First Name',
      rule: 'Enter a valid first name',
      minLength: 3,
      message: 'First name should be at least 3 characters ',
      placeholder: 'Placeholder',
      isRequired: true,
      type: 'text',
    },
    {
      name: 'middle_name',
      label: 'Middle Name',
      rule: 'Enter a valid middle name',
      minLength: 3,
      message: 'Middle name should be at least 3 characters ',
      placeholder: 'Placeholder',
      isRequired: false,
      type: 'text',
    },
    {
      name: 'last_name',
      label: 'Last Name',
      rule: 'Enter a valid last name',
      minLength: 3,
      message: 'Last name should be at least 3 characters ',
      placeholder: 'Placeholder',
      isRequired: true,
      type: 'text',
    },
    {
      name: 'phone_number',
      label: 'Phone Number',
      rule: 'Enter a valid phone number',
      placeholder: '0008166351',
      isRequired: true,
      type: 'number',
    },
  ];
};

export const registrationInputsTwo = (): AuthInput[] => {
  return [
    {
      name: 'dob',
      label: 'Date of birth',
      rule: 'Please select a valid date',
      message: 'Please select a valid date',
      isRequired: true,
      type: 'date',
    },
    {
      name: 'bvn',
      label: 'BVN',
      rule: 'BVN is required',
      message: 'Please include your BVN number ',
      placeholder: 'Placeholder',
      isRequired: true,
      type: 'number',
    },
  ];
};

export const ssnRegistrationInputs = (): AuthInput[] => {
  return [
    {
      name: 'first_name',
      label: 'First Name',
      rule: 'First name is required',
      minLength: 3,
      message: 'First name should be at least 3 characters ',
      placeholder: 'Placeholder',
      isRequired: true,
      type: 'text',
    },
    {
      name: 'middle_name',
      label: 'Middle Name',
      rule: 'Middle name is required',
      minLength: 3,
      message: 'Middle name should be at least 3 characters ',
      placeholder: 'Placeholder',
      isRequired: true,
      type: 'text',
    },
    {
      name: 'last_name',
      label: 'Last Name',
      rule: 'Last name is required',
      minLength: 3,
      message: 'Last name should be at least 3 characters ',
      placeholder: 'Placeholder',
      isRequired: true,
      type: 'text',
    },
    {
      name: 'email',
      label: 'Email',
      rule: 'Email is required',
      placeholder: 'Example@gmail.com',
      isRequired: true,
      type: 'email',
    },
    {
      name: 'dob',
      label: 'Date of birth',
      rule: 'Please select a valid date',
      message: 'Please select a valid date',
      isRequired: true,
      type: 'date',
    },
  ];
};

export const cardRegistrationInputs = (): AuthInput[] => {
  return [
    {
      name: 'firstName',
      label: 'First Name',
      rule: 'First name is required',
      minLength: 3,
      message: 'First name should be at least 3 characters',
      placeholder: 'Enter your first name',
      isRequired: true,
      type: 'text',
    },
    {
      name: 'lastName',
      label: 'Last Name',
      rule: 'Last name is required',
      minLength: 3,
      message: 'Last name should be at least 3 characters',
      placeholder: 'Enter your last name',
      isRequired: true,
      type: 'text',
    },
    {
      name: 'email',
      label: 'Email',
      rule: 'Email is required',
      placeholder: 'Example@gmail.com',
      isRequired: true,
      type: 'email',
    },
    {
      name: 'idNumber',
      label: 'ID Number',
      rule: 'ID number is required',
      placeholder: 'Enter your ID number',
      isRequired: true,
      type: 'text',
    },
    {
      name: 'idIssuanceDate',
      label: 'Issuance Date',
      rule: 'Issuance date is required',
      placeholder: 'Enter your Issuance date',
      isRequired: true,
      type: 'text',
    },
    {
      name: 'idIssuanceCountry',
      label: 'Issuance Country',
      rule: 'Issuance Countr is required',
      placeholder: 'Enter your Issuance country',
      isRequired: true,
      type: 'text',
    },
    {
      name: 'idExpiryDate',
      label: 'Expiry Date',
      rule: 'Id Expiry Date is required',
      placeholder: 'Enter your Expiry Date',
      isRequired: true,
      type: 'text',
    },
    {
      name: 'idType',
      label: 'ID Type',
      rule: 'ID type is required',
      placeholder: 'e.g. driver-license',
      isRequired: true,
      type: 'select',
      data: [
        {
          id: 1,
          name: 'NIN',
          value: 'NIN',
        },
      ],
    },
    {
      name: 'phoneNumber',
      label: 'Phone Number',
      rule: 'Phone number is required',
      placeholder: '08105951215',
      isRequired: true,
      type: 'tel',
    },
    {
      name: 'city',
      label: 'City',
      rule: 'City is required',
      placeholder: 'Enter your city',
      isRequired: true,
      type: 'text',
    },
    {
      name: 'state',
      label: 'State',
      rule: 'State is required',
      placeholder: 'Enter your state',
      isRequired: true,
      type: 'text',
    },
    {
      name: 'country',
      label: 'Country',
      rule: 'Country is required',
      placeholder: 'Enter your country',
      isRequired: true,
      type: 'text',
    },
    {
      name: 'zipCode',
      label: 'Zip Code',
      rule: 'Zip code is required',
      placeholder: 'Enter your zip code',
      isRequired: true,
      type: 'text',
    },
    {
      name: 'line1',
      label: 'Address Line 1',
      rule: 'Address Line 1 is required',
      placeholder: 'Enter your address',
      isRequired: true,
      type: 'text',
    },
    {
      name: 'houseName',
      label: 'House Name',
      rule: 'House name is required',
      placeholder: 'Enter your house name',
      isRequired: true,
      type: 'text',
    },
    {
      name: 'idImage',
      label: 'ID Image',
      rule: 'ID image is required',
      isRequired: true,
      type: 'upload', // Change type to upload
    },
    {
      name: 'bvn',
      label: 'BVN',
      rule: 'BVN is required',
      placeholder: 'Enter your BVN',
      isRequired: true,
      type: 'text',
    },
    {
      name: 'userPhoto',
      label: 'User Photo',
      rule: 'User photo is required',
      isRequired: true,
      type: 'upload', // Change type to upload
    },
    {
      name: 'dob',
      label: 'Date of Birth',
      rule: 'Please select a valid date',
      message: 'Please select a valid date',
      isRequired: true,
      type: 'date',
    },
  ];
};

export const paymentFormInputs = (): AuthInput[] => {
  return [
    {
      name: 'cardBrand',
      label: 'Card Brand',
      rule: 'Card brand is required',
      message: 'Please select a valid card brand',
      placeholder: 'e.g. Visa',
      isRequired: true,
      type: 'text',
    },
    {
      name: 'cardType',
      label: 'Card Type',
      rule: 'Card type is required',
      message: 'Please select a valid card type',
      placeholder: 'e.g. Visa',
      isRequired: true,
      type: 'text',
    },
    {
      name: 'reference',
      label: 'Reference',
      rule: 'Reference is required',
      message: 'Please enter a valid reference',
      placeholder: 'Enter a reference (e.g. phone number)',
      isRequired: true,
      type: 'text',
    },
    {
      name: 'amount',
      label: 'Amount',
      rule: 'Amount is required',
      message: 'Please enter a valid amount',
      placeholder: 'Enter the amount',
      isRequired: true,
      type: 'number',
    },
  ];
};

export const bvnValidateInputs = (): AuthInput[] => {
  return [
    {
      name: 'date',
      label: 'Date of Birth',
      rule: 'Date must be selected',
      isRequired: true,
      type: 'date',
    },
    {
      name: 'bvn',
      label: 'BVN',
      rule: 'Your BVN is required',
      placeholder: 'Placeholder',
      isRequired: true,
      type: 'text',
    },
  ];
};

export const validateEmailInput = (): AuthInput[] => {
  return [
    {
      name: 'email',
      label: 'Email',
      rule: 'Email is required',
      placeholder: 'Placeholder',
      isRequired: true,
      type: 'email',
    },
  ];
};

export const validateMobileInput = (): AuthInput[] => {
  return [
    {
      name: 'phone_number',
      label: 'Phone Number',
      rule: 'Phone Number is required',
      message: 'Please include a valid phone number ',
      placeholder: 'Placeholder',
      isRequired: true,
      type: 'number',
    },
  ];
};

export const registrationInputsThree = (): AuthInput[] => {
  return [
    {
      name: 'password',
      label: 'Password',
      rule: 'Please select a valid password',
      message: 'Please select a valid password',
      placeholder: '******',
      isRequired: true,
      type: 'password',
    },
    {
      name: 'confirm_password',
      label: 'Confirm Password',
      rule: 'Passwords must match',
      message: 'Please confirm your password ',
      placeholder: '******',
      isRequired: true,
      type: 'password',
    },
  ];
};

export const addressInputs = (): AuthInput[] => {
  return [
    {
      name: 'address',
      label: 'House Address',
      rule: 'House address is required',
      placeholder: 'No.2 Kilometer Way, Off Sango-Eleyele Ibadan',
      isRequired: true,
      type: 'text',
    },
    {
      name: 'postal_code',
      label: 'Postal Code',
      rule: 'Postal code is required',
      placeholder: '500811',
      isRequired: true,
      type: 'number',
    },
    {
      name: 'state',
      label: 'Region/State',
      rule: 'Region/State is required',
      placeholder: 'Oyo State',
      isRequired: true,
      type: 'text',
    },
    {
      name: 'country',
      label: 'Country',
      rule: 'Please enter the name of your country',
      placeholder: 'Nigeria',
      isRequired: true,
      type: 'text',
    },
  ];
};

export const verifyIdentityInputs = (): AuthInput[] => {
  return [
    {
      name: 'identification_numbers',
      label: 'Select an ID type',
      rule: 'Please a title',
      isRequired: true,
      type: 'select',
      data: [
        {
          id: 1,
          name: 'Passport Card',
          value: 'passport_card',
        },
        {
          id: 2,
          name: 'Passport',
          value: 'passport',
        },
        {
          id: 3,
          name: 'Passport Card',
          value: 'passport_card',
        },
        {
          id: 4,
          name: 'Residence Card',
          value: 'residence_card',
        },
        {
          id: 5,
          name: 'SSN',
          value: 'social_security_number',
        },
        {
          id: 6,
          name: 'Tax Identification Number',
          value: 'tax_identification_number',
        },
        {
          id: 7,
          name: 'Identification Card',
          value: 'identification_card',
        },
      ],
    },
    {
      name: 'identification_number',
      label: 'ID',
      rule: 'ID is required',
      placeholder: '',
      isRequired: true,
      type: 'number',
    },
    {
      name: 'street',
      label: 'Street',
      rule: 'Street is required',
      placeholder: 'Enter street here',
      isRequired: true,
      type: 'text',
    },
    {
      name: 'street2',
      label: 'Street 2',
      rule: 'Second street is required',
      placeholder: 'Enter street here',
      isRequired: true,
      type: 'text',
    },
    {
      name: 'city',
      label: 'City',
      rule: 'City is required',
      placeholder: 'Ohaio',
      isRequired: true,
      type: 'text',
    },
  ];
};
export const SecureTransactionStep = () => [
  {
    id: 1,
    value: 'Set a pin to secure your wallet from unauthorised access',
  },
  {
    id: 2,
    value:
      'Choose a transaction PIN that you can remember easily, you would need it to authorise all your transactions.',
  },
  {
    id: 3,
    value: 'Ensure to keep your PIN safe at all times.',
  },
];

export const localTransferInputs = (): AuthInput[] => {
  return [
    {
      name: 'amount',
      label: 'Amount',
      rule: 'Amount is required',
      placeholder: 'Placeholder text',
      isRequired: true,
      type: 'number',
    },
    {
      name: 'bankName',
      label: 'Bank Name',
      rule: 'Bank name is required',
      placeholder: 'Placeholder text',
      isRequired: true,
      type: 'select',
    },
    {
      name: 'accountNumber',
      label: 'Account Number',
      rule: 'Account number is required',
      placeholder: 'Placeholder text',
      isRequired: true,
      type: 'number',
    },
    {
      name: 'recipient',
      label: "Recipient's Number",
      rule: "Recipient's number is required",
      placeholder: 'Placeholder text',
      isRequired: true,
      type: 'number',
    },
    {
      name: 'narration',
      label: 'Narration',
      rule: 'Narration is required',
      placeholder: 'Placeholder text',
      isRequired: true,
      type: 'text',
    },
  ];
};

export const transferInputs = (): AuthInput[] => {
  return [
    {
      name: 'amount',
      label: 'Amount',
      rule: 'Amount is required',
      placeholder: 'Placeholder text',
      isRequired: true,
      type: 'number',
    },
    {
      name: 'narration',
      label: 'Narration',
      rule: 'Narration is required',
      placeholder: 'Placeholder text',
      isRequired: true,
      type: 'text',
    },
  ];
};

export const kycDocuments = () => [
  {
    id: 1,
    name: 'id_Front',
    label: 'ID Front',
    compulsory: true,
  },
  {
    id: 2,
    name: 'id_Back',
    label: 'ID Back',
    compulsory: true,
  },
  {
    id: 3,
    label: 'Signature',
    name: 'signature',
    compulsory: true,
  },
];

export const spaceInputs = (): AuthInput[] => {
  return [
    {
      name: 'email',
      label: 'Email',
      rule: 'Email is required',
      placeholder: 'Placeholder',
      isRequired: true,
      type: 'email',
    },
    {
      name: 'phone',
      label: 'Phone Number',
      rule: 'Phone Number is required',
      placeholder: 'Placeholder',
      isRequired: true,
      type: 'number',
    },
    {
      name: 'referralCode',
      label: 'Referral Code',
      rule: 'Please enter your referral code',
      placeholder: 'Placeholder',
      isRequired: false,
      type: 'text',
    },
  ];
};

export const transactionsTab = [
  {
    id: 1,
    title: 'All',
  },
  {
    id: 2,
    title: 'Credit',
  },
  {
    id: 3,
    title: 'Debit',
  },
];

export const settingsTab = [
  {
    id: 1,
    title: 'Profile',
    path: '/settings/profile',
  },
  {
    id: 2,
    title: 'Security',
    path: '/settings/security',
  },
  {
    id: 3,
    title: 'Notifications',
    path: '/settings/notifications',
  },
  {
    id: 6,
    title: 'Manage Device',
    path: '/settings/device',
  },
  {
    id: 7,
    title: 'Request Cards',
    path: '/settings/card',
  },
  {
    id: 7,
    title: 'Manage Cards',
    path: '/settings/manage-cards',
  },
  // {
  //   id: 8,
  //   title: 'More',
  //   path: '/settings/more',
  // },
];

export const notificationSettings = [
  {
    name: 'Activity',
    info: 'We will send you notifications about your deposits, withdrawals.',
    checked: true,
  },
  {
    name: 'Push Notification',
    checked: true,
  },
  { name: 'SMS', checked: true },
  {
    name: 'Email',
    checked: true,
  },
  {
    name: 'Logging In',
    info: 'We will send you notifications about your log in anytime we notice any.',
    checked: false,
  },
  {
    name: 'Suspicious actions',
    info: 'We will send you notifications about any suspicious action taken with your name or password.',
    checked: false,
  },
  {
    name: 'Updates',
    info: 'We will send you notifications about all important updates on the app',
    checked: true,
  },
];

export const profileInputs = (): AuthInput[] => {
  return [
    {
      name: 'first_name',
      label: 'First Name',
      rule: 'First name is required',
      minLength: 3,
      message: 'First name should be at least 3 characters ',
      placeholder: 'Placeholder',
      isRequired: true,
      type: 'text',
    },
    {
      name: 'middle_name',
      label: 'Middle Name',
      rule: 'Middle name is required',
      minLength: 3,
      message: 'Middle name should be at least 3 characters ',
      placeholder: 'Placeholder',
      isRequired: true,
      type: 'text',
    },
    {
      name: 'last_name',
      label: 'Last Name',
      rule: 'Last name is required',
      minLength: 3,
      message: 'Last name should be at least 3 characters ',
      placeholder: 'Placeholder',
      isRequired: true,
      type: 'text',
    },
    {
      name: 'email',
      label: 'Email',
      rule: 'Email is required',
      placeholder: 'Placeholder',
      isRequired: true,
      type: 'text',
    },
    {
      name: 'phone_number',
      label: 'Phone Number',
      rule: 'Last name is required',
      message: 'Please include a valid phone number ',
      placeholder: 'Placeholder',
      isRequired: true,
      type: 'number',
    },
  ];
};
