import React from 'react';
import OtpVerificationBox from 'components/otp/VerificationBox';
import { combineOtp, isOtpFilled } from 'utils/helpers';
import { Button } from 'components/common/Form';
import { useToast } from 'components/Notifications/Toast';
import { executeVerifyOtp } from 'api/settings/security';
import { useAppSelector } from 'store/hooks';

interface OTPVerificationProps {
  cta: (no: any) => void;
  isLoading?: boolean;
  resendOtp: () => Promise<void>; // assuming resendOtp is async
}

const OTPVerification: React.FC<OTPVerificationProps> = ({
  cta,
  resendOtp,
}) => {
  const [otp, setOtp] = React.useState(Array.from({ length: 6 }, () => ''));
  const email = useAppSelector((state) => state.accountStore.account?.email);
  const token = useAppSelector((state) => state.accountStore.tokenStore);
  const { showToast } = useToast();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isResending, setIsResending] = React.useState(false); // new state for resend OTP loading

  const handleVerifyOTP = async () => {
    if (!isOtpFilled(otp)) {
      showToast('Please fill in all OTP digits.', 'error');
      return;
    }
    try {
      setIsLoading(true);
      const payload = {
        email: email,
        otp: combineOtp(otp),
      };
      const result = await executeVerifyOtp(payload, token);
      if (!result.isSuccess) throw new Error(result.message);
      showToast(result.message, 'success');
      cta((prev: any) => prev + 1);
    } catch (error: any) {
      showToast(error.message, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendOtp = async () => {
    try {
      setIsResending(true);
      await resendOtp(); // Assuming resendOtp is an async function
      showToast('OTP has been resent successfully', 'success');
    } catch (error: any) {
      showToast('Failed to resend OTP, please try again.', 'error');
    } finally {
      setIsResending(false);
    }
  };

  return (
    <div
      data-aos="flip-right"
      data-aos-duration="2000"
      data-aos-delay="10"
      data-aos-offset="200"
      className="w-[400px] px-[60px] bg-white pt-[99px] pb-[90px] flex flex-col items-center"
    >
      <h2 className="text-2xl mb-6 text-[#000] font-semibold">
        OTP Verification
      </h2>
      <p className="text-sm text-[#949596]">Enter the OTP sent to</p>
      <button className="border-none bg-transparent text-[#1C1C1C] text-base">
        {email}
      </button>
      <div className="my-10 text-center">
        <OtpVerificationBox otp={otp} setOtp={setOtp} />
        <p className="text-sm text-[#949596] mt-14 mb-[24px]">
          Didn’t receive a code?
        </p>
        <button
          className="border-none bg-transparent text-[#1C1C1C] text-base"
          onClick={handleResendOtp}
          disabled={isResending} // disable button while resending
        >
          {isResending ? 'Resending...' : 'Request again'}{' '}
          {/* Show loader text */}
        </button>
        <div className="mt-10">
          <Button
            label={'Continue'}
            onClick={handleVerifyOTP}
            loading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default OTPVerification;
