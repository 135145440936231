import AuthLayout from 'layouts/Auth';
import { useForm } from 'react-hook-form';
import authBg from 'assets/form-bg-three.png';
import { Button, Input } from 'components/common/Form';
import { BiLogInCircle } from 'react-icons/bi';

const NewPassword = () => {
  const { control, watch } = useForm({
    mode: 'onSubmit',
  });

  const password = watch('password');

  const handleResetPassword = () => {
    console.log('password reset loading.....');
  };

  return (
    <AuthLayout background={true} backgroundImage={authBg}>
      <div>
        <h2 className="text-2xl font-semibold mb-[11px]">New Password</h2>
        <p className="text-sm leading-[25px] text-[#949596] mb-8">
          Input a safe password that can stand the <br /> test of fraud
        </p>
        <div className="space-y-6">
          <Input
            control={control}
            name="password"
            type="password"
            color={true}
            label="Password"
            placeholder="**********"
            rules={{
              validate: (value: string) => {
                return value !== password ? 'Wrong password' : undefined;
              },
            }}
          />
          <Input
            control={control}
            name="confirm"
            type="password"
            color={true}
            label="Confirm Password"
            placeholder="**********"
            rules={{
              validate: (value: string) => {
                return value !== password ? 'Wrong password' : undefined;
              },
            }}
          />
        </div>
        <Button
          label="Log in again"
          Icon={BiLogInCircle}
          onClick={handleResetPassword}
          extraClasses="mt-[70px]"
        />
      </div>
    </AuthLayout>
  );
};

export default NewPassword;
