import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, useWatch, Controller } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { cardRegistrationInputs } from 'utils/data';
import { formatDateForInput, getSlug } from 'utils/helpers';
import { executeRegisterVirtualCard } from 'api/kyc';
import { populateAccount } from 'store/account/accountSlice';
import { AccountData } from 'types/data';
import { Button, Input, Select } from 'components/common/Form';
import { useToast } from 'components/Notifications/Toast';
import useDisclosure from 'hooks/useDisclosure';
import KycLayout from 'layouts/Kyc';
import UploadInput from 'pages/kyc/Upload';
import authBg from 'assets/q1.png';
import { BiChevronDown } from 'react-icons/bi';

const RegisterCardUser = () => {
  const {
    trigger,
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
    control,
  } = useForm<UserCardRegistrationPayload>({
    mode: 'onSubmit',
  });

  const token = useAppSelector((state) => state.accountStore.tokenStore);
  const user = useAppSelector((state) => state.accountStore.account);
  const currency = new URLSearchParams(useLocation().search).get('currency');
  const [imageUrls, setImageUrls] = React.useState<KycUploads>();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    isOpen: isLoading,
    onOpen: openLoading,
    onClose: closeLoading,
  } = useDisclosure();
  const { showToast } = useToast();

  const handleCreateAccount = async (data: UserCardRegistrationPayload) => {
    if (!(await trigger())) {
      console.log(errors); // Check if errors are being populated
      return false;
    }
    if (!user?.id) return;

    try {
      openLoading();
      const dateOfBirth = new Date(user.dob as string);
      const options: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      };
      const formattedDate = dateOfBirth
        .toLocaleDateString('en-GB', options)
        .replace(/ /g, '-');

      const payload: UserCardRegistrationPayload = {
        customerEmail: user.email,
        idNumber: (data as any).idnumber,
        idType: (data as any).idType?.value,
        firstName: user.first_name!,
        lastName: user.last_name!,
        phoneNumber: user.phone_number!,
        city: data.city,
        state: data.state,
        country: data.country,
        zipCode: (data as any).zipcode,
        line1: data.line1,
        houseName: (data as any).housename,
        idImage: imageUrls?.idImage,
        bvn: data.bvn,
        userPhoto: imageUrls?.userPhoto,
        dateOfBirth: formattedDate,
        currency: currency!,
        idIssuanceCountry: (data as any).idissuancecountry,
        idExpiryDate: (data as any).idissuancecountry,
        idIssuanceDate: (data as any).idissuancedate,
      };

      const result = await executeRegisterVirtualCard(payload, user.id, token);
      dispatch(populateAccount(result?.data?.customer_id));
      if (!result.isSuccess) throw new Error(result.message);
      showToast(result.message, 'success');
      navigate('/create-virtual-card?currency=USD');
    } catch (error: any) {
      showToast(error.message, 'error');
    } finally {
      closeLoading();
    }
  };

  const idImageFile = useWatch({ control, name: 'idImage' });
  const userPhotoFile = useWatch({ control, name: 'userPhoto' });

  return (
    <KycLayout background={true} backgroundImage={authBg}>
      <h2 className="text-2xl font-semibold mb-[35px]">Register Card User</h2>
      <form className="space-y-6" onSubmit={handleSubmit(handleCreateAccount)}>
        {cardRegistrationInputs().map((input, idx) => {
          const watchedFile =
            input.name === 'idImage' ? idImageFile : userPhotoFile;

          return input.type !== 'upload' ? (
            input.type === 'select' ? (
              <div className="flex w-full flex-col gap-3 mb-4" key={idx}>
                <p className="text-black text-sm">{input.label}</p>
                <Controller
                  name={input.name as keyof UserCardRegistrationPayload} // Ensure this matches the correct payload type
                  control={control}
                  render={({ field }) => (
                    <Select
                      selected={
                        field.value as unknown as { id: number; name: string }
                      }
                      setSelected={(value) => field.onChange(value)}
                      datas={input.data as any}
                      required={input.isRequired}
                    >
                      <div className="w-full flex-btn text-textGray text-sm p-4 border border-border font-light rounded-lg focus:border focus:border-subMain">
                        {(
                          field.value as unknown as { id: number; name: string }
                        )?.name || ''}
                        <BiChevronDown className="text-xl" />
                      </div>
                    </Select>
                  )}
                />
                {errors[input.name as keyof UserCardRegistrationPayload] && (
                  <p className="text-red-500 text-xs mt-1">
                    {
                      errors[input.name as keyof UserCardRegistrationPayload]
                        ?.message as string
                    }
                  </p>
                )}
              </div>
            ) : (
              <Input
                key={idx}
                name={getSlug(input.name)}
                placeholder={input.placeholder}
                color={true}
                label={input.label}
                type={input.type}
                register={register}
                required={input.isRequired}
                errors={errors}
                defaultValue={
                  input.name === 'dob' &&
                  user?.[input.name as keyof AccountData]
                    ? formatDateForInput(
                        String(user[input.name as keyof AccountData]),
                      )
                    : String(user?.[input.name as keyof AccountData] ?? '')
                }
              />
            )
          ) : (
            <UploadInput
              key={idx}
              label={input.label}
              setError={setError as any}
              setValue={setValue as any}
              error={
                errors[getSlug(input.name) as keyof UserCardRegistrationPayload]
                  ?.message
              }
              register={register}
              value={watchedFile as any} // Correct watchedFile based on the current input
              name={input.name} // Use the correct field name here
              setImageUrls={setImageUrls}
              accept=".jpg, .png"
            />
          );
        })}

        <Button
          label="Continue"
          loading={isLoading}
          extraClasses="mt-[196px] mb-[24px]"
        />
      </form>
    </KycLayout>
  );
};

export default RegisterCardUser;
