// import { log } from 'utils/helpers';
import {
  CREATE_ACCOUNT_ENDPOINT,
  LOGIN_ENDPOINT,
  RESET_PASSWORD_ENDPOINT,
  VALIDATE_BVN,
  VALIDATE_EMAIL,
  VERIFY_EMAIL_PHONE,
} from './index';
import { default as axios } from 'config/axios';

export const executeLogin = async (
  data: LoginData,
): Promise<ResponseDataType> => {
  try {
    const response = await axios().patch(LOGIN_ENDPOINT, data);
    return response.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: error };
  }
};

export const executeValidateEmail = async (
  data: VerificationType,
): Promise<ResponseDataType> => {
  try {
    const response = await axios().post(VALIDATE_EMAIL, data);
    return response.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false } as ResponseDataType;
  }
};

export const executeValidateBvn = async (
  token: string,
  id: string,
  data: BvnValidation,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).post(VALIDATE_BVN(id), data);
    return response.data;
  } catch (error: any) {
    console.log({ error });
    return { message: error.message, isSuccess: false } as ResponseDataType;
  }
};

export const executeVerifyEmail = async (
  data: VerificationType,
): Promise<ResponseDataType> => {
  try {
    const response = await axios().post(VERIFY_EMAIL_PHONE, data);
    return response.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false } as ResponseDataType;
  }
};

export const executeValidateMobile = async (
  data: VerificationType,
): Promise<ResponseDataType> => {
  try {
    const response = await axios().post(VALIDATE_EMAIL, data);
    return response.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false } as ResponseDataType;
  }
};

export const executeVerifyPhone = async (
  data: VerificationType,
): Promise<ResponseDataType> => {
  try {
    const response = await axios().post(VERIFY_EMAIL_PHONE, data);
    return response.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false } as ResponseDataType;
  }
};

export const executeSignUp = async (
  data: RegistrationData,
): Promise<ResponseDataType> => {
  try {
    const response = await axios().post(CREATE_ACCOUNT_ENDPOINT, data);
    return response.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false } as ResponseDataType;
  }
};

export const executeResetPassword = async (
  data: SecuritySettingsData,
): Promise<ResponseDataType> => {
  try {
    const response = await axios().post(RESET_PASSWORD_ENDPOINT, data);
    return response.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: 'error' };
  }
};
