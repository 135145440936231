import { createContext, ReactNode, useContext, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import {
  populateCountries,
  populateSelectedCountry,
} from '../store/country/countrySlice';
import { executeGetCountries } from 'api/countries';
import {
  executeGetProfile,
  executeGetVirtualCards,
  executeGetWallets,
} from 'api/wallet';
import {
  populateAccount,
  populateCards,
  populateNotifications,
  populateWallets,
} from 'store/account/accountSlice';
import { useToast } from 'components/Notifications/Toast';
import useDisclosure from 'hooks/useDisclosure';
import { executeGetNotificaitons } from 'api/user';

interface AppContextProps {
  handleGetCountries: (token: string) => Promise<void>;
  isLoadingData: boolean;
}
const AppContext = createContext({} as AppContextProps);

interface AppContextProviderProps {
  children: ReactNode;
}
const AppContextProvider = ({ children }: AppContextProviderProps) => {
  const dispatch = useAppDispatch();
  const { showToast } = useToast();
  const tokenStore = useAppSelector((state) => state.accountStore.tokenStore);
  const account = useAppSelector((state) => state.accountStore);

  console.log({ account });

  const selectedCountry = useAppSelector(
    (state) => state.countryStore.selectedCountry,
  );
  const accountId = useAppSelector((state) => state.accountStore.account?.id);

  const {
    isOpen: isLoadingData,
    onOpen: openLoadingData,
    onClose: closeLoadingData,
  } = useDisclosure();

  const handleGetCountries = async () => {
    try {
      openLoadingData();
      const result = await executeGetCountries();
      if (!result.isSuccess)
        throw new Error('Failed to get countries, Try again');
      dispatch(populateCountries(result.data));
      if (selectedCountry === null) {
        dispatch(populateSelectedCountry(result.data[1])); // Set default country (e.g., United States)
      }
    } catch (err: any) {
      const isNetwork = err.message.toLowerCase().includes('network');
      showToast(
        isNetwork
          ? "Can't connect to internet, check your network settings"
          : err.message,
        isNetwork ? 'warning' : 'error',
      );
    } finally {
      closeLoadingData();
    }
  };

  const handleGetProfile = async () => {
    if (!tokenStore || !accountId || !account.isAuthenticated) return;
    try {
      openLoadingData();
      const result = await executeGetProfile(tokenStore);
      if (!result.isSuccess)
        throw new Error('Failed to get account information, Try again');
      dispatch(populateAccount(result.data));
    } catch (err: any) {
      const isNetwork = err.message.toLowerCase().includes('network');
      showToast(
        isNetwork
          ? "Can't connect to internet, check your network settings"
          : err.message,
        isNetwork ? 'warning' : 'error',
      );
    } finally {
      closeLoadingData();
    }
  };

  const handleGetWallets = async () => {
    if (!tokenStore || !accountId || !account.isAuthenticated) return;
    try {
      openLoadingData();
      const result = await executeGetWallets(accountId, tokenStore);

      if (!result.isSuccess)
        throw new Error('Failed to get wallets , Try again');
      dispatch(populateWallets(result.data));
    } catch (err: any) {
      const isNetwork = err.message.toLowerCase().includes('network');
      showToast(
        isNetwork
          ? "Can't connect to internet, check your network settings"
          : err.message,
        isNetwork ? 'warning' : 'error',
      );
    } finally {
      closeLoadingData();
    }
  };

  const handleGetVirtualCards = async () => {
    if (!tokenStore || !accountId || !account.isAuthenticated) return;
    try {
      openLoadingData();
      const result = await executeGetVirtualCards(accountId, tokenStore);
      if (!result.isSuccess)
        throw new Error('Failed to get virtual cards , Try again');
      dispatch(populateCards(result.data));
    } catch (err: any) {
      const isNetwork = err.message.toLowerCase().includes('network');
      showToast(
        isNetwork
          ? "Can't connect to internet, check your network settings"
          : err.message,
        isNetwork ? 'warning' : 'error',
      );
    } finally {
      closeLoadingData();
    }
  };

  const handleGetNotifications = async () => {
    if (!tokenStore || !accountId || !account.isAuthenticated) return;
    try {
      openLoadingData();
      const result = await executeGetNotificaitons(tokenStore);
      if (!result.isSuccess)
        throw new Error('Failed to get notifications , Try again');
      dispatch(populateNotifications(result.data));
    } catch (err: any) {
      const isNetwork = err.message.toLowerCase().includes('network');
      showToast(
        isNetwork
          ? "Can't connect to internet, check your network settings"
          : err.message,
        isNetwork ? 'warning' : 'error',
      );
    } finally {
      closeLoadingData();
    }
  };

  useEffect(() => {
    handleGetCountries();
    handleGetWallets();
    handleGetVirtualCards();
    handleGetProfile();
    handleGetNotifications();
  }, [tokenStore]);

  return (
    <AppContext.Provider
      value={{
        handleGetCountries,
        isLoadingData,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
export const useAppContext = () => useContext(AppContext);
