import React from 'react';
import AuthLayout from 'layouts/Auth';
import { Link as ReactLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import authBg from 'assets/form-bg-one.png';
import { Button, Input } from 'components/common/Form';
import { BiLogInCircle } from 'react-icons/bi';
import { FaArrowRight } from 'react-icons/fa';

interface ResetPasswordProps {
  setActiveStep: (no: any) => void;
}

const ForgotPassword: React.FC<ResetPasswordProps> = ({ setActiveStep }) => {
  const { control, register } = useForm({
    mode: 'onSubmit',
  });
  const handleReset = () => {
    setActiveStep((prev: number) => prev + 1);
  };

  return (
    <AuthLayout background={true} backgroundImage={authBg}>
      <div className="h-full">
        <h2 className="text-2xl font-semibold">Forgot Password?</h2>
        <p className="text-sm text-gray-600 mb-12 mt-6">
          Please enter the email or mobile number you used to register with
          World Hero Wallet to request a password reset.
        </p>
        <Input
          control={control}
          name="email"
          type="email"
          label="Email"
          placeholder="Placeholder"
          color={true}
          register={register}
        />
        <Button
          label=" Send reset link"
          Icon={BiLogInCircle}
          onClick={handleReset}
          extraClasses="mt-[149px] mb-[157px]"
        />

        <p className="text-sm text-[#949596] text-center">
          If you dont have an account
          <ReactLink to={'/register'} className="inline-flex items-center ml-2">
            Sign up
            <span className="ml-1">
              <FaArrowRight size={12} />
            </span>
          </ReactLink>
        </p>
      </div>
    </AuthLayout>
  );
};

export default ForgotPassword;
