import { createSlice } from '@reduxjs/toolkit';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import { CountryData } from 'types/data';

type InitialStateType = {
  selectedCountry: CountryData | null;
  countries: CountryData[];
};
const initialState: InitialStateType = {
  selectedCountry: null,
  countries: [],
};

const countrySlice = createSlice({
  name: 'country',
  initialState,
  reducers: {
    populateCountries: (state, action) => {
      state.countries = action.payload;
    },
    populateSelectedCountry: (state, action) => {
      state.selectedCountry = action.payload;
    },
  },
});

const countryPersistConfig = {
  key: 'country',
  storage: storage,
};

export const { populateCountries, populateSelectedCountry } =
  countrySlice.actions;

const countryReducer = persistReducer(
  countryPersistConfig,
  countrySlice.reducer,
);

export default countryReducer;
