import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import useDisclosure from 'hooks/useDisclosure';
import { useAppSelector } from 'store/hooks';
import { executeUpdateKyc } from 'api/kyc';
import authBg from 'assets/q1.png';
import KycLayout from 'layouts/Kyc';
import { kycDocuments } from 'utils/data';
import { getSlug, labelValueMap } from 'utils/helpers';
import { Button, Select } from 'components/common/Form';
import UploadInput from './Upload';
import { BiChevronDown } from 'react-icons/bi';
import { useToast } from 'components/Notifications/Toast';

const Documents = () => {
  const [imageUrls, setImageUrls] = React.useState<KycUploads>();
  const {
    onOpen: openLoading,
    onClose: closeLoading,
    isOpen: isLoading,
  } = useDisclosure();
  const token = useAppSelector((state) => state.accountStore.tokenStore);

  const {
    register,
    watch,
    formState: { errors },
    setError,
    setValue,
    getValues,
    control,
    trigger,
  } = useForm({
    mode: 'onSubmit',
  });

  const { showToast } = useToast();
  const navigate = useNavigate();

  const handleUpload = async () => {
    if (!(await trigger())) return false;
    try {
      openLoading();
      const payload = {
        ...imageUrls,
        id_type: getValues()?.type?.value,
      };
      const result = await executeUpdateKyc(payload, token);
      if (!result.isSuccess) throw new Error(result.message);
      showToast(result.message, 'success');
      navigate('/dashboard');
    } catch (error: any) {
      showToast(error.message, 'error');
    } finally {
      closeLoading();
    }
  };

  const types = [
    { name: 'International Passport', id: 'international_passport' },
    { name: 'National Identity Card', id: 'national_identity_card' },
    { name: 'Drivers License', id: 'drivers_license' },
    { name: 'Voters Card', id: 'voters_card' },
  ];

  return (
    <KycLayout background={true} backgroundImage={authBg}>
      <div className="px-6">
        <div className="mb-6 text-center">
          <h1 className="text-[24px] mb-3 font-semibold">Documents</h1>
          <p className="text-sm text-black">
            Scan/Upload the following
            <br /> Documents
          </p>
        </div>
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12">
            <Controller
              name={'type'}
              control={control}
              render={({ field }) => (
                <Select
                  selected={field.value as { id: number; name: string }}
                  setSelected={(value) => field.onChange(value)}
                  datas={labelValueMap(types)}
                  label="ID Type"
                >
                  <div className="w-full flex-btn text-textGray text-sm p-4 border border-border font-light rounded-lg focus:border focus:border-subMain bg-white">
                    {(field.value as { id: number; name: string })?.name || ''}
                    <BiChevronDown className="text-xl" />
                  </div>
                </Select>
              )}
            />
          </div>
          {kycDocuments().map((doc) => (
            <div key={doc.name} className="col-span-12">
              <UploadInput
                label={doc.name}
                setError={setError as any}
                setValue={setValue as any}
                error={errors[getSlug(doc.name) as any]?.message as any}
                register={register}
                value={watch(getSlug(doc.name))}
                name={getSlug(doc.name)}
                setImageUrls={setImageUrls}
                accept=".jpg, .png"
              />
            </div>
          ))}
        </div>
        <div className="mt-32 mb-4">
          <Button label="Continue" onClick={handleUpload} loading={isLoading} />

          <Button
            label="Do This Later"
            onClick={() => console.log('test')}
            loading={false}
            outline={true}
            extraClasses="border border-primary"
          />
        </div>
      </div>
    </KycLayout>
  );
};

export default Documents;
