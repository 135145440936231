/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import { AccountData, Cards, WalletData } from 'types/data';

type InitialStateType = {
  account: AccountData | null;
  tokenStore: any;
  isAuthenticated: boolean;
  wallets: WalletData[] | any;
  selectedWallet: WalletData | null;
  selectedCard: WalletData | null;
  cards: Cards | any;
  notifications: any[];
};
const initialState: InitialStateType = {
  account: null,
  tokenStore: null,
  isAuthenticated: false,
  wallets: [],
  selectedWallet: null,
  cards: null,
  selectedCard: null,
  notifications: [],
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    clearAccount: (state) => {
      state.tokenStore = null;
      state.account = null;
      state.isAuthenticated = false;
      state.wallets = null;
      localStorage.clear();
    },
    populateAccount: (state, action) => {
      state.account = { ...state.account, ...action.payload };
      state.isAuthenticated = true;
    },
    populateWallets: (state, action) => {
      state.wallets = { ...state.wallets, ...action.payload };
    },
    populateCards: (state, action) => {
      state.cards = { ...state.cards, ...action.payload };
    },
    populateSelectedWallet: (state, action) => {
      state.selectedWallet = action.payload;
    },
    populateSelectedCard: (state, action) => {
      state.selectedCard = action.payload;
    },
    populateToken: (state, action) => {
      state.tokenStore = action.payload;
    },
    populateNotifications: (state, action) => {
      state.notifications = action.payload;
    },
  },
});

export const {
  populateAccount,
  populateToken,
  clearAccount,
  populateWallets,
  populateSelectedWallet,
  populateCards,
  populateSelectedCard,
  populateNotifications,
} = accountSlice.actions;

const accountPersistConfig = {
  key: 'account',
  storage: storage,
};

const accountReducer = persistReducer(
  accountPersistConfig,
  accountSlice.reducer,
);

export default accountReducer;
