import { Dialog } from '@headlessui/react';
import moneyCard from 'assets/moneycard.png';
import useDisclosure from 'hooks/useDisclosure';

interface SelectCardProps {
  setActiveStep: (no: any) => void;
}

const SelectCard: React.FC<SelectCardProps> = ({ setActiveStep }) => {
  const { isOpen, onClose } = useDisclosure();

  return (
    <div className="flex flex-col items-center text-center">
      <div className="w-[374px] pt-6 pb-11 px-6 shadow-lg">
        <Dialog open={isOpen} onClose={onClose}>
          <Dialog.Title className="text-lg font-semibold">
            Virtual Cards
          </Dialog.Title>
          <div className="my-6">
            <img src={moneyCard} alt="3d money card" className="mx-auto" />
            dddd
          </div>
          <button
            className="mt-[160px] py-4 bg-blue-500 text-white rounded-md w-full"
            onClick={() => setActiveStep((prev: number) => prev + 1)}
          >
            Send Request
          </button>
        </Dialog>
      </div>
    </div>
  );
};

export default SelectCard;
