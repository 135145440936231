import React from 'react';
import OTPVerification from './components/OTPVerification';
import PasswordForm from './components/PasswordForm';
import { useForm } from 'react-hook-form';
import useRequestOTP from 'hooks/useRequestOTP';
import { executeChangePassword } from 'api/settings/security';
import { useToast } from 'components/Notifications/Toast';
import { useAppSelector } from 'store/hooks';

const ChangePassword = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const { register, watch, getValues, trigger } =
    useForm<SecuritySettingsData>();
  const { handleRequest, loadingData } = useRequestOTP();
  const { showToast } = useToast();
  const token = useAppSelector((state) => state.accountStore.tokenStore);

  const handleChangePassword = async () => {
    try {
      if (!(await trigger())) return false;
      const payload: SecuritySettingsData = {
        current_password: getValues('current_password'),
        new_password: getValues('new_password'),
      };
      const result = await executeChangePassword(payload, token);
      if (!result.isSuccess) throw new Error(result.message);
      showToast(result.message, 'success');
    } catch (error: any) {
      showToast(error.message, 'error');
    }
  };

  React.useEffect(() => {
    handleRequest();
  }, []);

  const steps = [
    {
      name: 'OTP Verification',
      component: (
        <OTPVerification
          cta={setActiveStep}
          isLoading={loadingData}
          resendOtp={handleRequest}
        />
      ),
    },
    {
      name: 'New Pin',
      component: (
        <PasswordForm
          register={register}
          watch={watch}
          handleChangePassword={handleChangePassword}
        />
      ),
    },
  ];

  return <>{steps[activeStep].component}</>;
};

export default ChangePassword;
