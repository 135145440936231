/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import ForgotPassword from './ForgotPassword';
import VerifyEmail from './VerifyEmail';
import NewPassword from './NewPassword';

const FacilityForm = () => {
  const [email, setEmail] = useState<string>('');
  const [otp, setOtp] = useState<number | null>(null);

  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    {
      name: 'Validate Email',
      component: (
        <ForgotPassword setActiveStep={setActiveStep} setEmail={setEmail} />
      ),
    },
    {
      name: 'Verify Email',
      component: (
        <VerifyEmail
          setActiveStep={setActiveStep}
          email={email}
          setVerificationOtp={setOtp}
        />
      ),
    },
    {
      name: 'Verify Email',
      component: <NewPassword email={email} otp={otp} />,
    },
  ];
  return <> {steps[activeStep].component}</>;
};

export default FacilityForm;
