import { log } from 'utils/helpers';
import { GET_PROFILE, GET_USER_DEVICES, GET_USER_NOTIFICATIONS } from './index';
import { AccountData } from 'types/data';
import { default as axios } from 'config/axios';

export const executeUpdateProfile = async (
  data: AccountData,
  token: string,
): Promise<ResponseDataType> => {
  try {
    const options = {
      body: JSON.stringify(data),
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        whspay: '&sw22mewjsd4t343643?',
        whcookie: `whspay=${token}`,
      },
    };
    const request = await fetch(GET_PROFILE, options);
    const response = (await request.json()) satisfies ResponseDataType;
    return response;
  } catch (error: any) {
    log('LOGIN [ERROR]:', error.message);
    return { message: error.message, isSuccess: false } as ResponseDataType;
  }
};

export const executeGetCurrentDevices = async (
  token: string,
  userId: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).get(GET_USER_DEVICES(userId));
    return response.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false } as ResponseDataType;
  }
};

export const executeGetNotificaitons = async (
  token: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).get(GET_USER_NOTIFICATIONS());
    return response.data;
  } catch (error: any) {
    log('NOTIFICATION [ERROR]:', error.message);
    return { message: error.message, isSuccess: false } as ResponseDataType;
  }
};
