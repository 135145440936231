import { BsThreeDotsVertical } from 'react-icons/bs';
import { CreditIcon, DebitIcon, ReceivedIcon } from '../icons';
import { Transaction as TransactionDataType } from 'types/data';

interface Props {
  item: TransactionDataType;
}
const Transaction: React.FC<Props> = ({ item }) => {
  return (
    <div className="max-w-md flex flex-col sm:flex-row shadow-none border-t border-[#E8E8E8] rounded-none bg-greyed p-4">
      <div className="basis-[75%] flex items-center">
        <div className="flex items-center">
          {/* <item.icon /> */}
          {item.type === 'debit' ? (
            <DebitIcon />
          ) : item.type === 'credit' ? (
            <CreditIcon />
          ) : (
            <ReceivedIcon />
          )}
          <div className="ml-4 flex flex-col items-start">
            <p className="text-sm text-black mb-1">{item?.status}</p>
            <p className="text-xs font-medium text-neutral-600">{item?.type}</p>
          </div>
        </div>
      </div>
      <div className="basis-[25%] flex justify-between items-center mt-4 sm:mt-0">
        <div className="flex flex-col items-start">
          <p
            className={`text-xs font-medium mb-1 ${
              item?.type?.toLowerCase() === 'debit'
                ? 'text-red-500'
                : 'text-green-600'
            }`}
          >
            {item?.type.toLowerCase() === 'debit' ? '-' : '+'}N{item?.amount}
          </p>
          <p className="text-xs font-medium text-neutral-600">
            {item?.created_at}
          </p>
        </div>
        <button className="text-gray-400 hover:text-gray-600">
          <BsThreeDotsVertical />
        </button>
      </div>
    </div>
  );
};

export default Transaction;
