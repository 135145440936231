import AuthLayout from 'layouts/Auth';
import { useNavigate } from 'react-router-dom';
import authBg from 'assets/secure-successful.png';
import lock from 'assets/padlock.png';
import { Button } from 'components/common/Form';
import { useAppDispatch } from 'store/hooks';
import { clearAccount } from 'store/account/accountSlice';
import { clearLevelState } from 'store/registration/registerSlice';

const SecureAccountSuccessful = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleAction = () => {
    dispatch(clearAccount());
    dispatch(clearLevelState());
    navigate('/');
  };

  return (
    <AuthLayout background={true} backgroundImage={authBg}>
      <img src={lock} className="mx-auto" alt="Padlock" />
      <div className="space-y-4 px-6">
        <h2 className="text-2xl font-semibold text-center">
          Transaction PIN created Successfully
        </h2>
        <p className="text-sm text-center text-gray-500">
          Your Transaction PIN has been successfully created. Activate your
          wallet now to start sending funds.
        </p>
        <Button
          label="Proceed to Login"
          onClick={handleAction}
          loading={false}
        />

        <Button
          label="Do This Later"
          onClick={handleAction}
          loading={false}
          outline={true}
          extraClasses="border border-primary"
        />
      </div>
    </AuthLayout>
  );
};

export default SecureAccountSuccessful;
