import React from 'react';
import ReactFlagsSelect from 'react-flags-select';
import { ArrowSwap } from 'components/icons';
import { executeGetAllRates } from 'api/wallet';
import { useAppSelector } from 'store/hooks';
import { useToast } from 'components/Notifications/Toast';

const ConversionCard = () => {
  const [sendCurrency, setSendCurrency] = React.useState('GBP'); // Default send currency
  const [receiveCurrency, setReceiveCurrency] = React.useState('USD'); // Default receive currency
  const [rates, setRates] = React.useState<any>({}); // Use an object to hold the rates
  const token = useAppSelector((state) => state.accountStore.tokenStore);
  const { showToast } = useToast();

  const getAllRates = async () => {
    try {
      const response = await executeGetAllRates(token);
      setRates(response.data);
    } catch (error: any) {
      showToast(error.message, 'error');
    }
  };

  React.useEffect(() => {
    getAllRates();
  }, []);

  const handleSendCurrencyChange = (code: string) => {
    const selectedCurrency =
      code === 'US' ? 'USD' : code === 'GB' ? 'GBP' : 'NGN';
    setSendCurrency(selectedCurrency);
  };

  const handleReceiveCurrencyChange = (code: string) => {
    const selectedCurrency =
      code === 'US' ? 'USD' : code === 'GB' ? 'GBP' : 'NGN';
    setReceiveCurrency(selectedCurrency);
  };

  const getConversionRate = () => {
    if (rates && rates[sendCurrency] && rates[receiveCurrency]) {
      return rates[sendCurrency]?.sellRate;
    }
    return null;
  };

  const conversionRate = getConversionRate();

  return (
    <div className="mx-auto w-full h-full border border-neutral-grey rounded-lg p-6 bg-greyed">
      <div>
        <p className="text-xs">Send</p>
        <div className="flex items-center justify-between border border-neutral-grey rounded-md px-4 py-2 bg-white">
          <p className="text-gray-500">{sendCurrency}</p>
          <ReactFlagsSelect
            countries={['US', 'GB', 'NG']}
            selected={
              sendCurrency === 'USD'
                ? 'US'
                : sendCurrency === 'GBP'
                  ? 'GB'
                  : 'NG'
            }
            onSelect={handleSendCurrencyChange}
            selectedSize={16}
            showSelectedLabel={false}
            showOptionLabel={false}
            className="menu-flags"
          />
        </div>
      </div>
      <div className="flex justify-center mt-4">
        <ArrowSwap />
      </div>
      <div>
        <p className="text-xs">Receive</p>
        <div className="flex items-center justify-between border border-neutral-grey rounded-md px-4 py-2 bg-white">
          <p className="text-gray-500">{receiveCurrency}</p>
          <ReactFlagsSelect
            countries={['US', 'GB', 'NG']}
            selected={
              receiveCurrency === 'USD'
                ? 'US'
                : receiveCurrency === 'GBP'
                  ? 'GB'
                  : 'NG'
            }
            onSelect={handleReceiveCurrencyChange}
            selectedSize={16}
            showSelectedLabel={false}
            showOptionLabel={false}
            className="menu-flags"
          />
        </div>
      </div>
      <div className="flex justify-center my-8">
        {conversionRate !== null ? (
          <p>
            {rates[sendCurrency]?.buyRate || 0} {sendCurrency} ={' '}
            {rates[receiveCurrency]?.buyRate || 0} {receiveCurrency}
          </p>
        ) : (
          <p>Loading conversion rate...</p>
        )}
      </div>
      {/* <button
        className="w-full py-6 border border-gray-300 rounded-md flex items-center justify-center text-gray-800"
        // Add onClick logic as needed
      >
        <ConversionIcon className="mr-2" />
        Convert
      </button> */}
    </div>
  );
};

export default ConversionCard;
