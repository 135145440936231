import { Button, Input } from 'components/common/Form';
import authBg from 'assets/q1.png';
import { useForm } from 'react-hook-form';
import { ssnRegistrationInputs } from 'utils/data';
import KycLayout from 'layouts/Kyc';
import useDisclosure from 'hooks/useDisclosure';
import { useToast } from 'components/Notifications/Toast';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatDateForInput } from 'utils/helpers';
import { executeCreateCybridAccount } from 'api/kyc';
import { populateSelectedWallet } from 'store/account/accountSlice';
import { AccountData } from 'types/data';

const SSNVerification = () => {
  const {
    trigger,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CybridAccountPayload>({
    mode: 'onSubmit',
  });
  const token = useAppSelector((state) => state.accountStore.tokenStore);
  const user = useAppSelector((state) => state.accountStore.account);
  const currency = new URLSearchParams(useLocation().search).get('currency');
  const dispatch = useAppDispatch();
  const {
    isOpen: isLoading,
    onOpen: openLoading,
    onClose: closeLoading,
  } = useDisclosure();

  const { showToast } = useToast();
  const navigate = useNavigate();

  const handleCreateAccount = async (data: CybridAccountPayload) => {
    if (!(await trigger())) return false;
    if (!user?.id) return;
    try {
      openLoading();
      const dateOfBirth = new Date(data.dob as string);
      const options: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      };
      const formattedDate = dateOfBirth
        .toLocaleDateString('en-GB', options)
        .replace(/ /g, '-');

      const payload: CybridAccountPayload = {
        date_of_birth: formattedDate,
        email_address: data.email,
        name: {
          first: data.first_name,
          last: data.last_name,
          middle: data.middle_name,
        },
        currency,
        type: 'individual',
      };

      const result = await executeCreateCybridAccount(payload, user.id, token);
      dispatch(populateSelectedWallet(result.data));
      if (!result.isSuccess) throw new Error(result.message);
      showToast(result.message, 'success');
      navigate('/verify-identity');
    } catch (error: any) {
      showToast(error.message, 'error');
    } finally {
      closeLoading();
    }
  };

  return (
    <KycLayout background={true} backgroundImage={authBg}>
      <h2 className="text-2xl font-semibold mb-[35px]">Create Account</h2>
      <form className="space-y-6" onSubmit={handleSubmit(handleCreateAccount)}>
        {ssnRegistrationInputs().map((input, idx) => (
          <>
            <Input
              key={idx}
              name={input.name}
              placeholder={input.placeholder}
              color={true}
              label={input.label}
              type={input.type}
              register={register}
              required={input.isRequired}
              defaultValue={
                input.name === 'dob' && user?.[input.name as keyof AccountData]
                  ? formatDateForInput(
                      String(user[input.name as keyof AccountData]),
                    )
                  : String(user?.[input.name as keyof AccountData] ?? '')
              }
            />
            {errors[input.name as keyof CybridAccountPayload] && (
              <p className="text-red-500 text-xs mt-1">
                {errors[
                  input.name as keyof CybridAccountPayload
                ]?.message?.toString()}
              </p>
            )}
          </>
        ))}
        <Button
          label="Continue"
          loading={isLoading}
          extraClasses="mt-[196px] mb-[24px]"
        />
      </form>
    </KycLayout>
  );
};

export default SSNVerification;
