import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Input, Select, Button } from 'components/common/Form';
import { BiChevronDown } from 'react-icons/bi';
import useDisclosure from 'hooks/useDisclosure';
import { useToast } from 'components/Notifications/Toast';
import { executeCreateCounterParty, executeGetSubdivisions } from 'api/bank';
import { useAppSelector } from 'store/hooks';
import { cynbridCounterPartyInputs, transferInputs } from 'utils/data';
import { WalletData } from 'types/data';
import CounterPartyVerificationModal from './CounterPartyVerificationModal';

interface InputField {
  name: string;
  placeholder?: string;
  label?: string;
  type?: string;
  required?: boolean;
  enum?: string[];
  banks?: any[];
  const?: string;
}

interface Subdivision {
  id: string;
  name: string;
  code: string;
}

interface Props {
  paymentMethod: string | null;
  data: any;
  setPaymentData: Dispatch<SetStateAction<any>>;
  handleNextStep: () => void;
  selectedWallet: WalletData;
  paymentData: any;
  selectedCounterParty: string;
  selectedCountry: {
    value: {
      code: string;
      paymentMethods: string[];
      currencies: string[];
      name: string;
    };
  } | null;
}

const Recipient: React.FC<Props> = ({
  paymentMethod,
  data,
  selectedCountry,
  handleNextStep,
  setPaymentData,
  selectedWallet,
  paymentData,
  selectedCounterParty,
}) => {
  const {
    register,
    formState: { errors, isValid, isDirty },
    setValue,
    getValues,
    control,
    trigger,
  } = useForm({
    mode: 'onChange', // Changed to onChange for real-time validation
  });

  const getInputs = (): any => {
    if (!selectedCountry) return [];
    const transferInputs = paymentMethod
      ? Object.values(data?.destination?.[paymentMethod])
      : Object.values(data?.destination);

    return transferInputs[0];
  };

  const { inputs, constFields } = useMemo(() => {
    const allInputs = getInputs();
    const constFields: Record<string, string> = {};
    const filteredInputs = allInputs.filter((input: InputField) => {
      if (input.const !== undefined) {
        constFields[input.name] = input.const;
        return false;
      }
      return true;
    });
    return { inputs: filteredInputs, constFields };
  }, [selectedCountry, paymentMethod, data]);

  const token = useAppSelector((state) => state.accountStore.tokenStore);
  const userId = useAppSelector((state) => state.accountStore.account?.id);
  const [subdivisions, setSubdivisions] = useState<Subdivision[]>([]);
  const [isLoadingSubdivisions, setIsLoadingSubdivisions] = useState(false);

  const [isVerificationModalOpen, setIsVerificationModalOpen] =
    React.useState(false);

  const customerEmail = useAppSelector(
    (state) => state.accountStore.account?.email,
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { showToast } = useToast();

  useEffect(() => {
    // Set const fields as default values and trigger validation
    Object.entries(constFields).forEach(([key, value]) => {
      setValue(key, value);
    });
    trigger(); // Trigger validation after setting const fields
  }, [constFields, setValue, trigger]);

  console.log({ selectedWallet });

  const onSubmit = async () => {
    // Validate all fields before proceeding
    const isFormValid = await trigger();
    if (!isFormValid) {
      showToast('Please fill all required fields', 'error');
      return;
    }

    onOpen();
    try {
      const {
        amount,
        narration,
        street,
        subdivision,
        country_code,
        postal_code,
        city,
        ...formData
      } = getValues();
      void amount;
      void narration;

      const networkField = inputs.find((input: any) => input.enum);
      if (networkField) {
        formData[networkField.name] = formData[networkField.name]?.id || null;
      }

      const destination = {
        ...formData,
        ...constFields,
        ...(formData.bankCode && {
          bankCode: formData.bankCode.id,
        }),
      };
      let payload;
      if (selectedWallet.currency === 'NGN') {
        payload = {
          currency: selectedCountry?.value?.currencies[0],
          country: selectedCountry?.value?.code,
          customerEmail,
          destination,
        };
      } else if (selectedWallet.currency === 'USD' && !selectedCounterParty) {
        payload = {
          brailsCounterParty: {
            currency: selectedCountry?.value?.currencies[0],
            country: selectedCountry?.value?.code,
            customerEmail,
            destination,
          },
          cybridCounterParty: {
            first_name: formData.first_name,
            last_name: formData.last_name,
            address: {
              street,
              city,
              postal_code,
              subdivision: subdivision.code,
              country_code,
            },
            customer_guid: selectedWallet.customer_id,
          },
        };
      } else {
        payload = {
          brailsCounterParty: {
            currency: selectedCountry?.value?.currencies[0],
            country: selectedCountry?.value?.code,
            customerEmail,
            destination,
          },
        };
      }

      // If there's no selected counter party, create a new one
      if (!selectedCounterParty) {
        const res = await executeCreateCounterParty(payload, userId!, token);

        if (res.isSuccess) {
          showToast(res.message, 'success');
          setPaymentData({
            ...getValues(),
            counterId: res.data.id,
          });

          // Only open verification modal if it's a USD wallet and creation was successful
          if (selectedWallet.currency === 'USD') {
            setIsVerificationModalOpen(true);
          } else {
            handleNextStep();
          }
        } else {
          // If creation failed, show error and don't proceed
          showToast(res.message || 'Failed to create counter party', 'error');
        }
      } else {
        // If there's a selected counter party, just proceed with it
        setPaymentData({
          ...getValues(),
          counterId: selectedCounterParty,
        });
        handleNextStep();
      }
    } catch (error: any) {
      showToast(error.message, 'error');
    } finally {
      onClose();
    }
  };

  useEffect(() => {
    if (
      selectedCountry?.value?.code &&
      selectedWallet.currency === 'USD' &&
      !selectedCounterParty
    ) {
      setValue('country_code', selectedCountry.value.code, {
        shouldDirty: false, // This prevents the field from affecting isDirty state
        shouldValidate: true, // This validates the field
      });
    }
  }, [
    selectedCountry,
    setValue,
    selectedWallet.currency,
    selectedCounterParty,
  ]);

  useEffect(() => {
    const fetchSubdivisions = async () => {
      if (!selectedCountry) return;

      setIsLoadingSubdivisions(true);
      try {
        const result = await executeGetSubdivisions(
          selectedCountry.value.code,
          token,
        );
        if (result.isSuccess && Array.isArray(result.data)) {
          setSubdivisions(
            result.data.map((sub) => ({
              id: sub.value,
              name: sub.key,
              code: sub.value,
            })),
          );
        } else {
          showToast('Failed to load subdivisions', 'error');
        }
      } catch (error) {
        console.error('Error fetching subdivisions:', error);
        showToast('Error loading subdivisions', 'error');
      } finally {
        setIsLoadingSubdivisions(false);
      }
    };

    fetchSubdivisions();
  }, [selectedCountry, token]);

  return (
    <div
      className="bg-[#F5FAFE] p-6 mb-[24px] mt-4 rounded-lg space-y-6"
      style={{ maxHeight: '80%', overflowY: 'auto' }}
    >
      {inputs.map((input: InputField, idx: number) =>
        input.enum ? (
          <Controller
            key={idx}
            name={input.name}
            control={control}
            rules={{ required: input.required !== false }}
            render={({ field }) => (
              <Select
                selected={field.value as { id: number; name: string }}
                setSelected={(value) => {
                  field.onChange(value);
                  trigger(input.name); // Trigger validation on change
                }}
                datas={input.enum?.map((item) => ({
                  name: item,
                  id: item,
                }))}
                label={input.label || input.name}
              >
                <div className="w-full flex-btn text-textGray text-sm p-4 border border-border font-light rounded-lg focus:border focus:border-subMain bg-white">
                  {(field.value as { id: number; name: string })?.name || ''}
                  <BiChevronDown className="text-xl" />
                </div>
              </Select>
            )}
          />
        ) : input.banks ? (
          <Controller
            key={idx}
            name={input.name}
            control={control}
            rules={{ required: input.required !== false }}
            render={({ field }) => (
              <Select
                selected={field.value as { id: number; name: string }}
                setSelected={(value) => {
                  field.onChange(value);
                  trigger(input.name);
                }}
                datas={input.banks?.map((item) => ({
                  name: item.bankName,
                  id: item.nipBankCode,
                }))}
                label={'Bank Name'}
              >
                <div className="w-full flex-btn text-textGray text-sm p-4 border border-border font-light rounded-lg focus:border focus:border-subMain bg-white">
                  {(field.value as { id: number; name: string })?.name || ''}
                  <BiChevronDown className="text-xl" />
                </div>
              </Select>
            )}
          />
        ) : (
          <div key={idx}>
            <Input
              name={input.name}
              placeholder={input.placeholder || input.name}
              label={input.label || input.name}
              type={input.type || 'text'}
              color={true}
              register={register}
              required={input.required !== false}
            />
            {errors[input.name] && (
              <p className="text-red-500 text-sm">
                {errors[input.name]?.message?.toString()}
              </p>
            )}
          </div>
        ),
      )}
      {transferInputs().map((input, idx) => (
        <div key={idx}>
          <Input
            name={input.name}
            placeholder={input.placeholder || input.name}
            label={input.label || input.name}
            type={input.type || 'text'}
            color={true}
            register={register}
            required
          />
          {errors[input.name] && (
            <p className="text-red-500 text-sm">
              {errors[input.name]?.message?.toString()}
            </p>
          )}
        </div>
      ))}

      {selectedWallet.currency === 'USD' &&
        !selectedCounterParty &&
        cynbridCounterPartyInputs().map((input, idx) =>
          input.name === 'subdivision' ? (
            <Controller
              key={idx}
              name={input.name}
              control={control}
              render={({ field }) => (
                <Select
                  selected={field.value}
                  setSelected={(value) => {
                    field.onChange(value);
                    trigger(input.name);
                  }}
                  datas={subdivisions}
                  label={input.label}
                >
                  <div className="w-full flex-btn text-textGray text-sm p-4 border border-border font-light rounded-lg focus:border focus:border-subMain bg-white">
                    {field.value?.name ||
                      (isLoadingSubdivisions
                        ? 'Loading...'
                        : 'Select subdivision')}
                    <BiChevronDown className="text-xl" />
                  </div>
                </Select>
              )}
            />
          ) : input.name === 'country_code' ? (
            <div key={idx}>
              <Input
                name={input.name}
                placeholder={input.placeholder || input.name}
                label={input.label || input.name}
                type={input.type || 'text'}
                color={true}
                register={register}
                required={input.isRequired}
                readOnly={true} // Make the field read-only
                className="bg-gray-50" // Optional: Add a background color to indicate it's read-only
              />
              {errors[input.name] && (
                <p className="text-red-500 text-sm">
                  {errors[input.name]?.message?.toString()}
                </p>
              )}
            </div>
          ) : (
            <div key={idx}>
              <Input
                name={input.name}
                placeholder={input.placeholder || input.name}
                label={input.label || input.name}
                type={input.type || 'text'}
                color={true}
                register={register}
                required={input.isRequired}
              />
              {errors[input.name] && (
                <p className="text-red-500 text-sm">
                  {errors[input.name]?.message?.toString()}
                </p>
              )}
            </div>
          ),
        )}
      <Button
        loading={isOpen}
        label="Next"
        onClick={onSubmit}
        disabled={!isValid || !isDirty} // Disable button if form is invalid or unchanged
        extraClasses={`mb-[2px] ${!isValid || !isDirty ? 'opacity-50 cursor-not-allowed' : ''}`}
      />
      <CounterPartyVerificationModal
        isOpen={isVerificationModalOpen}
        onClose={() => setIsVerificationModalOpen(false)}
        counterId={paymentData?.counterId}
        onVerificationSuccess={handleNextStep}
      />
    </div>
  );
};

export default Recipient;
