import locker from 'assets/locker.png';
import { useNavigate } from 'react-router-dom';

const RequestCard = () => {
  const navigate = useNavigate();
  return (
    <div className="px-20 bg-[#F5FAFE] pb-18 h-full">
      <h1 className="text-center text-[20px] text-primary font-medium pt-4">
        Request a virtual card
      </h1>
      <div className="flex flex-col items-center text-center">
        <img src={locker} alt="Locker" className="my-4" />
        <button
          onClick={() => navigate('/settings/card')}
          className="border border-solid border-primary bg-white font-normal rounded px-4 py-2"
        >
          Send Request
        </button>
      </div>
    </div>
  );
};

export default RequestCard;
