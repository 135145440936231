// WaitingPage.tsx
import { Button } from 'components/common/Form';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface WaitingPageProps {
  message?: string;
}

const WaitingPage: React.FC<WaitingPageProps> = ({
  message = 'Your KYC is currently being reviewed. Please wait.',
}) => {
  const navigate = useNavigate();
  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="text-center p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold mb-4">Reviewing KYC</h2>
        <p className="text-lg text-gray-600">{message}</p>
        <div className="mt-4">
          <span className="loader"></span>
        </div>
      </div>
      <Button
        label="Proceed to Dahboard"
        onClick={() => navigate('/dashboard')}
        loading={false}
        extraClasses="border border-primary"
      />
    </div>
  );
};

export default WaitingPage;
