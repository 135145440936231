/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import ForgotPassword from './ForgotPassword';
import VerifyEmail from './VerifyEmail';
import NewPassword from './NewPassword';

// import { useAppSelector } from "store/hooks";
// import { useAppSelector } from "../../store/hook";

const FacilityForm = () => {
  const currentStep = 'VALIDATE_EMAIL';
  // const { currentStep } = useAppSelector((state) => state.registerStore);

  const valueMap = {
    VALIDATE_EMAIL: 0,
    VERIFY_EMAIL: 1,
    // VALIDATE_MOBILE: 2,
    // VERIFY_MOBILE: 3,
    SIGN_UP: 4,
    RESIDENTIAL_ADDRESS: 5,
    SECURE_TRANSACTIONS_GUIDE: 6,
    SECURE_ACCOUNT: 7,
    ACTIVATE_PIN: 8,
  };
  const [activeStep, setActiveStep] = useState(
    (valueMap as any)[currentStep] || 0,
  );

  const steps = [
    {
      name: 'Validate Email',
      component: <ForgotPassword setActiveStep={setActiveStep} />,
    },
    {
      name: 'Verify Email',
      component: <VerifyEmail setActiveStep={setActiveStep} />,
    },
    {
      name: 'Verify Email',
      component: <NewPassword />,
    },
  ];
  return <> {steps[activeStep].component}</>;
};

export default FacilityForm;
