import React, { useState } from 'react';
import ProfileForm from './Form';
import QRcode from './QRcode';

const Index = () => {
  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    {
      name: 'First Form',
      component: <ProfileForm setActiveStep={setActiveStep} />,
    },
    {
      name: 'Second Form',
      component: <QRcode setActiveStep={setActiveStep} />,
    },
  ];

  return (
    <div className="flex flex-col items-center justify-center">
      {steps[activeStep].component}
    </div>
  );
};

export default Index;
