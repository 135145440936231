import React from 'react';
import Atm from 'components/cards/AtmCard';
import Fade from './index';
import flag from 'assets/flags.svg';
import cad from 'assets/canada.svg';
import usa from 'assets/usa.svg';
import { CreditIcon, ReceivedIcon } from 'components/icons';
import SendMoney from 'components/cards/SendMoney';
import { useDrawer } from 'contexts/Drawer';
import { useAppSelector } from 'store/hooks';
import VirtualCardModal from 'components/Modal/VirtualCardModal';

const CardCarousel = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const wallets = useAppSelector((state) => state.accountStore.wallets);
  const [activeSlide, setActiveSlide] = React.useState(0); // Track active slide
  const user = useAppSelector((state) => state.accountStore.account);
  const carousels = Object.values(wallets!).map((item: any) => ({
    id: 3,
    component: <Atm wallet={item} />,
    wallet: item,
  }));
  const activeWallet = carousels[activeSlide]?.wallet;

  const flagRef =
    activeWallet?.currency === 'NGN'
      ? flag
      : activeWallet?.currency === 'CAD'
        ? cad
        : usa;

  const { openDrawer, closeDrawer } = useDrawer();

  const handleOpenDrawer = () => {
    openDrawer(<SendMoney onClose={() => closeDrawer()} />);
  };

  return (
    <div className="px-4 py-8 bg-greyed h-full rounded-lg border border-neutral-300">
      <div className="flex justify-between mb-6 items-center">
        <h2 className="text-6 font-semibold">
          Welcome {user?.first_name} {user?.last_name},
        </h2>
        <img src={flagRef} alt="flag" />
      </div>
      <div className="flex flex-col mb-12">
        <Fade
          items={carousels}
          onSlideChange={(index: number) => setActiveSlide(index)}
        />
      </div>
      <div className="flex mt-0 space-x-6 justify-between">
        <button
          className="bg-yellow-500 text-primary max-w-xs py-2 px-4 rounded flex items-center justify-center space-x-2 w-full"
          onClick={handleOpenDrawer}
        >
          <ReceivedIcon primaryColor="#221860" />
          <span>Transfer</span>
        </button>
        <button
          onClick={() => setIsModalOpen(true)}
          className="border border-green-600 text-green-600 max-w-xs py-2 px-4 rounded flex items-center justify-center space-x-2 w-full"
        >
          <CreditIcon />
          <span>Add Money</span>
        </button>
      </div>
      <VirtualCardModal
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        activeWallet={activeWallet}
      />
    </div>
  );
};

export default CardCarousel;
