import logo from 'assets/whlogo.png';
import flag from 'assets/flags.svg';

interface Props {
  data: any;
}
const Receipt: React.FC<Props> = ({ data }) => {
  return (
    <div className="bg-white px-4 pb-4 rounded-lg">
      <img src={logo} alt="Logo" className="w-18 h-18" />
      <h2 className="text-xs font-medium leading-4">Receipt</h2>
      <div className="w-full">
        <div className="flex justify-between w-full">
          <p className="text-xs">22/12/2023</p>
          <p className="text-xs">Personal</p>
        </div>
        <div>
          <h3 className="text-xs">Amount</h3>
          <p className="text-sm">Recipient Name</p>
          <p className="text-sm">Account Number</p>
          <p className="text-sm">Reason for payment</p>
        </div>
        <div className="flex justify-between w-full">
          <p className="text-xs">Reference Number</p>
          <p className="text-xs">########</p>
        </div>
        <div className="flex justify-between w-full">
          <p className="text-xs">Type</p>
          <p className="text-xs">Transfer</p>
        </div>
        <div className="flex justify-between w-full">
          <p className="text-xs">Transaction ID</p>
          <p className="text-xs">########</p>
        </div>
        <div className="flex justify-between w-full">
          <p className="text-xs">Branch</p>
          <p className="text-xs">Head Office</p>
        </div>
      </div>
      <div className="w-full">
        <div className="flex justify-between w-full">
          <p className="text-xs leading-4">Phone Number</p>
          <p className="text-xs leading-4">Transfer Fee</p>
        </div>
        <div className="flex justify-between w-full">
          <p className="text-xs leading-4">Address</p>
          <p className="text-sm font-semibold">{data.amount}</p>
        </div>
        <div className="flex gap-2 w-full">
          <img src={flag} alt="Flag" className="inline" />
          <p className="text-sm">Access Bank/Victoria island</p>
        </div>
      </div>
    </div>
  );
};

export default Receipt;
