import Layout from 'layouts/Dashboard';
import ActivateAccount from 'components/cards/ActivateAccount';
import RequestCard from 'components/cards/Request';
import ReferAndEarn from 'components/cards/ReferAndEarn';
import ConversionCard from 'components/cards/ConversionCard';
import Transaction from 'components/lists/transaction';
import { useAppSelector } from 'store/hooks';
import useDisclosure from 'hooks/useDisclosure';
import { useToast } from 'components/Notifications/Toast';
import { executeGetRecentTransactions } from 'api/bank';
import { useEffect, useState } from 'react';
import { Transaction as TransactionDataType } from 'types/data';
import Loader from 'components/Notifications/Loader';

function Dashboard() {
  const wallets = useAppSelector((state) => state.accountStore.wallets);
  const tokenStore = useAppSelector((state) => state.accountStore.tokenStore);
  const userId = useAppSelector((state) => state.accountStore?.account?.id);
  const [transactions, setTransactions] = useState<TransactionDataType[]>([]);

  const { showToast } = useToast();
  const {
    isOpen: isLoading,
    onOpen: openLoading,
    onClose: closeLoading,
  } = useDisclosure();

  const handleGetRecentTransactions = async () => {
    if (!tokenStore) return;
    try {
      openLoading();
      const result = await executeGetRecentTransactions(tokenStore, userId!);
      if (!result.isSuccess)
        throw new Error('Failed to get recent transactions, Try again');
      setTransactions(result?.data);
    } catch (err: any) {
      console.log('Error:', err.message);
      const isNetwork = err.message.toLowerCase().includes('network');
      showToast(
        isNetwork
          ? "Can't connect to internet, check your network settings"
          : err.message,
        isNetwork ? 'warning' : 'error',
      );
    } finally {
      closeLoading();
    }
  };

  useEffect(() => {
    handleGetRecentTransactions();
  }, []);

  if (!wallets) return <Loader />;

  return (
    <Layout title="Dashboard">
      <div className="static w-full grid xl:grid-cols-10 gap-4 g:grid-cols-3 sm:grid-cols-2 grid-cols-1">
        <div className="col-span-7">
          <div
            data-aos="flip-right"
            data-aos-duration="1000"
            data-aos-delay="10"
            data-aos-offset="200"
            className="w-full grid gap-6 xl:grid-cols-8 lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-2 grid-cols-1"
          >
            <div className="col-span-8 md:col-span-2 lg:col-span-4 xl:col-span-5">
              <ActivateAccount />
            </div>
            <div className="col-span-8 md:col-span-2 lg:col-span-2 xl:col-span-3">
              <ConversionCard />
            </div>
            <div className="col-span-8 md:col-span-2 lg:col-span-3 xl:col-span-4">
              <RequestCard />
            </div>
            <div className="col-span-8 md:col-span-2 lg:col-span-3 xl:col-span-4">
              <ReferAndEarn />
            </div>
          </div>
        </div>
        <div className="col-span-3">
          <div className="w-full grid xl:grid-cols-2 grid-cols-1 gap-6 z-1">
            <div className="xl:col-span-2 xl:block grid sm:grid-cols-2 gap-6">
              <div className="bg-[#F5FAFE] rounded-lg">
                <div className="px-6 py-5">
                  <div className="flex justify-between items-center">
                    <h2 className="text-sm text-light-textTwo-neutralGray">
                      Top Transactions
                    </h2>
                    <a
                      href={`/transactions/${wallets[1]?.id}`}
                      className="outline-none px-4 py-2 bg-white rounded-md text-sm font-normal text-center flex items-center justify-center"
                    >
                      See All
                    </a>
                  </div>
                </div>
                {isLoading ? (
                  <Loader />
                ) : (
                  transactions.map((item, idx) => (
                    <Transaction item={item} key={idx} />
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Dashboard;
