import React, { ReactNode } from 'react';
import logo from 'assets/logo.png';
import q2 from 'assets/q2.png';

interface AuthLayoutProps {
  children?: ReactNode;
  smaller?: boolean;
  background?: boolean;
  backgroundImage?: string;
  backgroundOverlay?: string;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({
  children,
  smaller,
  background,
  backgroundImage,
  backgroundOverlay,
}) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 h-screen w-full overflow-hidden">
      {!background ? (
        <div className="hidden md:block bg-[#F5FAFE]">
          <div className="flex items-center justify-center h-full">
            <img src={logo} alt="logo" />
          </div>
        </div>
      ) : (
        <div
          className="hidden md:block bg-cover"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        >
          <div className="flex items-center justify-center h-full">
            <img src={q2} alt="background" />
          </div>
        </div>
      )}
      <div
        className="overflow-y-auto"
        style={{ backgroundColor: backgroundOverlay ?? 'white' }}
      >
        <div className="flex items-center justify-center h-full">
          <div
            className={`px-8 py-[50px] h-full ${
              smaller ? 'max-w-sm' : 'max-w-md'
            }`}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
