// AtmCard.tsx
import React, { useState, useCallback, memo } from 'react';
import cardBg from 'assets/atm-card-bg.jpeg';
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';
import { AddIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { BiLoaderCircle } from 'react-icons/bi';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { populateSelectedWallet } from 'store/account/accountSlice';
import { WalletData } from 'types/data';
import {
  executeCreateCybridWallet,
  executeCreateExternalBankAccount,
  executeCreatePlaidWorkflow,
  executeGetWorkflow,
} from 'api/kyc';
import useDisclosure from 'hooks/useDisclosure';
import { useToast } from 'components/Notifications/Toast';
import Modal from 'components/Modal';
import { Button } from 'components/common/Form';
import { usePlaidLink } from 'react-plaid-link';

interface AtmProps {
  wallet: WalletData;
}

const Atm: React.FC<AtmProps> = memo(({ wallet }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [plaidLinkToken, setPlaidLinkToken] = useState<string | null>(null);
  const [isAccountNumberVisible, setIsAccountNumberVisible] = useState(false);

  const navigate = useNavigate();
  const { showToast } = useToast();
  const dispatch = useAppDispatch();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const {
    onOpen: openModal,
    onClose: closeModal,
    isOpen: isModalOpen,
  } = useDisclosure();

  const token = useAppSelector((state) => state.accountStore.tokenStore);

  const { open, ready } = usePlaidLink({
    token: plaidLinkToken,
    onSuccess: useCallback(
      async (public_token, metadata) => {
        showToast('Bank account linked successfully!', 'success');
        const payload = {
          account_kind: 'plaid_processor_token',
          name: 'wdsddas',
          customer_guid: 'asdsdasd',
          plaid_processor_token: 'sadaDSDAD',
          plaid_institution_id: 'sdsasdas',
          plaid_account_mask: 'addsda',
          plaid_account_name: 'aSDASDSAD',
          wallet_id: 'kmlml',
        };

        await executeCreateExternalBankAccount(payload, token);
        console.log(public_token, metadata);
      },
      [showToast],
    ),
    onExit: useCallback(
      (err: any, metadata: any) => {
        if (err) showToast('Linking process exited or failed', 'error');
        console.log(metadata);
      },
      [showToast],
    ),
    onEvent: useCallback((eventName: any, metadata: any) => {
      console.log(eventName, metadata);
    }, []),
  });

  React.useEffect(() => {
    if (ready) {
      open();
      closeModal();
    }
  }, [ready, open]);

  const handleAddAccount = useCallback(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);

      if (wallet.customer_id) {
        dispatch(populateSelectedWallet(wallet));
        navigate('/verify-identity');
      } else if (wallet.identity_outcome === 'failed') {
        dispatch(populateSelectedWallet(wallet));
        navigate('/verify-identity');
      } else {
        navigate(
          wallet.currency === 'NGN'
            ? '/verify-bvn?currency=NGN'
            : `/verify-ssn?currency=${wallet.currency}`,
        );
      }
    }, 2000);
  }, [wallet, dispatch, navigate]);

  const handleCreateWallet = useCallback(async () => {
    try {
      onOpen();
      const result = await executeCreateCybridWallet(wallet.id, token);
      if (!result.isSuccess) throw new Error(result.message);
      showToast(result.message, 'success');
      navigate('/dashboard');
    } catch (error: any) {
      showToast(error.message, 'error');
    } finally {
      onClose();
    }
  }, [wallet.id, token, onOpen, onClose, showToast, navigate]);

  const pollForPlaidProcessorToken = useCallback(
    async (workflowId: string, maxRetries = 10, delay = 2000) => {
      let retries = 0;

      return new Promise((resolve, reject) => {
        const poll = async () => {
          try {
            const result = await executeGetWorkflow(workflowId, token);
            if (result.data.plaid_link_token) {
              setPlaidLinkToken(result.data.plaid_link_token);
              showToast('Plaid link token retrieved!', 'success');
              return resolve(result.data.plaid_link_token);
            }

            if (retries >= maxRetries) {
              return reject(
                new Error(
                  'Failed to retrieve plaid_link_token within the retry limit.',
                ),
              );
            }

            retries++;
            setTimeout(poll, delay);
          } catch (error) {
            showToast(
              'Error fetching plaid link token, retrying...',
              'warning',
            );
            if (retries >= maxRetries) {
              return reject(error);
            }
            retries++;
            setTimeout(poll, delay);
          }
        };

        poll();
      });
    },
    [token, showToast, open],
  );

  const createExternalWorkflow = useCallback(async () => {
    try {
      onOpen();
      const payload = {
        type: 'plaid',
        kind: 'link_token_create',
        customer_guid: wallet.customer_id,
        language: 'en',
        link_customization_name: 'default',
        wallet_id: wallet.id,
      };
      const result = await executeCreatePlaidWorkflow(payload, token);
      await pollForPlaidProcessorToken(result.data.guid);
      if (!result.isSuccess) throw new Error(result.message);
      showToast(result.message, 'success');
    } catch (error: any) {
      showToast(error.message, 'error');
    } finally {
      onClose();
    }
  }, [wallet, token, onOpen, onClose, showToast, pollForPlaidProcessorToken]);

  const toggleAccountVisibility = useCallback(() => {
    setIsAccountNumberVisible((prev) => !prev);
  }, []);

  return (
    <div
      className="px-6 py-10 bg-cover text-white w-full rounded-lg"
      style={{ backgroundImage: `url(${cardBg})` }}
    >
      <div className="flex justify-between mb-6 px-6">
        <p className="text-base">{wallet.currency} Account</p>
        <p className="text-sm">{wallet.type}</p>
      </div>
      <div className="flex justify-between px-6">
        {wallet.currency === 'CAD' && (
          <div className="flex w-full justify-center">
            <div className="mx-auto items-center bg-[orange] text-[white] text-sm font-medium mb-[4px] px-[8px] py-[4px] rounded-full">
              Coming Soon
            </div>
          </div>
        )}
        {wallet.status === 'pending' && wallet.currency !== 'CAD' && (
          <div className="flex w-full">
            <button
              onClick={handleAddAccount}
              className="text-primary max-w-xs bg-[white] py-2 px-4 rounded flex items-center justify-center mx-auto space-x-2"
            >
              {isLoading ? (
                <BiLoaderCircle className="animate-spin text-green-600 text-2xl" />
              ) : (
                <>
                  <AddIcon />
                  <span>Add Account</span>
                </>
              )}
            </button>
          </div>
        )}
        {wallet.identity_outcome === 'failed' && (
          <div className="flex w-full">
            <button
              onClick={handleAddAccount}
              className="text-primary max-w-xs bg-[white] py-2 px-4 rounded flex items-center justify-center mx-auto space-x-2"
            >
              {isLoading ? (
                <BiLoaderCircle className="animate-spin text-green-600 text-2xl" />
              ) : (
                <>
                  <AddIcon />
                  <span>Retry KYC</span>
                </>
              )}
            </button>
          </div>
        )}
        {wallet.status === 'active' && (
          <div className="flex items-center w-full">
            <h2 className="text-3xl font-bold">{wallet.available_balance}</h2>
            {wallet.account_number && (
              <>
                <p className="text-base inline ml-2">
                  {isAccountNumberVisible
                    ? wallet.account_number
                    : '**** **** **** ' + wallet.account_number.slice(-4)}
                </p>
                <p
                  className="text-sm ml-auto cursor-pointer"
                  onClick={toggleAccountVisibility}
                >
                  {isAccountNumberVisible ? (
                    <IoEyeOutline size={24} />
                  ) : (
                    <IoEyeOffOutline size={24} />
                  )}
                </p>
              </>
            )}
          </div>
        )}

        {wallet.status === 'processing' && wallet.currency !== 'CAD' && (
          <div className="flex items-center flex-col w-full">
            <button
              onClick={openModal}
              className="text-primary max-w-xs bg-[white] py-2 px-4 rounded flex items-center justify-center mx-auto space-x-2"
            >
              {isOpen ? (
                <BiLoaderCircle className="animate-spin text-green-600 text-2xl" />
              ) : (
                <>
                  <AddIcon />
                  <span>Create Wallet</span>
                </>
              )}
            </button>
          </div>
        )}

        {wallet.status === 'failed' && (
          <div className="flex w-full">
            <div className="flex items-center flex-col">
              <div className="inline-flex items-center bg-[#FF4C51] text-[white] text-sm font-medium mb-[4px] px-[8px] py-[4px] rounded-full">
                Account Failed
              </div>
              <p className="text-[12px] inline ml-2">KYC Rejected</p>
            </div>
            <button className="text-primary max-w-xs bg-[white] py-2 px-4 rounded flex items-center justify-center ml-auto space-x-2">
              <AddIcon />
              <span>Add Account</span>
            </button>
          </div>
        )}
        {wallet.status === 'blocked' && (
          <div className="flex items-center flex-col w-full">
            <div className="inline-flex items-center bg-[#FF4C51] text-[white] text-sm font-medium mb-2 px-[8px] py-[4px] rounded-full">
              Account Blocked
            </div>
            <p className="text-[12px] inline ml-2">Contact Us Now</p>
          </div>
        )}
      </div>

      <Modal
        isOpen={isModalOpen}
        closeModal={closeModal}
        title="Create Account"
        width={'max-w-lg'}
      >
        <CreateAccountModal
          handleCreateWallet={handleCreateWallet}
          createExternalWorkflow={createExternalWorkflow}
          isOpen={isOpen}
        />
      </Modal>
    </div>
  );
});

Atm.displayName = 'Atm';

interface CreateAccountModalProps {
  createExternalWorkflow: () => void;
  handleCreateWallet: () => void;
  isOpen: boolean;
}

const CreateAccountModal: React.FC<CreateAccountModalProps> = memo(
  ({ createExternalWorkflow, handleCreateWallet, isOpen }) => {
    return (
      <div className="flex items-center justify-center">
        <div className="space-x-4">
          <Button
            extraClasses=""
            label="Create Identity Account"
            onClick={handleCreateWallet}
          />
          <Button
            label="Link External Bank Account"
            // outline={true}
            onClick={createExternalWorkflow}
            loading={isOpen}
          />
        </div>
      </div>
    );
  },
);

CreateAccountModal.displayName = 'CreateAccountModal';

export default Atm;
