import AuthLayout from 'layouts/Auth';
import { useForm } from 'react-hook-form';
import authBg from 'assets/form-bg-three.png';
import { Button, Input } from 'components/common/Form';
import { BiLogInCircle } from 'react-icons/bi';
import React from 'react';
import { useToast } from 'components/Notifications/Toast';
import { executeResetPassword } from 'api/auth';
import { useNavigate } from 'react-router-dom';

interface VerifyEmailProps {
  email: string;
  otp: number | null;
}

const NewPassword: React.FC<VerifyEmailProps> = ({ email, otp }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { control, register, watch, getValues } = useForm<RegistrationData>({
    mode: 'onSubmit',
  });

  const password = watch('password');

  const { showToast } = useToast();
  const navigate = useNavigate();

  const handleResetPassword = async () => {
    const { password, confirm_password } = getValues();
    if (password !== confirm_password) {
      return showToast('Passwords do not match', 'error');
    }
    try {
      setIsLoading(true);
      const payload = {
        email: email,
        new_password: password,
        confirm_password,
        otp: otp!,
      };
      const result = await executeResetPassword(payload);
      if (!result.isSuccess) throw new Error(result.message);
      showToast(result.message, 'success');
      setTimeout(() => {
        navigate('/login'); // Replace with your navigation function, e.g., `useNavigate` from React Router
      }, 1000);
    } catch (error: any) {
      showToast(error.message, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthLayout background={true} backgroundImage={authBg}>
      <div>
        <h2 className="text-2xl font-semibold mb-[11px]">New Password</h2>
        <p className="text-sm leading-[25px] text-[#949596] mb-8">
          Input a safe password that can stand the <br /> test of fraud
        </p>
        <div className="space-y-6">
          <Input
            control={control}
            name="password"
            type="password"
            color={true}
            label="Password"
            placeholder="**********"
            rules={{
              validate: (value: string) => {
                return value !== password ? 'Wrong password' : undefined;
              },
            }}
            register={register}
          />
          <Input
            control={control}
            name="confirm_password"
            type="password"
            color={true}
            label="Confirm Password"
            placeholder="**********"
            rules={{
              validate: (value: string) => {
                return value !== password ? 'Wrong password' : undefined;
              },
            }}
            register={register}
          />
        </div>
        <Button
          label="Log in again"
          Icon={BiLogInCircle}
          onClick={handleResetPassword}
          extraClasses="mt-[70px]"
          loading={isLoading}
        />
      </div>
    </AuthLayout>
  );
};

export default NewPassword;
