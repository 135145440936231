import React from 'react';

interface DialPadProps {
  cta: React.Dispatch<React.SetStateAction<number>>; // Update typing for cta
  title: string;
  pin: string;
  setPin: React.Dispatch<React.SetStateAction<string>>; // Update typing for setPin
}

const DialPad: React.FC<DialPadProps> = ({ cta, title, pin, setPin }) => {
  const handlePinSubmit = () => {
    cta((prev: any) => prev + 1);
  };

  const handleButtonClick = (value: any) => {
    if (pin.length < 4) {
      setPin((prevPin: any) => {
        const newPin = prevPin + value;
        if (newPin.length === 4) {
          handlePinSubmit();
        }
        return newPin;
      });
    } else {
      setPin(value.toString());
    }
  };

  const renderCircle = (index: any) => {
    return (
      <div
        key={index}
        className={`w-4 h-4 border-2 rounded-full mx-1 ${
          index < pin.length ? 'bg-primary border-primary' : 'border-primary'
        }`}
      />
    );
  };

  return (
    <div className=" w-[400px] px-[60px] bg-white pt-[40px] pb-[90px] flex flex-col items-center">
      <h2 className="text-[24px] mb-[36px] text-[#000] font-semibold">
        {title}
      </h2>
      <div className="flex justify-center mb-20">
        {[...Array(4)].map((_, index) => renderCircle(index))}
      </div>
      <div className="grid grid-cols-3 gap-14">
        {[...Array(9)].map((_, index) => (
          <div key={index} className="mx-auto">
            <button
              onClick={() => handleButtonClick(index + 1)}
              className="border border-gray-400 rounded-full w-14 h-14"
            >
              <span className="text-lg">{index + 1}</span>
            </button>
          </div>
        ))}
        <div className="col-span-3 mx-auto">
          <button
            onClick={() => handleButtonClick(0)}
            className="border border-gray-400 rounded-full w-14 h-14"
          >
            <span className="text-lg">0</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DialPad;
