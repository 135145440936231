import React, { useState } from 'react';
import SelectCard from './components/SelectCard';
import Initial from './components/Initial';
import CardSelected from './components/CardSelected';
import Carousel from './components/CardCarousel';
import Proceed from './components/Proceed';

const Cards = () => {
  const valueMap = {
    INITIAL: 0,
    SELECT_CARD: 1,
    CARD_SELECTED: 2,
    CARD_CAROUSEL: 3,
  };

  const currentStep = 'INITIAL';
  const [selectedCard, setSelectedCard] = useState<string | null>(null);

  const [activeStep, setActiveStep] = React.useState(
    (valueMap as any)[currentStep] || 0,
  );

  const steps = [
    {
      name: 'Initial',
      component: (
        <Initial
          setActiveStep={setActiveStep}
          setSelectedCard={setSelectedCard}
          selectedCard={selectedCard}
        />
      ),
    },
    {
      name: 'Initial',
      component: <Proceed setActiveStep={setActiveStep} />,
    },
    {
      name: 'Select Card',
      component: <SelectCard setActiveStep={setActiveStep} />,
    },
    {
      name: 'Card Selected',
      component: <CardSelected setActiveStep={setActiveStep} />,
    },
    {
      name: 'Card Carousel',
      component: <Carousel />,
    },
  ];
  return <>{steps[activeStep].component}</>;
};

export default Cards;
