import { Button, Input } from 'components/common/Form';
import authBg from 'assets/q1.png';
import { useForm } from 'react-hook-form';
import { registrationInputsTwo } from 'utils/data';
import KycLayout from 'layouts/Kyc';
import useDisclosure from 'hooks/useDisclosure';
import { executeValidateBvn } from 'api/auth';
import { useToast } from 'components/Notifications/Toast';
import { useAppSelector } from 'store/hooks';
import { useLocation, useNavigate } from 'react-router-dom';

const BvnVerification = () => {
  const { trigger, register, handleSubmit } = useForm<BvnValidation>({
    mode: 'onSubmit',
  });
  const token = useAppSelector((state) => state.accountStore.tokenStore);
  const userId = useAppSelector((state) => state.accountStore.account?.id);
  const navigate = useNavigate();
  const {
    isOpen: isLoading,
    onOpen: openLoading,
    onClose: closeLoading,
  } = useDisclosure();

  const currency = new URLSearchParams(useLocation().search).get('currency');

  const { showToast } = useToast();

  const handleValidateBvn = async (data: BvnValidation) => {
    if (!(await trigger())) return false;
    if (!token) return;
    if (!userId) return;
    try {
      openLoading();
      const dateOfBirth = new Date(data.dob);
      const options: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      };
      const formattedDate = dateOfBirth
        .toLocaleDateString('en-GB', options)
        .replace(/ /g, '-');

      const payload: BvnValidation = {
        bvn: data.bvn,
        dob: formattedDate,
        userId,
      };
      const result = await executeValidateBvn(token, userId, payload);
      if (!result.isSuccess) throw new Error(result.message);
      showToast(result.message, 'success');
      navigate(`/kyc?currency=${currency}`);
    } catch (error: any) {
      showToast(error.message, 'error');
    } finally {
      closeLoading();
    }
  };

  return (
    <KycLayout background={true} backgroundImage={authBg}>
      <h2 className="text-2xl font-semibold mb-[35px]">Validate BVN</h2>
      <form className="space-y-6" onSubmit={handleSubmit(handleValidateBvn)}>
        {registrationInputsTwo().map((input, idx) => (
          <Input
            key={idx}
            name={input.name}
            placeholder={input.placeholder}
            color={true}
            label={input.label}
            type={input.type}
            register={register}
          />
        ))}
        <Button
          label="Continue"
          loading={isLoading}
          extraClasses="mt-[196px] mb-[24px]"
        />
      </form>
    </KycLayout>
  );
};

export default BvnVerification;
