import React, { useEffect } from 'react';
import { executeCreateAccount, executeVerifyKyc } from 'api/kyc';
import authBg from 'assets/q1.png';
import KycLayout from 'layouts/Kyc';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getIdentityLabel, getSlug } from 'utils/helpers';
import { Button, Input } from 'components/common/Form';
import { useToast } from 'components/Notifications/Toast';
import UploadInput from './Upload';
import flag from 'assets/flags.svg';
import { populateSelectedCountry } from 'store/country/countrySlice';
import useDisclosure from 'hooks/useDisclosure';

const Index = () => {
  const { showToast } = useToast();
  const [image, setImage] = React.useState<KycUploads>();
  const navigate = useNavigate();
  const {
    control,
    getValues,
    trigger,
    register,
    setError,
    setValue,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    mode: 'onSubmit',
  });

  const token = useAppSelector((state) => state.accountStore.tokenStore);
  const { countries } = useAppSelector((state) => state.countryStore);
  const currency = new URLSearchParams(useLocation().search).get('currency');
  const userId = useAppSelector((state) => state.accountStore.account?.id);
  const {
    isOpen: isLoading,
    onOpen: openLoading,
    onClose: closeLoading,
  } = useDisclosure();

  const dispatch = useAppDispatch();
  const selectedCountry = useAppSelector(
    (state) => state.countryStore.selectedCountry,
  );

  // Always call the useEffect hook regardless of conditions
  useEffect(() => {
    if (countries && currency) {
      const foundCountry = countries.find(
        (country) => country.currency_code === currency,
      );

      console.log({ foundCountry });
      if (foundCountry) {
        dispatch(populateSelectedCountry(foundCountry));
      }
    }
  }, [currency]);

  // Add check inside JSX to avoid conditional return outside hooks
  if (!selectedCountry) {
    return null; // Render nothing or a loader when country is not selected
  }

  const kycInputs = (): AuthInput[] => {
    return [
      {
        name: 'nin',
        label: 'NIN',
        rule: `Please enter your ${getIdentityLabel(selectedCountry)}`,
        placeholder: 'Placeholder text',
        isRequired: true,
        type: 'number',
      },
      {
        name: 'image',
        label: 'Upload Your Image',
        rule: 'Please upload your image',
        isRequired: true,
        type: 'file',
      },
    ];
  };

  const handleVerifyKyc = async () => {
    try {
      openLoading();
      if (!(await trigger())) return false;
      const payload = {
        number: getValues(`nin`),
        image: image?.image,
      };
      const result = await executeVerifyKyc(payload, token);
      if (!result.isSuccess) throw new Error(result.message);
      showToast(result.message, 'success');
      await executeCreateAccount(userId!, token);
      navigate('/dashboard');
    } catch (error: any) {
      showToast(error.message, 'error');
    } finally {
      closeLoading();
    }
  };

  return (
    <KycLayout background={true} backgroundImage={authBg}>
      <div className="px-6">
        <div className="flex justify-end mb-14">
          <img src={flag} alt="Flag" />
        </div>
        <div className="mb-10 text-center">
          <h2 className="text-[24px] mb-3 font-semibold">Proof of Identity</h2>
          <p className="text-sm text-[#585757]">
            Kindly enter your Identification Number (NIN). Government
            regulations require us to verify your identity and keep your account
            safe and secure
          </p>
        </div>
        <form className="space-y-6" onSubmit={handleSubmit(handleVerifyKyc)}>
          {kycInputs().map((input, idx) =>
            input.type === 'file' ? (
              <UploadInput
                key={idx}
                label={input.label}
                setError={setError as any}
                setValue={setValue as any}
                error={errors[getSlug(input.name) as any]?.message as any}
                register={register}
                value={watch(getSlug(input.name))}
                name={input.name}
                setImageUrls={setImage}
                accept=".jpg, .png"
              />
            ) : (
              <>
                <Input
                  key={idx}
                  control={control}
                  name={input.name}
                  color={true}
                  register={register}
                  placeholder={input.placeholder}
                  label={input.label}
                  type={input.type}
                  required={true}
                />
                {errors[input.name] && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors[input.name]?.message?.toString()}
                  </p>
                )}
              </>
            ),
          )}
          <div className="mt-32 mb-4">
            <Button
              label="Continue"
              onClick={handleVerifyKyc}
              loading={isLoading}
              disabled={!image}
            />

            <Button
              label="Do This Later"
              onClick={() => navigate('/dashboard')}
              loading={false}
              outline={true}
              extraClasses="border border-primary"
            />
          </div>
        </form>
      </div>
    </KycLayout>
  );
};

export default Index;
