import {
  Action,
  ThunkAction,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';

import { persistStore } from 'redux-persist';
import registerReducer from './registration/registerSlice';
import countryReducer from './country/countrySlice';
import accountReducer from './account/accountSlice';
import bankReducer from './bank/bankSlice';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';

const appReducer = combineReducers({
  registerStore: registerReducer,
  countryStore: countryReducer,
  accountStore: accountReducer,
  bankStore: bankReducer,
});

const persistedReducer = persistReducer(
  {
    key: 'root',
    storage,
    whitelist: ['registerStore', 'accountStore', 'countryStore'],
  },
  appReducer,
);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
