import locker from 'assets/locker.png';
import React from 'react';
import CardCarousel from 'components/carousel/Atm';

interface Props {
  setState: () => void;
}
const ActivateAccountIntro: React.FC<Props> = ({ setState }) => {
  return (
    <div className="px-20 py-14 bg-[#F5FAFE] h-full w-full">
      <div className="flex flex-col items-center text-center">
        <h1 className="text-6 font-semibold">Activate your account</h1>
        <img src={locker} alt="Locker" className="my-4" />
        <p className="text-sm mb-10">
          Welcome to your choiced bank, click the button below to activate any
          account of your choice.
        </p>
        <button
          onClick={() => setState()}
          className="px-4 py-2 bg-primary text-white rounded w-full"
        >
          Continue
        </button>
      </div>
    </div>
  );
};

const ActivateAccount = () => {
  const [state, setState] = React.useState(1);

  const handleStepper = () => {
    setState((prev: any) => prev + 1);
  };

  const steppers = [
    {
      name: 'Change PIN',
      component: <ActivateAccountIntro setState={handleStepper} />,
    },
    {
      name: 'Carousel',
      component: <CardCarousel />,
    },
  ];

  return <>{steppers[state]?.component}</>;
};

export default ActivateAccount;
