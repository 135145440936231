import { useEffect, useState } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import { populateSelectedCountry } from 'store/country/countrySlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const COUNTRIES = {
  US: 'United States',
  CA: 'Canada',
  NG: 'Nigeria',
} as const;

const FLAGS_TO_STATE = {
  CA: 'CA', // Canada
  US: 'US',
  NG: 'NG',
} as const;

const STATE_TO_FLAGS = {
  CA: 'CA', // Canada
  US: 'US',
  NG: 'NG',
} as const;

type CountryCode = keyof typeof COUNTRIES;

interface Props {
  border?: boolean;
}

const FlagsSelect: React.FC<Props> = ({ border }) => {
  const selectedCountry = useAppSelector(
    (state) => state.countryStore.selectedCountry,
  );
  const [selected, setSelected] = useState<CountryCode>(
    selectedCountry ? (selectedCountry.code as CountryCode) : 'US',
  );
  const { countries } = useAppSelector((state) => state.countryStore);
  const dispatch = useAppDispatch();

  const getSelectedCountry = () => {
    const testSelected = selected === 'CA' ? '+1' : selected;
    return countries?.find((country) => country.code === testSelected);
  };

  useEffect(() => {
    const selectedCountry = getSelectedCountry();
    console.log({ selectedCountry, countries }, 'from flag select');
    dispatch(populateSelectedCountry(selectedCountry));
  }, [selected, dispatch, countries]);

  return (
    <div
      className={`rounded ${
        border === false ? 'border-none' : 'border border-gray-300'
      }`}
    >
      <ReactFlagsSelect
        countries={Object.keys(FLAGS_TO_STATE)}
        selected={STATE_TO_FLAGS[selected as keyof typeof STATE_TO_FLAGS]}
        onSelect={(code) => {
          setSelected(
            FLAGS_TO_STATE[code as keyof typeof FLAGS_TO_STATE] as CountryCode,
          );
        }}
        showSelectedLabel={false}
        showOptionLabel={false}
        selectedSize={16}
        className="menu-flags"
      />
    </div>
  );
};

export default FlagsSelect;
