import { executeGetCurrentDevices } from 'api/user';
// import { Button } from 'components/common/Form';
import { useToast } from 'components/Notifications/Toast';
import useDisclosure from 'hooks/useDisclosure';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import Loader from 'components/Notifications/Loader'; // Import Loader component

const Device = () => {
  const tokenStore = useAppSelector((state) => state.accountStore.tokenStore);
  const userId = useAppSelector((state) => state.accountStore?.account?.id);
  const [devices, setDevices] = useState<any[]>([]);

  const { showToast } = useToast();
  const {
    isOpen: isLoading,
    onOpen: openLoading,
    onClose: closeLoading,
  } = useDisclosure();

  const handleGetDevices = async () => {
    if (!tokenStore) return;
    try {
      openLoading(); // Start loader
      const result = await executeGetCurrentDevices(tokenStore, userId!);
      if (!result.isSuccess)
        throw new Error('Failed to get devices information, Try again');
      setDevices(result?.data);
    } catch (err: any) {
      const isNetwork = err.message.toLowerCase().includes('network');
      showToast(
        isNetwork
          ? "Can't connect to internet, check your network settings"
          : err.message,
        isNetwork ? 'warning' : 'error',
      );
    } finally {
      closeLoading(); // Stop loader
    }
  };

  useEffect(() => {
    handleGetDevices();
  }, []);

  return (
    <div className="px-36 flex flex-row justify-between">
      <div className="flex flex-col items-start max-w-lg space-y-6">
        <h2 className="text-lg font-semibold">Manage Devices</h2>
        <p className="text-xs text-black leading-5 font-medium">
          You are signed in on the devices below. Please check them to ensure
          you recognize each device. If you notice any unfamiliar devices, sign
          out immediately to secure your account.
        </p>

        <div className="flex flex-row items-start justify-between w-full">
          <h3 className="text-[20px] font-semibold text-[#585757]">
            Current Sessions
          </h3>
        </div>

        {/* Render loader while data is loading */}
        {isLoading ? (
          <div className="w-full flex justify-center items-center">
            <Loader /> {/* Show loader */}
          </div>
        ) : (
          <>
            {devices.length > 0 ? (
              <ul className="list-none w-full space-y-4">
                {devices.map((device) => (
                  <li
                    key={device.id}
                    className="border border-gray-200 rounded-lg p-4 flex flex-col"
                  >
                    <div className="flex flex-row justify-between items-center">
                      <div>
                        <p className="text-sm font-medium text-[#585757]">
                          {device.name}
                        </p>
                        <p className="text-xs text-gray-500">
                          Device ID: {device.device_id}
                        </p>
                      </div>
                      <span
                        className={`text-xs font-semibold ${
                          device.type === 'ios'
                            ? 'text-[#007aff]'
                            : 'text-[#a4c639]'
                        }`}
                      >
                        {device.type === 'ios' ? 'iOS' : 'Android'}
                      </span>
                    </div>
                    <p className="text-xs text-gray-400">
                      Last Active:{' '}
                      {device.last_active
                        ? new Date(device.last_active).toLocaleString()
                        : 'Never'}
                    </p>
                    <p className="text-xs text-gray-400">
                      Linked Account: {device.account_id}
                    </p>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-sm text-gray-500">No devices found.</p>
            )}
          </>
        )}
      </div>
      {/* <div className="flex flex-col justify-center items-start">
        <Button
          label="Sign out of all devices"
          extraClasses="px-[35px] py-2 bg-red-500 text-white rounded-md"
        />
      </div> */}
    </div>
  );
};

export default Device;
