// KycFailedPage.tsx
import React from 'react';

interface KycFailedPageProps {
  errorMessage?: string;
}

const KycFailedPage: React.FC<KycFailedPageProps> = ({
  errorMessage = 'Unfortunately, your KYC verification has failed. Please try again.',
}) => {
  return (
    <div className="flex items-center justify-center h-screen bg-red-100">
      <div className="text-center p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold text-red-600 mb-4">
          KYC Verification Failed
        </h2>
        <p className="text-lg text-gray-600">{errorMessage}</p>
        <div className="mt-4">
          <a href="/retry-kyc" className="text-primary font-semibold">
            Retry KYC
          </a>
        </div>
      </div>
    </div>
  );
};

export default KycFailedPage;
