import { useDrawer } from 'contexts/Drawer';
import React from 'react';
import { FaArrowLeft, FaTimes } from 'react-icons/fa';
import { useAppSelector } from 'store/hooks';
import { getRandomColor } from 'utils/helpers';

const Notifications = () => {
  const [state, setState] = React.useState<number | null>(null);
  const { closeDrawer } = useDrawer();
  const notifications = useAppSelector(
    (state) => state.accountStore.notifications,
  );

  return (
    <div className="fixed inset-0 z-[100]">
      {' '}
      {/* Set highest z-index */}
      <div className="px-10 pt-10 bg-[#e6f3fe] h-full overflow-y-scroll">
        {state === null ? (
          <div className="w-full mb-8">
            <div className="flex justify-between w-full">
              <FaArrowLeft onClick={closeDrawer} className="cursor-pointer" />
              <FaTimes onClick={closeDrawer} className="cursor-pointer" />
            </div>
            <h2 className="text-[1.5rem] font-semibold text-[#585757] text-center">
              Notifications
            </h2>
          </div>
        ) : null}

        {state === null && (
          <div
            className={`${state === null ? 'bg-[#F5FAFE]' : ''} w-full p-${
              state === null ? 6 : 0
            } rounded-md`}
          >
            {notifications.map((item, idx) => (
              <div
                className="flex items-center border-b border-gray-300 cursor-pointer my-2"
                onClick={() => setState(idx)}
                key={idx}
              >
                <div className="flex items-center">
                  <div
                    className={`min-h-[80px] min-w-[2px] h-full w-2 rounded-r-[25px]`}
                    style={{ backgroundColor: getRandomColor() }}
                  ></div>
                  <div className="ml-4">
                    <p className="text-xs text-black font-[600] mb-2">
                      {item.message}
                    </p>
                    <p className="text-sm text-[#7F7F7F]">
                      {new Date(item.created_at).toLocaleString()}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {state !== null && (
          <>
            <div className="w-full mb-8">
              <div className="flex justify-between w-full">
                <FaArrowLeft
                  onClick={() => setState(null)}
                  className="cursor-pointer"
                />
                <FaTimes
                  onClick={() => setState(null)}
                  className="cursor-pointer"
                />
              </div>
              <h2 className="text-[1.5rem] font-semibold text-[#585757] text-center">
                Notifications
              </h2>
            </div>
            <div className="flex flex-col justify-between py-6 px-[55px] bg-[#F5FAFE] min-h-[80%]">
              <div>
                <h2 className="text-[20px] font-[600] text-black text-center mb-[24px]">
                  {notifications[state].message}
                </h2>
                <p className="text-sm font-[semibold] text-[#7F7F7F] leading-[21px] mb-[24px]">
                  {new Date(notifications[state].created_at).toLocaleString()}
                </p>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Notifications;
