import AuthLayout from 'layouts/Auth';
import { useForm } from 'react-hook-form';
import authBg from 'assets/form-bg-three.png';
import { Input } from 'components/common/Form';

const SecureWallet = () => {
  const { register } = useForm({
    mode: 'onSubmit',
  });

  return (
    <AuthLayout background={true} backgroundImage={authBg}>
      <div className="flex justify-between items-center mb-14">
        <h2 className="text-2xl font-semibold">Set your space</h2>
      </div>
      <div className="space-y-6">
        <Input
          label={'password'}
          type={'password'}
          color={true}
          placeholder={'*******'}
          register={register}
          required
          name={'password'}
        />
        <Input
          name="confirm"
          type="password"
          placeholder="*******"
          required
          color={true}
          label="Confirm Password"
          register={register}
        />
        <button className="mt-20 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300 font-normal">
          Continue
        </button>
      </div>
    </AuthLayout>
  );
};

export default SecureWallet;
