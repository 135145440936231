import { executeRequestOtp } from 'api/settings/security';
import { useToast } from 'components/Notifications/Toast';
import React from 'react';
import { useAppSelector } from 'store/hooks';

const useRequestOTP = (): {
  handleRequest: () => Promise<void>;
  loadingData: boolean;
} => {
  const [loadingData, setLoading] = React.useState(false);
  const token = useAppSelector((state) => state.accountStore.tokenStore);
  const email = useAppSelector((state) => state.accountStore.account?.email);

  const { showToast } = useToast();

  const handleRequest = async () => {
    if (!email) {
      console.log('No email provided');
      return;
    }
    try {
      setLoading(true);
      const response = await executeRequestOtp({ email }, token);
      if (response.status === 'error') throw new Error(response.message);
      showToast(response.message, 'success');
    } catch (e: any) {
      console.log('ERROR HOOKKKK: ', e.message);
    } finally {
      setLoading(false);
    }
  };

  return { handleRequest, loadingData };
};

export default useRequestOTP;
