import React from 'react';
import moneyCard from 'assets/moneycard.png';

interface Props {
  setActiveStep: (no: any) => void;
}

const CardSelected: React.FC<Props> = () => {
  return (
    <div className="w-[374px] pt-[84px] pb-[73px] px-[24px] shadow-lg">
      <div className="flex flex-col items-center">
        <div className="text-center mb-6">
          <h2 className="text-lg font-semibold">Virtual Cards</h2>
        </div>

        <div className="text-center">
          <img src={moneyCard} alt="3d money card" className="mx-auto" />
          <h2 className="text-lg font-semibold my-4">
            Your Virtual Card is <br /> on the way!
          </h2>
          <p className="text-sm text-gray-500">
            Your account will be charged with the amount used in collecting the
            virtual card for the individual wallet.
          </p>
        </div>

        <div className="flex flex-col space-y-4 w-full mt-4">
          <button className="bg-blue-500 text-white py-2 rounded-md">
            Proceed
          </button>
          <button className="border border-blue-500 text-blue-500 py-2 rounded-md">
            Cancel Request
          </button>
        </div>
        <button className="mt-[160px] py-4 bg-blue-500 text-white rounded-md w-full">
          Request card
        </button>
      </div>
    </div>
  );
};

export default CardSelected;
