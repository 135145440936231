/* eslint-disable */
import { useEffect, useState } from 'react';
import AuthLayout from 'layouts/Auth';
import { useNavigate } from 'react-router-dom';
import authBg from 'assets/secure-successful.png';
import lock from 'assets/padlock.png';
import { Button } from 'components/common/Form';
import useDisclosure from 'hooks/useDisclosure';
import { useAppSelector } from 'store/hooks';
import WaitingPage from './Waiting';
import KycFailedPage from './Failed';
import KycPassedPage from './Completed';

import {
  executeCreateCybridIdentityVerification,
  executeGetIdentityVerification,
} from 'api/kyc';
import { useToast } from 'components/Notifications/Toast';
import Persona from 'persona';
import { WalletData } from 'types/data';

const IdentityVerification = () => {
  const {
    onOpen: openLoading,
    onClose: closeLoading,
    isOpen: isLoading,
  } = useDisclosure();
  const [personaInquiryId, setPersonaInquiryId] = useState<string | null>(null);
  const [state, setState] = useState<any>(null);
  const token = useAppSelector((state) => state.accountStore.tokenStore);
  const user = useAppSelector((state) => state.accountStore.account);
  const wallets = useAppSelector((state) => state.accountStore.wallets);

  const walletsArray: WalletData[] = Object.values(wallets);
  const usdWallet = walletsArray.find(
    (wallet: WalletData) => wallet.currency === 'USD',
  );

  if (
    usdWallet!.identity_status === 'reviewing' ||
    (state?.state === 'pending' && state?.persona_state === 'completed')
  ) {
    return <WaitingPage />;
  }

  if (usdWallet!.identity_outcome === 'failed') {
    return (
      <KycFailedPage errorMessage="Your verification has not met the requirements." />
    );
  }

  if (usdWallet!.identity_outcome === 'passed') {
    return <KycPassedPage wallet={usdWallet!} />;
  }

  const { showToast } = useToast();
  const selectedWallet = useAppSelector(
    (state) => state.accountStore.selectedWallet,
  );
  const navigate = useNavigate();

  console.log({ usdWallet: usdWallet });

  // Fetch the persona_inquiry_id when the component mounts
  useEffect(() => {
    if (selectedWallet && user) {
      const fetchIdentityVerification = async () => {
        try {
          openLoading();
          const transactionResult = await executeGetIdentityVerification(
            selectedWallet.currency,
            user!.id,
            token,
          );

          if (transactionResult.data.persona_inquiry_id) {
            setState(transactionResult.data);
            setPersonaInquiryId(transactionResult.data.persona_inquiry_id);
            showToast('Persona inquiry ID retrieved', 'success');
            handleOpenPersona(transactionResult.data.persona_inquiry_id);
          }
        } catch (error: any) {
          console.error({ error });
          // showToast(error.message, 'error');
        } finally {
          closeLoading();
        }
      };

      fetchIdentityVerification();
    }
  }, [selectedWallet, user, token]);

  const handleIdentityVerification = async () => {
    if (!usdWallet) {
      showToast('No wallet selected, please return to dashboard', 'error');
      return;
    }

    try {
      openLoading();

      if (usdWallet.identity_status === 'waiting') {
        // Fetch identity verification directly and open Persona
        const transactionResult = await executeGetIdentityVerification(
          usdWallet.currency,
          user!.id,
          token,
        );

        if (transactionResult.data.persona_inquiry_id) {
          showToast('Persona inquiry ID retrieved', 'success');
          setPersonaInquiryId(transactionResult.data.persona_inquiry_id); // Set the inquiry ID in the state
          handleOpenPersona(transactionResult.data.persona_inquiry_id);
          return;
        } else {
          showToast('Failed to fetch Identity Verification', 'error');
        }
      }

      // If persona_inquiry_id exists, skip creating a new identity verification
      if (personaInquiryId) {
        handleOpenPersona(personaInquiryId);
        return;
      }

      // Call the verification endpoint if persona_inquiry_id is not available
      const payload = { customer_guid: usdWallet.customer_id! };
      const result = await executeCreateCybridIdentityVerification(
        payload,
        token,
      );

      if (!result.isSuccess) {
        throw new Error(result.message);
      }

      showToast(result.message, 'success');

      // Start retrying to fetch persona_inquiry_id by calling `executeGetTransaction`
      const retryGetTransaction = async () => {
        while (true) {
          const transactionResult = await executeGetIdentityVerification(
            usdWallet.currency,
            user!.id,
            token,
          );

          if (transactionResult.data.persona_inquiry_id) {
            showToast('Persona inquiry ID retrieved', 'success');
            setPersonaInquiryId(transactionResult.data.persona_inquiry_id); // Set the inquiry ID in the state
            handleOpenPersona(transactionResult.data.persona_inquiry_id);
            break;
          }

          // If persona_inquiry_id is still null, wait for 2 seconds and retry
          await new Promise((resolve) => setTimeout(resolve, 2000));
        }
      };

      await retryGetTransaction();
    } catch (error: any) {
      showToast(error.message, 'error');
    } finally {
      closeLoading();
    }
  };

  const handleGetIdentityVerification = async () => {
    await executeGetIdentityVerification(usdWallet!.currency, user!.id, token);
  };

  const handleOpenPersona = (inquiryId: string) => {
    const client: any = new Persona.Client({
      // templateId: 'itmpl_FbSqkD252LJanpwjxA7woFdhjtpj',
      // referenceId: inquiryId,
      inquiryId: inquiryId,
      // environmentId: 'sandbox',
      onReady: () => client.open(),
      onComplete: ({ inquiryId }) => {
        console.log(`Sending finished inquiry ${inquiryId} to backend`);
        handleGetIdentityVerification();
      },
      onCancel: () => console.log('onCancel'),
      onError: (error) => console.log(error),
    });
  };

  return (
    <AuthLayout background={true} backgroundImage={authBg}>
      <img src={lock} className="mx-auto" alt="Padlock" />
      <div className="space-y-4 px-6">
        <h2 className="text-2xl font-semibold text-center">
          Account Registered Successfully
        </h2>
        <p className="text-sm text-center text-gray-500">
          Your account has been registered successfully. Complete KYC to start
          sending funds.
        </p>

        <Button
          label="Complete KYC"
          onClick={handleIdentityVerification}
          loading={isLoading}
        />

        <Button
          label="Do This Later"
          onClick={() => navigate('/dashboard')}
          loading={false}
          outline={true}
          extraClasses="border border-primary"
        />
      </div>
    </AuthLayout>
  );
};

export default IdentityVerification;
