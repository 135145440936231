import React, { useState } from 'react';

type Setup2FAProps = {
  qrCode: string | null;
  onVerify: (code: string) => void;
};

const Setup2FA: React.FC<Setup2FAProps> = ({ qrCode }) => {
  const [isVerified, setIsVerified] = useState<boolean>(false);

  if (!qrCode) return null;

  console.log({ setIsVerified });

  return (
    <div className="max-w-lg mx-auto p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4 text-center">
        Setup Two-Factor Authentication
      </h2>

      {!isVerified ? (
        <div className="space-y-6 flex flex-col items-center">
          <p className="text-gray-600">
            To secure your account, set up 2FA by scanning the QR code with your
            authenticator app or enter the secret key manually.
          </p>

          <img src={qrCode} alt="QR Code" style={{ width: 180, height: 180 }} />
        </div>
      ) : (
        <div className="text-center">
          <h3 className="text-xl font-semibold text-green-600">
            2FA Setup Complete!
          </h3>
          <p className="text-gray-600">
            Your account is now secured with two-factor authentication.
          </p>
        </div>
      )}
    </div>
  );
};

export default Setup2FA;
