import { enqueueSnackbar } from 'notistack';

export const useToast = () => {
  const showToast = (
    message: string,
    variant: 'success' | 'error' | 'warning',
  ) => {
    enqueueSnackbar(message, { variant });
  };

  return {
    showToast,
  };
};
