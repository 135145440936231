import React from 'react';
import { UseFormRegister, UseFormWatch } from 'react-hook-form';
import closeIcon from 'assets/vector.png';
import { Button, Input } from 'components/common/Form';

interface FormProps {
  register: UseFormRegister<SecuritySettingsData>;
  watch: UseFormWatch<SecuritySettingsData>;
  handleChangePassword: () => void;
}

const PasswordForm: React.FC<FormProps> = ({
  register,
  watch,
  handleChangePassword,
}) => {
  const password = watch('new_password');

  const checkPasswordStrength = () => {
    const requirements = [
      { label: '1 Small Letter', regex: /[a-z]/ },
      { label: '1 Capital Letter', regex: /[A-Z]/ },
      { label: '1 Number', regex: /[0-9]/ },
      {
        label: '1 Special Character',
        regex: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/,
      },
      { label: '8 Characters', regex: /.{8,}/ },
    ];

    if (!password) return;
    return requirements.map((req) => ({
      label: req.label,
      met: req.regex.test(password),
    }));
  };

  return (
    <div className="w-[375px] px-6 bg-white py-2 space-y-6 pb-[50px]">
      <h2 className="text-2xl mb-6 text-[#000] font-semibold leading-10 mb-2.5">
        New Password
      </h2>
      <p className="text-sm text-[#949596]">
        Input a safe password that can stand the test of fraud
      </p>
      <div className="space-y-6">
        <Input
          register={register}
          name={'current_password'}
          placeholder="*****"
          color={true}
          label={'Current Password'}
          type={'password'}
          rules={{
            required: 'Please select a valid password',
            minLength: {
              value: 8,
              message: 'Please select a valid password',
            },
          }}
        />
        <Input
          register={register}
          name={'new_password'}
          placeholder="*****"
          label={'Password'}
          type={'password'}
          color={true}
          rules={{
            required: 'Please select a valid password',
            minLength: {
              value: 8,
              message: 'Please select a valid password',
            },
          }}
        />
        <div className="grid grid-cols-2 gap-y-1.25">
          {checkPasswordStrength()?.map(
            (req, idx) =>
              !req.met && (
                <div key={idx} className="flex items-center">
                  <img src={closeIcon} alt="Close Icon" />
                  <p className="text-sm text-gray-400 ml-1.5">{req.label}</p>
                </div>
              ),
          )}
        </div>
        <Input
          register={register}
          name={'confirm_password'}
          placeholder="*****"
          label={'Confirm Password'}
          type={'password'}
          color={true}
          rules={{
            validate: (value: string) => {
              return value !== password ? 'Passwords do not match' : undefined;
            },
          }}
        />
      </div>
      <Button
        label="Log in again"
        onClick={handleChangePassword}
        extraClasses="mt-[100px]"
      />
    </div>
  );
};

export default PasswordForm;
