import {
  GET_COUNTRIES_ENDPOINT,
  GET_TRANSFER_COUNTRIES_ENDPOINT,
} from './index';
import { default as axios } from 'config/axios';

export const executeGetCountries = async (): Promise<ResponseDataType> => {
  try {
    const response = await axios().get(GET_COUNTRIES_ENDPOINT, {
      headers: {
        'Content-Type': 'application/json',
        whspay: '&sw22mewjsd4t343643?',
      },
    });
    return response.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: error };
  }
};

export const executeGetTransferCountries = async (
  token: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios().get(GET_TRANSFER_COUNTRIES_ENDPOINT, {
      headers: {
        'Content-Type': 'application/json',
        whspay: '&sw22mewjsd4t343643?',
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: error };
  }
};
