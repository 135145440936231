import { app_key } from 'api';
import axios, {
  AxiosInstance,
  InternalAxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import { AES, enc, lib, SHA256, mode, pad } from 'crypto-js';

interface IEncryptedResponse {
  iv: string;
  encryptedValue: string;
}

interface SecurityDto {
  iv: string;
  encryptedValue: string;
}

const secret = process.env.REACT_APP_SECRET_KEY!;

// Function to encrypt data
const encryptData = (data: any): IEncryptedResponse => {
  const iv = lib.WordArray.random(16);
  const key = SHA256(secret);

  const value = enc.Utf8.parse(JSON.stringify(data));
  const encrypted = AES.encrypt(value, key, {
    iv: iv,
    mode: mode.CBC,
    padding: pad.Pkcs7,
  });

  return {
    iv: enc.Hex.stringify(iv),
    encryptedValue: encrypted.ciphertext.toString(),
  };
};

// Function to decrypt data
const decryptData = (decryptDto: SecurityDto): any => {
  const { encryptedValue, iv } = decryptDto;
  const key = SHA256(secret);

  const ciphertext = enc.Hex.parse(encryptedValue);
  const ivWordArray = enc.Hex.parse(iv);

  const decrypted = AES.decrypt(ciphertext.toString(enc.Base64), key, {
    iv: ivWordArray,
    mode: mode.CBC,
    padding: pad.Pkcs7,
  });

  try {
    const decryptedStr = decrypted.toString(enc.Utf8);
    return JSON.parse(decryptedStr);
  } catch (error) {
    console.error('Decryption failed:', error);
    return undefined;
  }
};

// Function to create Axios instance
const createAxiosInstance = (
  token?: string,
  fileType?: boolean,
): AxiosInstance => {
  const headers: Record<string, string> = {
    'Content-Type': fileType ? 'multipart/form-data' : 'application/json',
    whspay: app_key,
  };

  if (token) {
    headers.whcookie = `whspay=${token}`;
  }

  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers,
    withCredentials: true,
  });

  if (!fileType) {
    instance.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        if (config.data) {
          config.data = encryptData(config.data); // Encrypt request data
        }
        return config;
      },
      (error) => {
        console.log({ error }, 'from encryopt');
        return Promise.reject(error);
      },
    );
  }

  instance.interceptors.response.use(
    (response: AxiosResponse) => {
      if (response.data) {
        const decryptedData = decryptData(response.data);
        if (
          typeof decryptedData === 'object' &&
          decryptedData !== null &&
          'result' in decryptedData
        ) {
          try {
            response.data = decryptedData.result;
          } catch (error) {
            console.error('Error parsing inner JSON:', error);
            response.data = decryptedData; // Fallback to decrypted data if parsing fails
          }
        } else {
          response.data = decryptedData;
        }
      }
      return response;
    },
    (error) => {
      const decryptedError = decryptData(error?.response?.data);
      return Promise.reject(decryptedError);
    },
  );

  return instance;
};

export default createAxiosInstance;
