import React from 'react';
import Summary from './Summary';
import InputPin from './InputPin';
import Successful from './Successful';
import Receipt from './Receipt';
import Countries from './Countries';
import { ArrowBackIcon, CloseIcon } from '@chakra-ui/icons';
import { Button, FormNavigator } from 'components/common/Form';
import useDisclosure from 'hooks/useDisclosure';
import flag from 'assets/flags.svg';
import cad from 'assets/canada.svg';
import usa from 'assets/usa.svg';
import {
  executeGetBanksByCountryCode,
  executeInternationalTransfer,
} from 'api/bank';
import { useAppSelector } from 'store/hooks';
import Recipient from './Recipient';
import { useToast } from 'components/Notifications/Toast';
import { IntlTransferData, Transaction, WalletData } from 'types/data';
import { useDrawer } from 'contexts/Drawer';
import { FiArrowRight } from 'react-icons/fi';
import Beneficiaries from './Beneficiaries';

const International = () => {
  const [state, setState] = React.useState(0);

  const [selectedCountry, setSelectedCountry] = React.useState<any | null>(
    null,
  );
  const [selectedCounterParty, setSelectedCounterParty] = React.useState<
    any | null
  >(null);

  const [data, setData] = React.useState<any | null>(null);
  const [paymentMethod, setPaymentMethod] = React.useState<string | null>(null);
  const [showRecipientInfo, setShowRecipientInfo] = React.useState(false);
  const token = useAppSelector((state) => state.accountStore.tokenStore);
  const [paymentData, setPaymentData] = React.useState<Transaction | null>(
    null,
  );
  const [selectedWallet, setSelectedWallet] = React.useState<any | null>(null);
  const { showToast } = useToast();

  const [pin, setPin] = React.useState('');
  const wallets = useAppSelector((state) => state.accountStore.wallets);
  const { closeDrawer } = useDrawer();

  const {
    isOpen: isLoading,
    onOpen: openLoading,
    onClose: closeLoading,
  } = useDisclosure();

  const getFlagRef = (currency: string) => {
    return currency === 'NGN' ? flag : currency === 'CAD' ? cad : usa;
  };

  console.log({ selectedCounterParty });
  const {
    isOpen: isSubmitting,
    onOpen: openSubmit,
    onClose: closeSubmit,
  } = useDisclosure();

  const fetchCountryBanks = async () => {
    try {
      if (!selectedCountry) return;
      openLoading();
      const result = await executeGetBanksByCountryCode(
        token,
        selectedCountry?.value?.code,
      );
      if (!result.isSuccess) throw new Error(result.message);
      setData(result.data);
    } catch (error: any) {
      console.log('error', error);
    } finally {
      closeLoading();
    }
  };

  React.useEffect(() => {
    fetchCountryBanks();
  }, [selectedCountry]);

  const handlePaymentMethodChange = (method: string) => {
    setPaymentMethod(method);
    setShowRecipientInfo(true);
  };

  const handleNextStep = () => {
    if (selectedCountry?.value?.paymentMethods.length > 1 && !paymentMethod) {
      alert('Please select a payment method.');
      return;
    }
    if (showRecipientInfo) {
      setState((prev) => prev + 1);
    } else {
      setShowRecipientInfo(true); // Display recipient info before moving to the next state
    }
  };

  console.log({ selectedCountry });
  const handleTransfer = async () => {
    console.log({ paymentData, selectedWallet });
    if (!paymentData || !selectedWallet) return;
    try {
      openSubmit();
      const payload: IntlTransferData = {
        amount: paymentData.amount,
        accountId: paymentData.accountNumber,
        reason: paymentData.narration,
        walletId: selectedWallet.id,
        counter_party_id: paymentData.counterId,
        currency: selectedWallet.currency,
        dest_currency: selectedCountry?.value?.currencies[0],
        pin,
      };
      const result = await executeInternationalTransfer(payload, token);
      if (!result.isSuccess) throw new Error(result.message);
      showToast(result.message, 'success');
      setState((prev: number) => prev + 1);
      return result;
    } catch (err: any) {
      const isNetwork = err.message.toLowerCase().includes('network');
      showToast(
        isNetwork
          ? "Can't connect to internet, check your network settings"
          : err.message,
        isNetwork ? 'warning' : 'error',
      );
    } finally {
      closeSubmit();
    }
  };

  const handleWalletSelect = (wallet: WalletData) => {
    setSelectedWallet({
      id: wallet.id,
      currency: wallet.currency,
      customer_id: wallet.customer_id,
    });
    setState(1);
  };

  return (
    <>
      {state === 0 && (
        <>
          <FormNavigator
            setState={setState}
            state={1}
            closeDrawer={closeDrawer}
          />
          <h2 className="text-sm text-center text-gray-500 mb-8">
            Select Wallet
          </h2>
          {wallets && Object.values(wallets).length > 0 ? (
            <div className="bg-[#F5FAFE] p-6">
              {Object.values(wallets).map((wallet: any) => (
                <div
                  key={wallet.id}
                  className={`flex items-center justify-between p-4 border-2 rounded-lg cursor-pointer transition-all duration-200 mb-6`}
                  onClick={() => handleWalletSelect(wallet)}
                >
                  <div className="flex items-center space-x-4">
                    <div className="bg-white p-2">
                      <img
                        src={getFlagRef(wallet.currency)}
                        alt={`${wallet.currency} flag`}
                        className="w-8 h-8"
                      />
                    </div>
                    <span className={`font-medium`}>
                      {wallet.currency} Account
                    </span>
                  </div>
                  <span className={`font-semibold`}>
                    {wallet.currency}{' '}
                    {new Intl.NumberFormat().format(wallet.balance / 100)}
                  </span>
                  <span className="ml-1.5">
                    <FiArrowRight />
                  </span>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-gray-500">No wallets available</p>
          )}
        </>
      )}
      {state === 1 && (
        <>
          <div className="flex justify-between w-full mb-12">
            <ArrowBackIcon className="cursor-pointer" onClick={closeDrawer} />
            <CloseIcon className="cursor-pointer" onClick={closeDrawer} />
          </div>
          <h2 className="text-sm text-center text-gray-500">Choose country</h2>
          <Countries
            setState={setState}
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
          />
        </>
      )}

      {state === 2 && (
        <>
          <div className="flex justify-between w-full mb-12">
            <ArrowBackIcon className="cursor-pointer" onClick={closeDrawer} />
            <CloseIcon className="cursor-pointer" onClick={closeDrawer} />
          </div>
          <h2 className="text-sm text-center text-gray-500">
            All Beneficiaries
          </h2>
          <Beneficiaries
            setState={setState}
            setSelectedCounterParty={setSelectedCounterParty}
            selectedCounterParty={selectedCounterParty}
          />
        </>
      )}

      {state === 3 && (
        <>
          {selectedCountry.value.paymentMethods.length > 1 &&
          !showRecipientInfo ? (
            <>
              <div className="space-y-4">
                <h3 className="text-lg">Select Payment Method</h3>
                {selectedCountry.value.paymentMethods.map((method: string) => (
                  <label key={method} className="flex items-center space-x-2">
                    <input
                      type="radio"
                      name="paymentMethod"
                      value={method}
                      onChange={() => handlePaymentMethodChange(method)}
                      checked={paymentMethod === method}
                    />
                    <span>{method}</span>
                  </label>
                ))}
              </div>
              <Button
                label="Next"
                onClick={handleNextStep}
                extraClasses="mb-[2px]"
              />
            </>
          ) : (
            <>
              <FormNavigator
                setState={setState}
                state={0}
                closeDrawer={closeDrawer}
              />
              <h2 className="text-center text-[1.5rem] font-semibold text-[#585757]">
                Recipient information
              </h2>
              {isLoading ? (
                <p>Loading...</p>
              ) : data ? (
                <>
                  <Recipient
                    paymentMethod={paymentMethod}
                    data={data}
                    selectedCountry={selectedCountry}
                    selectedWallet={selectedWallet}
                    handleNextStep={handleNextStep}
                    setPaymentData={setPaymentData}
                    paymentData={paymentData}
                    selectedCounterParty={selectedCounterParty}
                  />
                </>
              ) : (
                // </>
                <p>No data available</p>
              )}
            </>
          )}
        </>
      )}

      {state === 4 && (
        <>
          <FormNavigator
            setState={setState}
            state={2}
            closeDrawer={closeDrawer}
          />
          <h2 className="text-sm text-center text-gray-500">Summary</h2>
          <Summary formData={paymentData} />
          <Button
            label="Confirm"
            onClick={() => setState((prev) => prev + 1)}
            extraClasses="mb-[2px]"
          />
        </>
      )}

      {state === 5 && (
        <>
          <FormNavigator
            setState={setState}
            state={2}
            closeDrawer={closeDrawer}
          />
          <h2 className="text-sm text-center text-gray-500 mb-8">Summary</h2>
          <InputPin
            setState={setState}
            isLoading={isSubmitting}
            setPin={setPin}
            handleTransfer={handleTransfer}
          />
        </>
      )}

      {state === 6 && (
        <>
          <FormNavigator
            setState={setState}
            state={3}
            closeDrawer={closeDrawer}
          />
          <Successful setState={setState} />
        </>
      )}

      {state === 7 && (
        <>
          <FormNavigator
            setState={setState}
            state={4}
            closeDrawer={closeDrawer}
          />
          <Receipt data={paymentData} />
          <div className="flex w-full mt-6">
            <Button label="Download" onClick={() => setState(6)} />
            {/* <button className="border border-red-500 text-red-500 bg-white py-2 px-4 rounded">
              Report
            </button> */}
          </div>
        </>
      )}
    </>
  );
};

export default International;
