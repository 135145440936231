import { Navigate } from 'react-router-dom';
import Login from 'pages/auth/Login';
import Dashboard from 'pages/dashboard';
import Transactions from 'pages/transactions';
import BaseSettings from 'pages/settings';
import ProfileSettings from 'pages/settings/profile/index';
import Profile from 'pages/profile';
import Security from 'pages/settings/security';
import Notifications from 'pages/settings/notifications';
import Wallet from 'pages/settings/wallet';
import KYC from 'pages/settings/kyc';
import Device from 'pages/settings/device';
import Cards from 'pages/settings/cards';
import SecureWallet from 'pages/auth/registration/SecureWallet';
import ChangePassword from 'pages/settings/security/ChangePassword';
import NewPassword from 'pages/auth/reset-password';
import ForgotPassword from 'pages/auth/reset-password';
import SecureAccountSuccessful from 'pages/auth/registration/SecureAccountSuccessful';
import Registration from 'pages/auth/registration';
import Kyc from 'pages/kyc';
import Documents from 'pages/kyc/Documents';
import BvnVerification from 'pages/kyc/BvnVerification';
import SSNVericiation from 'pages/kyc/SSNVerification';
import GetWallet from 'pages/kyc/GetWallet';
import IdentityVerification from 'pages/kyc/IdentityVerification';
import { Route } from 'types/data';
import Verify2FA from 'pages/auth/2FA';
import RegisterCardUser from 'pages/cardForm/RegisterUser';
import CreateVirtualCard from 'pages/cardForm/CreateVirtualCard';
import ManageVirtualCards from 'pages/settings/ManageVirtualCards';

const routes: Route[] = [
  {
    path: '/login',
    element: <Login />,
    name: 'Login',
    protected: false,
  },
  {
    path: '/2fa',
    element: <Verify2FA />,
    name: 'Two FA',
    protected: true,
  },
  {
    path: '/register',
    element: <Registration />,
    name: 'Register',
    protected: false,
  },

  {
    path: '/dashboard',
    element: <Dashboard />,
    name: 'Dashboard',
    protected: true,
  },
  {
    path: '/transactions/:id',
    element: <Transactions />,
    name: 'transactions',
    protected: true,
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
    name: 'ForgotPassword',
    protected: false,
  },

  {
    path: '/reset-password',
    element: <NewPassword />,
    name: 'New Password',
    protected: false,
  },

  {
    path: '/change-password/:email',
    element: <ChangePassword />,
    name: 'ChangePassword',
    protected: true,
  },

  {
    path: '/secure-wallet',
    element: <SecureWallet />,
    name: 'ValidateBVN',
    protected: true,
  },

  {
    path: '/secure-account-successful',
    element: <SecureAccountSuccessful />,
    name: 'Address',
    protected: true,
  },
  {
    path: '/verify-bvn',
    element: <BvnVerification />,
    name: 'Kyc',
    protected: true,
  },
  {
    path: '/verify-ssn',
    element: <SSNVericiation />,
    name: 'verify SSN',
    protected: true,
  },
  {
    path: '/register-card-user',
    element: <RegisterCardUser />,
    name: 'Kyc',
    protected: true,
  },
  {
    path: '/create-virtual-card',
    element: <CreateVirtualCard />,
    name: 'Kyc',
    protected: true,
  },
  {
    path: '/verify-identity',
    element: <IdentityVerification />,
    name: 'Verify Identity',
    protected: true,
  },
  {
    path: '/kyc',
    element: <Kyc />,
    name: 'Kyc',
    protected: true,
  },
  {
    path: '/kyc/upload-documents',
    element: <Documents />,
    name: 'Kyc Documents',
    protected: true,
  },
  {
    path: '/create-account',
    element: <GetWallet />,
    name: 'Create Account',
    protected: true,
  },
  {
    path: '/profile',
    element: <Profile />,
    name: 'Profile',
    protected: true,
  },
  {
    path: '/settings',
    element: <BaseSettings />,
    name: 'settings',
    protected: true,
    children: [
      {
        path: 'profile',
        element: <ProfileSettings />,
        name: 'User Panel',
        protected: true,
      },
      {
        path: 'security',
        element: <Security />,
        name: 'User Panel',
        protected: true,
      },
      {
        path: 'notifications',
        element: <Notifications />,
        name: 'User Panel',
        protected: true,
      },
      {
        path: 'wallets', // Updated path from "wallets" to "wallet"
        element: <Wallet />,
        name: 'User Panel',
        protected: true,
      },
      {
        path: 'kyc',
        element: <KYC />,
        name: 'Kyc Verification',
        protected: true,
      },
      {
        path: 'device',
        element: <Device />,
        name: 'User Panel',
        protected: true,
      },
      {
        path: 'card',
        element: <Cards />,
        name: 'User Panel',
        protected: true,
      },
      {
        path: 'manage-cards',
        element: <ManageVirtualCards />,
        name: 'Manage Virtual Cards',
        protected: true,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to={'/login'} replace />,
    name: 'Notfound',
    protected: false,
  },
];

export default routes;
