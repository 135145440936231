import DashboardLayout from 'layouts/Dashboard';
import { profileInputs } from 'utils/data';
// import camera from 'assets/camera.svg';
import { Button, Input } from 'components/common/Form';
import { useForm } from 'react-hook-form';
import { useAppSelector } from 'store/hooks';
import { AccountData } from 'types/data';
import useDisclosure from 'hooks/useDisclosure';
import { executeUpdateProfile } from 'api/user';
import { useToast } from 'components/Notifications/Toast';

const Index = () => {
  const user = useAppSelector((state) => state.accountStore.account);
  const token = useAppSelector((state) => state.accountStore.tokenStore);
  const { trigger, register, handleSubmit } = useForm<AccountData>({
    mode: 'onSubmit',
  });
  const {
    isOpen: isLoading,
    onOpen: openLoading,
    onClose: closeLoading,
  } = useDisclosure();

  const { showToast } = useToast();

  const handleUpdateProfile = async (data: AccountData) => {
    if (!(await trigger())) return false;
    try {
      openLoading();

      const result = await executeUpdateProfile(data, token);
      if (!result.isSuccess) throw new Error(result.message);
      showToast(result.message, 'success');
    } catch (error: any) {
      showToast(error.message, 'error');
    } finally {
      closeLoading();
    }
  };

  return (
    <DashboardLayout title={''}>
      <div className="flex flex-row justify-between px-24 w-11/12">
        <div className="max-w-sm mx-auto flex flex-col space-y-6">
          <p className="text-center text-xs text-black">
            Manage your personal information, and control which information
            other people see and apps may access.
          </p>
          <div className="flex flex-col items-center space-y-4">
            <h2 className="text-xl text-blue-500">
              {user?.first_name?.charAt(0)} {user?.last_name?.charAt(0)}
            </h2>{' '}
            {/* <img src={camera} alt="camera icon" /> */}
            <p className="text-lg font-bold text-black">
              {user?.first_name} {user?.last_name}
            </p>{' '}
            <p className="text-sm text-gray-500">USERID: {user?.id}</p>
            {/* <button className="border border-blue-600 text-blue-600 rounded px-3 py-1">
              QR Code
            </button> */}
          </div>

          {/* Form */}
          <form
            className="space-y-6 w-full"
            onSubmit={handleSubmit(handleUpdateProfile)}
          >
            <div>
              {profileInputs().map((input, idx) => (
                <Input
                  key={idx}
                  type={input.type}
                  color={true}
                  name={input.name}
                  placeholder={input.placeholder}
                  label={input.label}
                  register={register}
                  defaultValue={String(
                    user?.[input.name as keyof AccountData] ?? '',
                  )}
                />
              ))}
            </div>

            {/* Button aligned right within the form */}
            <div className="flex justify-end">
              <Button label="Update Profile" loading={isLoading} />
            </div>
          </form>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Index;
