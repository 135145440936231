import React from 'react';

interface VirtualCardProps {
  cardNumber: string;
  cardHolderName: string;
  expiryDate: string;
  country: string; // Add country as a prop
}

const countryColors: { [key: string]: string } = {
  USA: 'bg-gradient-to-r from-blue-500 to-green-500',
  Nigeria: 'bg-gradient-to-r from-green-500 to-yellow-500',
  UK: 'bg-gradient-to-r from-red-500 to-blue-500',
  Canada: 'bg-gradient-to-r from-pink-500 to-purple-500',
  default: 'bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500',
};

const VirtualCard: React.FC<VirtualCardProps> = ({
  cardNumber,
  cardHolderName,
  expiryDate,
  country,
}) => {
  const cardColor = countryColors[country] || countryColors['default']; // Choose color based on country

  return (
    <div
      className={`w-full max-w-sm ${cardColor} text-white p-6 rounded-lg shadow-lg`}
    >
      <h3 className="text-lg font-bold mb-4">Virtual Card</h3>
      <p className="text-xl font-mono tracking-wider">{cardNumber}</p>
      <div className="flex justify-between mt-4">
        <span>{cardHolderName}</span>
        <span>{expiryDate}</span>
      </div>
    </div>
  );
};

export default VirtualCard;
