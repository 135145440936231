const BASE_URL = process.env.REACT_APP_API_URL;
export const app_key = process.env.REACT_APP_KEY!;

// https://whspay-backend-prod-qefxjgz2ia-uc.a.run.app/api/v1/notification

export const LOGIN_ENDPOINT = BASE_URL + '/account';
export const VALIDATE_EMAIL = BASE_URL + '/account/send-verification-otp';
export const VALIDATE_BVN = (userId: string) =>
  BASE_URL + `/wallet/verify-bvn/${userId}`;

export const CREATE_CYBRID_ACCOUNT = (userId: string) =>
  BASE_URL + `/wallet/cybrid/customers/${userId}`;

export const REGISTER_VIRTUAL_CARD_USER = () =>
  BASE_URL + `/virtual-card/user/register`;

export const CREATE_VIRTUAL_CARD = () => BASE_URL + `/virtual-card/create`;

export const FREEZE_VIRTUAL_CARD = (cardId: string, isBlocked: boolean) =>
  BASE_URL + `/virtual-card/${isBlocked ? 'unfreeze' : 'freeze'}/${cardId}`;

export const VERIFY_CYBRID_IDENTITY =
  BASE_URL + `/wallet/cybrid/identity-verification`;

export const GET_IDENTITY_VERIFICATION = (currency: string, userId: string) =>
  BASE_URL + `/wallet/cybrid/identity-verification/${currency}/${userId}`;

export const VERIFY_EMAIL_PHONE = BASE_URL + '/account/otp-verification';
export const CREATE_ACCOUNT_ENDPOINT = BASE_URL + '/account';
export const GET_COUNTRIES_ENDPOINT = BASE_URL + '/country';
export const GET_TRANSFER_COUNTRIES_ENDPOINT = BASE_URL + '/countries';

export const RESET_PASSWORD_ENDPOINT =
  BASE_URL + '/setting/create-new-password';

// KYC
export const VERIFY_KYC_ENDPOINT = BASE_URL + '/account/verify-kyc-and-face';
export const UPLOAD_IMAGE_ENDPOINT = BASE_URL + '/setting/upload';
export const CREATE_WALLET_ACCOUNT_ENDPOINT = (userId: string) =>
  BASE_URL + `/wallet/brails/create-account/${userId}`;

export const CREATE_CYBRID_WALLET_ACCOUNT_ENDPOINT = (walletId: string) =>
  BASE_URL + `/wallet/cybrid/create-account/${walletId}`;

export const CREATE_PLAID_WORKFLOW_ENDPOINT = () =>
  BASE_URL + `/wallet/workflow`;

export const GET_WORKFLOW_ENDPOINT = (workflowId: string) =>
  BASE_URL + `/wallet/workflow/${workflowId}`;

export const CREATE_EXTERNAL_BANK_ACCOUNT =
  BASE_URL + `/wallet/external-bank-account`;

export const UPDATE_KYC_ENDPOINT = BASE_URL + '/setting/update-kyc';

//USER SETTINGS
export const ADD_ADDRESS_ENDPOINT = BASE_URL + '/setting/address';
export const GET_PROFILE = `${BASE_URL}/setting/profile`;
export const GET_USER_DEVICES = (userId: string) =>
  `${BASE_URL}/setting/devices/get/${userId}`;

export const GET_USER_NOTIFICATIONS = () => `${BASE_URL}/notification`;

export const PROFILE_SETTINGS_ENDPOINT = BASE_URL + '/setting/profile';
export const REQUEST_OTP_ENDPOINT = BASE_URL + '/setting/send-otp';
export const VERIFY_OTP_ENDPOINT = BASE_URL + '/setting/verify-otp';
export const CHANGE_PIN_ENDPOINT = BASE_URL + '/setting/change-pin';
export const FORGOT_PIN_ENDPOINT = BASE_URL + '/setting/forgot-pin';
export const CHANGE_PASSWORD_ENDPOINT = BASE_URL + '/setting/change-password';
export const SETUP_2FA = BASE_URL + '/setting/setup-2fa';
export const SET_SECURITY_QUESTION_ENDPOINT =
  BASE_URL + '/setting/add-secret-question';
export const UPDATE_SECURITY_QUESTION_ENDPOINT =
  BASE_URL + '/setting/update-secret-question';

export const GET_ALL_WALLETS = (id: string) => `${BASE_URL}/wallet/${id}`;
export const GET_ALL_VIRTUAL_CARDS = (id: string) =>
  `${BASE_URL}/virtual-card/${id}`;
export const GET_ALL_WALLET_TRANSACTIONS = (id: string) =>
  `${BASE_URL}/wallet/transactions/${id}`;
export const GET_ALL_RATES = () => `${BASE_URL}/wallet/rates/all`;
// {{local}}/wallet/rates/all
export const GET_ALL_RECENT_TRANSACTIONS = (id: string) =>
  `${BASE_URL}/wallet/recent-transactions/${id}`;
export const VERIFY_ACCOUNT_NUMBER = `${BASE_URL}/wallet/brails/counter-party`;
export const VERIFY_COUNTER_PARTY = `${BASE_URL}/wallet/brails/counter-party`;

export const CYBRID_VERIFY_COUNTER_PARTY = (counterPartyId: string) =>
  `${BASE_URL}/counter-party/verify/${counterPartyId}`;

export const CREATE_COUNTER_PARTY = (userId: string) =>
  `${BASE_URL}/counter-party/${userId}`;
export const GET_ALL_COUNTER_PARTIES = (userId: string) =>
  `${BASE_URL}/counter-party/all/${userId}`;
export const TRANSFER_ENDPOINT = `${BASE_URL}/wallet/brails/transfer`;
export const INTL_TRANSFER_ENDPOINT = `${BASE_URL}/wallet/brails/transfer`;
export const GET_COUNTRY_BANKS = (countryCode: string) =>
  `${BASE_URL}/countries/banks/${countryCode}`;
export const GET_ALL_COUNTRY_BANKS = (countryCode: string) =>
  `${BASE_URL}/countries/banks/${countryCode}`;

export const REGISTER_ENDPOINT = BASE_URL + '/auth/sign-up';

export const GET_SUBDIVISIONS = (countryCode: string) =>
  `${BASE_URL}/state/subdivition/${countryCode}`;
