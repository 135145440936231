import { executeLogin } from 'api/auth';
import { Button } from 'components/common/Form';
import { useToast } from 'components/Notifications/Toast';
import useDisclosure from 'hooks/useDisclosure';
import React, { useState, useEffect, useRef } from 'react';
import logo from 'assets/auth-bg.png';
import { useNavigate } from 'react-router-dom';
import { populateAccount, populateToken } from 'store/account/accountSlice';
// import useDisclosure from "../../app/hooks/useDisclosure";
// import { VERIFY2FA, executeResend2Fa } from "../../apis/auth";
import { useAppDispatch, useAppSelector } from 'store/hooks';

function Verify2FA() {
  const [verificationCode, setVerificationCode] = useState<string[]>(
    Array(6).fill(''),
  );
  const [timer, setTimer] = useState<number>(30);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const user = useAppSelector((state) => state.accountStore.account);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const { showToast } = useToast();

  const handleCodeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const newCode = [...verificationCode];
    newCode[index] = e.target.value;
    setVerificationCode(newCode);

    // Move to the next input field
    if (e.target.value && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      onOpen();
      const payload: LoginData = {
        email: user!.email,
        password: user!.password!,
        two_fa_code: verificationCode.join(''),
      };
      const result = await executeLogin(payload);
      console.log({ result });
      if (result.isSuccess === false) throw new Error(result.message);
      showToast(result.message, 'success');
      dispatch(populateToken(result.data.token));
      dispatch(populateAccount(result.data.account));
      navigate('/dashboard');
    } catch (error: any) {
      showToast(error.message, 'error');
    } finally {
      onClose();
    }
  };

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [timer]);

  return (
    <div className="w-full h-screen flex items-center justify-center bg-gray-100">
      <form
        className="w-96 p-8 rounded-lg shadow-lg bg-white"
        onSubmit={handleSubmit}
      >
        <img
          src={logo}
          alt="logo"
          className="w-32 h-12 mb-6 mx-auto object-contain"
        />
        <h2 className="text-2xl font-semibold mb-4 text-center">
          Enter your verification code
        </h2>
        <p className="text-center mb-6">
          To log in, kindly enter the code we sent to your phone number
        </p>
        <div className="flex justify-center gap-2 mb-6">
          {verificationCode.map((digit, index) => (
            <input
              key={index}
              type="text"
              maxLength={1}
              value={digit}
              onChange={(e) => handleCodeChange(e, index)}
              ref={(el) => (inputRefs.current[index] = el!)}
              className="w-12 h-12 text-center text-xl border rounded"
            />
          ))}
        </div>
        <Button label="Log into your account" loading={isOpen} />
      </form>
    </div>
  );
}

export default Verify2FA;
