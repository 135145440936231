import React from 'react';
import MainDrawer from './MainDrawer';
import Sidebar from 'layouts/Sidebar';

interface MenuDrawerProps {
  isOpen: boolean;
  toggleDrawer: () => void;
}

const MenuDrawer: React.FC<MenuDrawerProps> = ({ isOpen, toggleDrawer }) => {
  return (
    <MainDrawer isOpen={isOpen} toggleDrawer={toggleDrawer}>
      <Sidebar />
    </MainDrawer>
  );
};

export default MenuDrawer;
