import React from 'react';
import AuthLayout from 'layouts/Auth';
import { useForm } from 'react-hook-form';
import authBg from 'assets/form-bg-one.png';
import { executeSetPinCode } from 'api/settings/users';
import { useToast } from 'components/Notifications/Toast';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { STEPS, updateLevel } from 'store/registration/registerSlice';
import { Button, Input } from 'components/common/Form';

interface SecureAccountProps {
  setActiveStep: (no: any) => void;
}

const SecureAccount: React.FC<SecureAccountProps> = ({ setActiveStep }) => {
  const {
    watch,
    trigger,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UserProfile>({
    mode: 'onSubmit',
  });

  const token = useAppSelector((state) => state.accountStore.tokenStore);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState(false);

  const { showToast } = useToast();
  const code = watch('pin');
  const confirm = watch('confirm');

  const onSubmit = async (data: UserProfile) => {
    if (!(await trigger())) return false;
    try {
      if (code !== confirm) throw new Error('The codes do not match');
      setIsLoading(true);
      const payload: UserProfile = {
        pin: data.pin,
      };
      const result = await executeSetPinCode(payload, token);
      if (!result.isSuccess) throw new Error(result.message);
      dispatch(updateLevel({ step: STEPS.ACTIVATE_PIN }));
      setActiveStep((prev: any) => prev + 1);
    } catch (error: any) {
      showToast(error.message, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthLayout background={true} backgroundImage={authBg}>
      <div className="space-y-4 mb-14">
        <h2 className="text-2xl font-semibold">Pin Code</h2>
        <p className="text-sm text-gray-500">
          Re-enter your transaction PIN to confirm
        </p>
      </div>
      <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
        <Input
          name="pin"
          type="number"
          color={true}
          label="Input Pin Code"
          placeholder="Placeholder text"
          register={register}
          errors={errors}
        />
        <Input
          name="confirm"
          type="number"
          color={true}
          label="Confirm Pin Code"
          placeholder="Placeholder text"
          register={register}
          errors={errors}
        />
        <Button loading={isLoading} label={'Create PIN'} />
      </form>
    </AuthLayout>
  );
};

export default SecureAccount;
