import { log } from 'utils/helpers';
import {
  FREEZE_VIRTUAL_CARD,
  GET_ALL_RATES,
  GET_ALL_VIRTUAL_CARDS,
  GET_ALL_WALLETS,
  GET_ALL_WALLET_TRANSACTIONS,
  GET_PROFILE,
} from './index';
import { default as axios } from 'config/axios';

export const executeGetWallets = async (
  id: string,
  token: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).get(GET_ALL_WALLETS(id));
    return response.data;
  } catch (error: any) {
    console.error('GET WALLETS [ERROR]:', error.message);
    return { message: error.message, isSuccess: false } as ResponseDataType;
  }
};

export const executeGetProfile = async (
  token: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).get(GET_PROFILE);
    return { ...response.data, isSuccess: true };
  } catch (error: any) {
    console.error('GET PROFILE [ERROR]:', error.message);
    return { message: error.message, isSuccess: false } as ResponseDataType;
  }
};

export const executeGetVirtualCards = async (
  id: string,
  token: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).get(GET_ALL_VIRTUAL_CARDS(id));
    return response.data;
  } catch (error: any) {
    console.error('GET VIRTUAL CARDS [ERROR]:', error.message);
    return { message: error.message, isSuccess: false } as ResponseDataType;
  }
};

export const executeGetWalletTransactions = async (
  id: string,
  token: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).get(GET_ALL_WALLET_TRANSACTIONS(id));
    return response.data;
  } catch (error: any) {
    console.error('GET WALLET TRANSACTIONS [ERROR]:', error.message);
    return {
      message: error.message,
      isSuccess: false,
      status: error,
    } as ResponseDataType;
  }
};

export const executeGetAllRates = async (
  token: string,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).get(GET_ALL_RATES());
    return response.data;
  } catch (error: any) {
    return { message: error.message, isSuccess: false, status: error };
  }
};

export const executeFreezeCard = async (
  id: string,
  token: string,
  isBlocked: boolean,
): Promise<ResponseDataType> => {
  try {
    const response = await axios(token).post(
      FREEZE_VIRTUAL_CARD(id, isBlocked),
    );
    return response.data;
  } catch (error: any) {
    log('LOGIN [ERROR]:', error.message);
    return { message: error.message, isSuccess: false } as ResponseDataType;
  }
};
