import React from 'react';
// import button from 'assets/Button.svg';
// import intl from 'assets/intl.svg';
// import twitter from 'assets/twitter.svg';
import globe from 'assets/globe.svg';
// import Local from 'components/transfers/Local';
import International from 'components/transfers/International';
// import Twitter from 'components/transfers/Twitter';
// import WorldHero from 'components/transfers/WorldHero';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

interface Props {
  onClose: () => void;
}

const SendMoney: React.FC<Props> = ({ onClose }) => {
  const [state, setState] = React.useState<number | null>(null);

  const transfers = [
    // {
    //   name: 'World Hero Account',
    //   component: <WorldHero />,
    //   icon: button,
    // },
    {
      name: 'Transfers',
      component: <International />,
      icon: globe,
    },
    // { name: 'Local Transfers', component: <Local />, icon: intl },
    // { name: 'Twitter Payment', component: <Twitter />, icon: twitter },
  ];

  return (
    <div className="px-10 pt-10 bg-[#e6f3fe] h-full overflow-y-hidden">
      {state === null ? (
        <div className="w-full mb-10">
          <div className="flex justify-between w-full">
            <FaArrowLeft onClick={onClose} className="cursor-pointer" />
          </div>
          <h2 className="text-[1.5rem] font-semibold text-[#585757] text-center">
            Send Money
          </h2>
          <p className="text-sm text-[#585757] text-center">
            Choose your option here
          </p>
        </div>
      ) : null}
      <div
        className={`${state === null ? 'bg-white' : ''} w-full p-${
          state === null ? 6 : 0
        } h-full`}
      >
        {state === null
          ? transfers.map((item, idx) => (
              <div
                className="flex justify-between py-4 border-b border-gray-300 cursor-pointer"
                key={idx}
                onClick={() => setState(idx)}
              >
                <div className="flex items-center">
                  <img src={item.icon} alt={item.name} />
                  <p className="text-xs text-black ml-2">{item.name}</p>
                </div>
                <FaArrowRight className="text-gray-800 w-6 h-6" />
              </div>
            ))
          : transfers[state]?.component}
      </div>
    </div>
  );
};

export default SendMoney;
