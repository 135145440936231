import useRequestOTP from 'hooks/useRequestOTP';
import DialPad from './components/DialPad';
import OTPVerification from './components/OTPVerification';
import React, { useState } from 'react';
import { executeChangePin } from 'api/settings/security';
import useDisclosure from 'hooks/useDisclosure';
import { useToast } from 'components/Notifications/Toast';
import { useAppSelector } from 'store/hooks';

const ChangePin = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [currentPin, setCurrentPin] = useState('');
  const [newPin, setNewPin] = useState('');
  const [confirmPin, setConfirmPin] = useState('');
  const { showToast } = useToast();
  const { onOpen: openLoading, onClose: closeLoading } = useDisclosure();
  const token = useAppSelector((state) => state.accountStore.tokenStore);

  const { handleRequest, loadingData } = useRequestOTP();

  React.useEffect(() => {
    handleRequest();
    // eslint-disable-next-line
  }, []);

  const handleChangePin = async () => {
    try {
      openLoading();
      const payload: SecuritySettingsData = {
        current_pin: currentPin,
        new_pin: newPin,
      };
      const result = await executeChangePin(payload, token);
      if (!result.isSuccess) throw new Error(result.message);
      showToast(result.message, 'success');
    } catch (error: any) {
      showToast(error.message, 'error');
    } finally {
      closeLoading();
    }
  };

  const steps = [
    {
      name: 'OTP Verification',
      component: (
        <OTPVerification
          cta={setActiveStep}
          isLoading={loadingData}
          resendOtp={handleRequest}
        />
      ),
    },
    {
      name: 'Current Pin',
      component: (
        <DialPad
          cta={setActiveStep}
          title={'Input your current PIN'}
          setPin={setCurrentPin}
          pin={currentPin}
        />
      ),
    },
    {
      name: 'New Pin',
      component: (
        <DialPad
          cta={setActiveStep}
          title={'Input your New PIN'}
          setPin={setNewPin}
          pin={newPin}
        />
      ),
    },
    {
      name: 'Confirm Pin',
      component: (
        <DialPad
          cta={handleChangePin}
          title={'Confirm your new PIN'}
          setPin={setConfirmPin}
          pin={confirmPin}
        />
      ),
    },
  ];

  return <>{steps[activeStep].component}</>;
};

export default ChangePin;
