import React from 'react';
import OtpVerificationBox from 'components/otp/VerificationBox';
import { Button } from 'components/common/Form';

interface Props {
  setState: (arg: any) => void;
  setPin?: (arg: string) => void;
  handleTransfer?: () => void;
  isLoading?: boolean;
}

const InputPin: React.FC<Props> = ({ isLoading, setPin, handleTransfer }) => {
  const [otp, setOtp] = React.useState(Array.from({ length: 4 }, () => ''));

  React.useEffect(() => {
    if (setPin) setPin(otp.join(''));
  }, [otp, setPin]);

  const handleInputPin = () => {
    if (handleTransfer) {
      handleTransfer();
    }
  };

  return (
    <div className="flex flex-col items-center bg-white px-8 pt-6 pb-28 space-y-20">
      <p className="text-base text-[#585757]">Input your pin</p>
      <div className="max-w-xs">
        <OtpVerificationBox otp={otp} setOtp={setOtp} />
      </div>
      <Button
        label="Confirm"
        onClick={handleInputPin}
        extraClasses="mb-[2px]"
        loading={isLoading}
      />
    </div>
  );
};

export default InputPin;
