import React from 'react';

interface MainDrawerProps {
  isOpen: boolean;
  toggleDrawer: () => void;
  children: React.ReactNode;
}

const MainDrawer: React.FC<MainDrawerProps> = ({
  isOpen,
  toggleDrawer,
  children,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div>
      <button onClick={toggleDrawer}>Close Drawer</button>
      {children}
    </div>
  );
};

export default MainDrawer;
