import React from 'react';
import { notificationSettings } from 'utils/data';

const Notifications = () => {
  return (
    <div className="px-52 flex flex-row justify-between">
      <div className="flex flex-col items-start max-w-lg space-y-6">
        <h2 className="text-lg font-semibold">Notifications</h2>
        <div className="flex flex-col items-start space-y-6 w-full">
          {notificationSettings.map((item, index) => (
            <div
              key={index}
              className="flex flex-row items-center justify-between w-full border-b border-gray-300 py-4"
            >
              <div className="flex flex-col items-start space-y-2">
                <h3 className="text-base font-medium text-black leading-6">
                  {item.name}
                </h3>
                <p className="text-xs text-black leading-5 font-medium max-w-xs">
                  {item.info}
                </p>
              </div>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  defaultChecked={item.checked}
                />
                <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Notifications;
