/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import useTimer from 'hooks/useTimer';

interface TimerComponentProps {
  time: number;
  isMinute?: boolean;
  isLoading?: boolean;
  onClick: (prop: any) => void;
}

const TimerComponent: React.FC<TimerComponentProps> = ({
  time,
  // onClick,
  // isLoading,
  isMinute,
}) => {
  const { currentTime, start } = useTimer(time, isMinute);

  useEffect(() => {
    start();
    //eslint-disable-next-line
  }, []);

  return (
    <div className="flex items-center">
      <span className="text-sm text-gray-500 w-full">
        {`${currentTime.minutes
          .toString()
          .padStart(2, '0')}:${currentTime.seconds
          .toString()
          .padStart(2, '0')}`}
      </span>
    </div>
  );
};

export default TimerComponent;
