import React from 'react';
import Modal from './index'; // Your Modal component
import { Button } from 'components/common/Form';
import { WalletData } from 'types/data';

interface VirtualCardModalProps {
  isOpen: boolean;
  closeModal: () => void;
  activeWallet: WalletData;
}

const VirtualCardModal: React.FC<VirtualCardModalProps> = ({
  isOpen,
  closeModal,
  activeWallet,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      title="Transfer Details"
      width={'max-w-lg'}
    >
      {activeWallet ? (
        activeWallet.currency !== 'NGN' ? (
          <UsView activeWallet={activeWallet} onClose={closeModal} />
        ) : (
          <div className="space-y-4">
            <div className="flex justify-between items-center border-b py-2">
              <span className="text-gray-600">Account Name:</span>
              <span className="font-semibold">{activeWallet.account_name}</span>
            </div>
            <div className="flex justify-between items-center border-b py-2">
              <span className="text-gray-600">Account Number:</span>
              <span className="font-semibold">
                {activeWallet.account_number}
              </span>
            </div>
            <div className="flex justify-between items-center border-b py-2">
              <span className="text-gray-600">Bank Name:</span>
              <span className="font-semibold">{activeWallet.bank_name}</span>
            </div>
            <div className="flex justify-between items-center border-b py-2">
              <span className="text-gray-600">Currency:</span>
              <span className="font-semibold">{activeWallet.currency}</span>
            </div>
          </div>
        )
      ) : (
        <p className="text-center text-gray-500">No active wallet found</p>
      )}
    </Modal>
  );
};

export default VirtualCardModal;

interface Props {
  activeWallet: WalletData;
  onClose: () => void;
}
export const UsView: React.FC<Props> = ({ activeWallet, onClose }) => {
  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center border-b py-2">
        <span className="text-gray-600">Account Name:</span>
        <span className="font-semibold">{activeWallet.account_name}</span>
      </div>
      <div className="flex justify-between items-center border-b py-2">
        <span className="text-gray-600">Account Number:</span>
        <span className="font-semibold">{activeWallet.account_number}</span>
      </div>
      <div className="flex justify-between items-center border-b py-2">
        <span className="text-gray-600">ACH Routing Number:</span>
        <span className="font-semibold">{activeWallet.routing_number}</span>
      </div>
      <div className="flex justify-between items-center border-b py-2">
        <span className="text-gray-600">Currency:</span>
        <span className="font-semibold">{activeWallet.currency}</span>
      </div>
      <Button label="Make Transfer" onClick={onClose} />
    </div>
  );
};
