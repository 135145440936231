import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface Props {
  items: { id: number; component: JSX.Element }[];
  onSlideChange?: (index: number) => void; // Add a callback for slide change
}

const Fade: React.FC<Props> = ({ items, onSlideChange }) => {
  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    afterChange: onSlideChange, // Call the function on slide change
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {items.map((item) => (
          <div key={item.id}>{item.component}</div>
        ))}
      </Slider>
    </div>
  );
};

export default Fade;
