import React, { KeyboardEvent } from 'react';

interface OtpVerificationProps {
  setOtp: (otp: string[]) => void;
  otp: string[];
}

const OtpVerificationBox: React.FC<OtpVerificationProps> = ({
  otp,
  setOtp,
}) => {
  const handleChange = (index: number, value: string) => {
    // Allow empty string for deletion
    if (value !== '' && isNaN(Number(value))) {
      return;
    }

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move to next input if a number was entered
    if (value !== '' && index < newOtp.length - 1) {
      document.getElementById(`otp-input-${index + 1}`)?.focus();
    }
  };

  const handleKeyDown = (index: number, e: KeyboardEvent<HTMLInputElement>) => {
    // Handle backspace
    if (e.key === 'Backspace') {
      if (otp[index] === '') {
        // If current input is empty, move to previous input
        if (index > 0) {
          document.getElementById(`otp-input-${index - 1}`)?.focus();
        }
      } else {
        // Clear current input
        const newOtp = [...otp];
        newOtp[index] = '';
        setOtp(newOtp);
      }
    }
    // Handle left arrow
    else if (e.key === 'ArrowLeft' && index > 0) {
      document.getElementById(`otp-input-${index - 1}`)?.focus();
    }
    // Handle right arrow
    else if (e.key === 'ArrowRight' && index < otp.length - 1) {
      document.getElementById(`otp-input-${index + 1}`)?.focus();
    }
  };

  return (
    <div className="flex justify-center items-center">
      {otp.map((digit, index) => (
        <div key={index} className="mx-2">
          <input
            id={`otp-input-${index}`}
            type="text"
            maxLength={1}
            value={digit}
            onChange={(e) => handleChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            className="text-center p-4 text-md border rounded-md border-gray-300 w-[45px] focus:border-blue-500 focus:ring-1 focus:ring-blue-500 outline-none"
          />
        </div>
      ))}
    </div>
  );
};

export default OtpVerificationBox;
