import locker from 'assets/locker.png';
import man from 'assets/man-shout.png';
import { DocumentCopyIcon } from 'components/icons';

const ReferAndEarn = () => {
  return (
    <div className="px-6 py-5 bg-[#F5FFF8] h-full">
      <h1 className="text-[20px] font-medium text-center mb-6 text-primary">
        Refer and Earn
      </h1>
      <div className="flex items-center justify-end text-center">
        <div className="flex flex-col items-center">
          <img src={locker} alt="Locker" className="mb-4" />
          <span className="cursor-pointer">
            <DocumentCopyIcon />
          </span>
          <h2 className="text-6 font-semibold">Code here</h2>
        </div>
        <img src={man} alt="Man" className="ml-4" />
      </div>
    </div>
  );
};

export default ReferAndEarn;
