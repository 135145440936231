import React from 'react';
import AuthLayout from 'layouts/Auth';
import authBg from 'assets/form-bg-two.png';
import OtpVerificationBox from 'components/otp/VerificationBox';
import TimerComponent from 'components/common/TimerComponent';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { combineOtp, isOtpFilled } from 'utils/helpers';
import { useToast } from 'components/Notifications/Toast';
import { BiLogInCircle } from 'react-icons/bi';
import { Button } from 'components/common/Form';
import { executeValidateEmail, executeVerifyEmail } from 'api/auth';
import { STEPS, updateLevel } from 'store/registration/registerSlice';
import useDisclosure from 'hooks/useDisclosure';

interface VerifyEmailProps {
  setActiveStep: (no: any) => void;
}

const VerifyEmail: React.FC<VerifyEmailProps> = ({ setActiveStep }) => {
  const [otp, setOtp] = React.useState(Array.from({ length: 6 }, () => ''));
  const { data } = useAppSelector((state) => state.registerStore);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const selectedCountry = useAppSelector(
    (state) => state.countryStore.selectedCountry,
  );

  const { showToast } = useToast();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isResending] = React.useState(false);

  const handleVerifyEmail = async () => {
    if (!isOtpFilled(otp)) {
      showToast('Please fill in all OTP digits.', 'error');
      return;
    }
    try {
      setIsLoading(true);
      const payload: VerificationType = {
        email: data.EMAIL,
        type: 'email-verification',
        otp: combineOtp(otp),
      };
      const result = await executeVerifyEmail(payload);
      if (!result.isSuccess) throw new Error(result.message);
      setActiveStep((prev: any) => prev + 1);
      dispatch(
        updateLevel({
          step: STEPS.SIGN_UP,
        }),
      );
    } catch (error: any) {
      showToast(error.message, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleValidateEmail = async () => {
    try {
      onOpen();
      const payload: VerificationType = {
        email: data.EMAIL,
        type: 'email-verification',
        country_id: selectedCountry?.id,
      };
      const result = await executeValidateEmail(payload);
      if (!result.isSuccess) throw new Error(result.message);
      showToast(result.message, 'success');
    } catch (error: any) {
      showToast(error.message, 'error');
    } finally {
      onClose();
    }
  };

  return (
    <AuthLayout background={true} backgroundImage={authBg}>
      <div className="flex flex-col items-center justify-between text-center">
        <h2 className="text-[24px] font-semibold mb-6">OTP Verification</h2>
        <p className="text-[#949596] text-sm">Enter the OTP sent to</p>
        <button className="text-[#1C1C1C] mb-6">{data.EMAIL}</button>
        <div className="mb-4 mt-10">
          <OtpVerificationBox otp={otp} setOtp={setOtp} />
        </div>
        <TimerComponent
          time={5}
          isMinute
          isLoading={isResending}
          onClick={() => console.log('test')}
        />
        <Button
          label="Continue"
          Icon={BiLogInCircle}
          onClick={handleVerifyEmail}
          extraClasses="mt-[95px] mb-[24px]"
          loading={isLoading}
        />
        <p className="text-[#949596] mt-[81px] mb-[24px] text-sm">
          Didn’t receive a code?
        </p>
        <button className="text-[#1C1C1C] mb-6" onClick={handleValidateEmail}>
          {isOpen ? `Resending...` : `Request again`}
        </button>
      </div>
    </AuthLayout>
  );
};

export default VerifyEmail;
