import React from 'react';
import AuthLayout from 'layouts/Auth';
import { Link as ReactLink } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import authBg from 'assets/form-bg-one.png';
import { Button, Input } from 'components/common/Form';
import { BiLogInCircle } from 'react-icons/bi';
import { FaArrowRight } from 'react-icons/fa';
import { useToast } from 'components/Notifications/Toast';
import useDisclosure from 'hooks/useDisclosure';
import { executeRequestOtp } from 'api/settings/security';

interface ResetPasswordProps {
  setActiveStep: (no: any) => void;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
}

const ForgotPassword: React.FC<ResetPasswordProps> = ({
  setActiveStep,
  setEmail,
}) => {
  const { control, register, trigger, handleSubmit } =
    useForm<RegistrationData>({
      mode: 'onSubmit',
    });

  const { showToast } = useToast();
  const {
    isOpen: isLoading,
    onOpen: openLoading,
    onClose: closeLoading,
  } = useDisclosure();

  const handleReset = async (data: RegistrationData) => {
    if (!(await trigger())) return false;
    try {
      openLoading();
      const result = await executeRequestOtp({ email: data.email });
      if (!result.isSuccess) throw new Error(result.message);
      setEmail(data.email);
      setActiveStep((prev: any) => prev + 1);
    } catch (error: any) {
      showToast(error.message, 'error');
    } finally {
      closeLoading();
    }
  };

  return (
    <AuthLayout background={true} backgroundImage={authBg}>
      <div className="h-full">
        <h2 className="text-2xl font-semibold">Forgot Password?</h2>
        <p className="text-sm text-gray-600 mb-12 mt-6">
          Please enter the email or mobile number you used to register with
          World Hero Wallet to request a password reset.
        </p>
        <form onSubmit={handleSubmit(handleReset)}>
          <Input
            control={control}
            name="email"
            type="email"
            label="Email"
            placeholder="Placeholder"
            color={true}
            register={register}
          />
          <Button
            label=" Send reset link"
            Icon={BiLogInCircle}
            loading={isLoading}
            extraClasses="mt-[149px] mb-[157px]"
          />
        </form>
        <p className="text-sm text-[#949596] text-center">
          If you dont have an account
          <ReactLink to={'/register'} className="inline-flex items-center ml-2">
            Sign up
            <span className="ml-1">
              <FaArrowRight size={12} />
            </span>
          </ReactLink>
        </p>
      </div>
    </AuthLayout>
  );
};

export default ForgotPassword;
