import { MenuDatas } from 'components/common/Datas';
import { Link, useParams } from 'react-router-dom';
import logo from 'assets/logo.png';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { clearAccount } from 'store/account/accountSlice';
import { useDrawer } from 'contexts/Drawer';
import Notifications from 'components/Drawer/Notifications';
import Collapsible from 'react-collapsible';
import { BiChevronDown } from 'react-icons/bi';
import nigeria from 'assets/flags.svg';
import canada from 'assets/canada.svg';
import usa from 'assets/usa.svg';
import { persistor } from 'store';

type Wallet = {
  id: string;
  currency: string;
};

function Sidebar() {
  const dispatch = useAppDispatch();
  const { openDrawer } = useDrawer();
  const wallets = useAppSelector((state) => state.accountStore.wallets);
  const { id } = useParams<{ id: string }>();

  const currentPath = (path: string) => {
    const isCurrentPath =
      window.location.pathname.split('/')[1] === path.split('/')[1];
    return isCurrentPath ? path : null;
  };

  const handleLogoutClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    dispatch(clearAccount());
    persistor.pause();
    persistor.flush().then(() => {
      return persistor.purge();
    });
    window.location.href = '/login';
  };

  const handleDrawer = () => {
    openDrawer(<Notifications />);
  };

  const isActiveWallet = (walletId: string) => {
    return id === walletId;
  };

  const getFlagIcon = (title: string) => {
    switch (title) {
      case 'NGN Wallet':
        return nigeria;
      case 'USD Wallet':
        return usa;
      case 'CAD Wallet':
        return canada;
      default:
        return undefined;
    }
  };

  const getWalletIdByTitle = (
    title: string,
    wallets: { [key: string]: Wallet },
  ) => {
    // Map wallet title to currency code
    const currencyMap: { [key: string]: string } = {
      'NGN Wallet': 'NGN',
      'USD Wallet': 'USD',
      'CAD Wallet': 'CAD',
    };

    const currency = currencyMap[title];
    const wallet = Object.values(wallets).find(
      (wallet) => wallet.currency === currency,
    );

    return wallet ? wallet.id : null;
  };

  return (
    <div className="bg-white xl:shadow-lg py-6 px-4 w-full h-screen border-r border-border">
      <Link to="/">
        <img src={logo} alt="logo" className="w-2/4 h-12 ml-4 object-contain" />
      </Link>
      <div className="flex flex-col gap-4 mt-12">
        {MenuDatas.map((item, index) => {
          if (!item.children) {
            return (
              <Link
                to={item.path!}
                key={index}
                className={`
                ${currentPath(item.path!) === item.path ? 'bg-lightBlue' : ''}
                flex gap-4 transitions group items-center w-full p-4 rounded-lg hover:bg-text`}
                onClick={
                  item.title === 'Logout'
                    ? handleLogoutClick
                    : item.type === 'drawer'
                      ? handleDrawer
                      : undefined
                }
              >
                <item.icon className={`text-xl text-darkBlue`} />
                <p
                  className={`text-base font-normal group-hover:text-primary ${
                    currentPath(item.path!) === item.path
                      ? 'text-darkBlue'
                      : 'text-darkBlue'
                  }`}
                >
                  {item.title}
                </p>
              </Link>
            );
          } else if (item.children.length > 0) {
            return (
              <Collapsible
                key={index}
                trigger={
                  <div className="flex justify-between p-4">
                    <item.icon className={`text-xl text-darkBlue`} />
                    <span className="text-base font-normal group-hover:text-primary">
                      {item.title}
                    </span>
                    <BiChevronDown size={'20px'} />
                  </div>
                }
              >
                {item.children.map((subItem, idx) => {
                  const walletId = getWalletIdByTitle(subItem.title, wallets); // Get wallet ID
                  return (
                    <Link
                      to={
                        walletId ? `/transactions/${walletId}` : '/transactions'
                      }
                      key={idx}
                      className={`
                        ${isActiveWallet(walletId!) ? 'bg-lightBlue' : ''}
                        flex gap-4 transitions group items-center w-full p-4 rounded-lg hover:bg-text`}
                    >
                      <img
                        src={getFlagIcon(subItem.title)}
                        className={`text-xl text-darkBlue`}
                      />
                      <p
                        className={`text-base font-normal group-hover:text-primary ${
                          currentPath(subItem.path!) === subItem.path
                            ? 'text-darkBlue'
                            : 'text-darkBlue'
                        }`}
                      >
                        {subItem.title}
                      </p>
                    </Link>
                  );
                })}
              </Collapsible>
            );
          }
          return null; // Handle case where item has no children
        })}
      </div>
    </div>
  );
}

export default Sidebar;
